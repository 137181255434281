<template>
  <b-modal id="video-upload-modal" centered hide-footer hide-header no-close-on-backdrop no-close-on-esc size="xs">
    <b-alert show :variant="variant">
      <template v-if="!uploaded">
        <b class="ml-2">Uploading Video...</b>
        <p class="font-weight-bold mb-0">This may take a while - do not close this window.</p>
      </template>
      <template v-else>
        <b>Video has been uploaded correctly</b>
        <b-icon icon="check2" scale="2" variant="success"></b-icon>
      </template>

      <b-progress class="mt-3" :value="uploadProgress" :max="100" variant="success"/>
      <div class="flex text-right font-weight-bolder mt-1" >{{ uploadProgress }}%</div>
    </b-alert>

    <div v-if="uploaded" class="modal-btns">
      <div role="button" class="btn-ok" @click="closeModal">
        OK
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'VideoUploadModal',
  props: {
    uploaded: {
      type: Boolean,
      default: false
    },
    uploadProgress: {
      type: Number,
      default: 0
    }
  },
  methods: {
    closeModal () {
      this.$emit('on-video-upload-modal-closed')
      this.$bvModal.hide('video-upload-modal')
    }
  },
  computed: {
    variant () {
      return this.uploaded ? 'success' : 'info'
    }
  }
}
</script>

<style lang="stylus">
@import "~@/core/stylus/variables.styl"

#video-upload-modal
  .modal-content
    margin auto
    width 500px
    height auto

  .modal-title
    text-align center
    font-size 16px
    font-weight bold
    margin-top 65px

  .modal-text
    text-align center
    font-size 14px
    width 79%
    margin auto
    height 75px
    margin-top 10px
    text-overflow ellipsis
    overflow hidden

  .modal-btns
    text-align center

    .btn-cancel, .btn-ok
      text-align center
      display inline-block
      vertical-align top
      width 102px
      height 31px
      background-color color-blue-100
      border none
      border-radius 4px
      color white
      font-weight 600
      margin-bottom 0
      letter-spacing 2px
      line-height 31px

    .btn-ok
      width 82px

  .alert
    text-align center

    &-info
      margin 0
      background-color #E1E3FD
      border none
      color #4B5AA7

    &-success .bi-check2
      margin-left 15px

    .progress
      background #F9B316
      height 0.25rem
      overflow visible

      .progress-bar
        background-color #9CCC4D !important
        border #4B5AA8
        height 0.75rem
        margin-top -0.25rem
        border-radius 0.5rem
</style>
