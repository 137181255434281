const timer = (function () {
  const props = {}
  function Timer () {
    const uuid = UUID()
    Object.defineProperty(this, 'uuid', {
      get: () => { return uuid },
      enumerable: true,
      configurable: false
    })

    props[uuid] = {}
    props[uuid].seconds = 0
    props[uuid].last = null

    Object.defineProperty(this, 'seconds', {
      get: () => {
        getDelta(this.uuid)
        return props[uuid].seconds
      },
      enumerable: true,
      configurable: false
    })
    Object.defineProperty(this, 'last', {
      get: () => { return props[this.uuid].last },
      enumerable: true,
      configurable: false
    })
  }

  Timer.prototype.start = function (reset) {
    if (reset === true) { props[this.uuid].seconds = 0 }
    if (!props[this.uuid].start) { props[this.uuid].start = Date.now() }
  }

  Timer.prototype.stop = function () {
    getDelta(this.uuid)
    props[this.uuid].start = null
  }

  function getDelta (uuid) {
    if (props[uuid] && props[uuid].start) {
      const now = Date.now()
      let delta = now - props[uuid].start
      delta = round(delta, 2) / 1000
      props[uuid].last = delta
      props[uuid].seconds += delta
      props[uuid].start = now
    }
  }

  function round (value, precision) {
    if (Number.isInteger(precision)) {
      var shift = Math.pow(10, precision)
      // Limited preventing decimal issue
      return (Math.round(value * shift + 0.00000000000001) / shift)
    } else {
      return Math.round(value)
    }
  }

  function UUID () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0; var v = c === 'x' ? r : (r & 0x3 | 0x8)
      return v.toString(16)
    })
  }

  function delegate (scope, method) {
    return function () {
      if (arguments.length > 0) {
        var args = []
        var numOfArgs = arguments.length
        for (var i = 0; i < numOfArgs; i++) {
          args.push(arguments[i])
        }
        return method.apply(scope, args)
      } else {
        return method.call(scope)
      }
    }
  };

  function IdleManager (interval) {
    const uuid = UUID()
    Object.defineProperty(this, 'uuid', {
      get: () => { return uuid },
      enumerable: true,
      configurable: false
    })

    props[uuid] = {}
    props[uuid].interval = parseInt(interval) || 60
    Object.defineProperty(this, 'interval', {
      get: () => { return props[uuid].interval },
      enumerable: true,
      configurable: false
    })
    props[uuid].listener = delegate(this, idleHandler)
    Object.defineProperty(this, 'listener', {
      get: () => { return props[uuid].listener },
      enumerable: true,
      configurable: false
    })
  }

  IdleManager.prototype.start = function () {
    window.addEventListener('mousemove', this.listener)
    window.addEventListener('mousedown', this.listener)
    window.addEventListener('keydown', this.listener)
    this.listener()
  }

  IdleManager.prototype.stop = function () {
    window.removeEventListener('mousedown', this.listener)
    window.removeEventListener('keydown', this.listener)
    window.removeEventListener('mousemove', this.listener)
  }
  IdleManager.prototype.clear = function () {
    idleClear(this.uuid)
  }
  function idleClear (uuid) {
    if (props[uuid].timeout) {
      window.clearTimeout(props[uuid].timeout)
      props[uuid].timeout = null
    }
  }
  function idleHandler (e) {
    idleClear(this.uuid)
    const uuid = this.uuid
    props[this.uuid].timeout = setTimeout(function () {
      window.dispatchEvent(new CustomEvent('useridle'))
      idleClear(uuid)
    }, this.interval * 1000)
    window.dispatchEvent(new CustomEvent('useraction'))
  }

  function TimeFactory () {
  }

  TimeFactory.prototype.timer = function () {
    return new Timer()
  }
  TimeFactory.prototype.idle = function (timeout) {
    return new IdleManager(timeout)
  }
  return new TimeFactory()
})()

const reloadManager = (function () {
  const props = {}
  const kReloadInterval = parseInt(process.env.VUE_APP_RELOAD_INTERVAL) || 60
  function ReloadManager () {
    props.timer = timer.timer()
    props.idle = timer.idle(process.env.VUE_APP_IDLE_INTERVAL)
    props.isEnable = true

    Object.defineProperty(this, 'elapsedTime', {
      get: () => { return props.timer.seconds },
      enumerable: true,
      configurable: false
    })
    Object.defineProperty(this, 'isEnable', {
      get: () => { return props.isEnable },
      enumerable: true,
      configurable: false
    })
  }
  ReloadManager.prototype.start = function (reset) {
    startTimer(reset)
    const timeout = setTimeout(function () {
      window.clearTimeout(timeout)
      window.addEventListener('useridle', () => {
        props.idle.stop()
        reload()
      })
      props.idle.start()
    }, kReloadInterval * 1000)
  }

  ReloadManager.prototype.enable = function () {
    props.isEnable = true
  }

  ReloadManager.prototype.disable = function () {
    props.isEnable = false
  }

  function startTimer (reset) {
    if (props.timer) props.timer.start(reset)
  }
  function reload () {
    if (props.isEnable === true) {
      window.location.reload()
    }
  }
  return new ReloadManager()
})()

export { reloadManager }
