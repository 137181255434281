import { ClassroomMixins } from '@/modules/mixins'

export const CourseMixins = {
  mixins: [ClassroomMixins],
  computed: {
    courseGroupTypeId () {
      return this.classroom?.course_student_group_type_id
    },
    isIndividualGroupType () {
      return this.courseGroupTypeId === 1
    },
    isSmallGroupType () {
      return this.courseGroupTypeId === 2
    },
    isWholeClassGroupType () {
      return this.courseGroupTypeId === 3
    }
  }
}
