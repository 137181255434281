export const OfflineMixin = {
  data () {
    return {
      isOnline: navigator.onLine
    }
  },
  mounted () {
    navigator.onLine ? this.isOnline = true : this.isOnline = false

    const onlineHandler = () => {
      this.$emit('online')
      this.isOnline = true
    }

    const offlineHandler = () => {
      this.$emit('offline')
      this.isOnline = false
    }

    window.addEventListener('online', onlineHandler)
    window.addEventListener('offline', offlineHandler)

    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('online', onlineHandler)
      window.removeEventListener('offline', offlineHandler)
    })
  }
}
