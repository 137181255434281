<template>
  <b-modal
    id="modal-form-payment"
    ref="modalFormPayment"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
    no-close-on-esc
    size="md"
  >
    <b-container>
      <b-row align-h="center" align-v="center">
        <b-col cols="12" md="10">
          <h2>Payment Information</h2>
          <p>Level Up Village Classroom: ${{ getClassroomPrice }}</p>
          <p>Please enter your credit card information</p>
          <form-payment
            @on-payment="onProcessPayment"
            @on-cancel="onCancel"
          ></form-payment>
          <b-overlay no-wrap :show="processingForm"></b-overlay>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      id="modal-confirm-payment"
      size="sm"
    >
      <p class="my-4 text-center">Accepted</p>
    </b-modal>
  </b-modal>
</template>

<script>
import { AppStorage } from '@/core/utils'
import { PaymentService } from '@/core/services'
import FormPayment from '@/core/components/Payment/FormPayment.vue'
import { awaitTimeout } from '@/core/utils/utils'

export default {
  name: 'FormPaymentStudent',
  components: { FormPayment },
  props: {
    classroom: {
      type: Object
    }
  },
  data () {
    return {
      processingForm: false
    }
  },
  methods: {
    async onProcessPayment (formData) {
      if (this.processingForm) return

      this.processingForm = true

      const data = {
        credit_card: { ...formData.creditCard },
        email: formData.email,
        classroom: {
          id: this.classroom.id,
          student_payment_price: this.classroom.student_payment_price
        }
      }

      try {
        await PaymentService.save(data)

        this.$bvModal.show('modal-confirm-payment')

        await awaitTimeout(3000)

        this.$bvModal.hide('modal-form-payment')

        await this.$router.push({
          name: 'MyTasksPage',
          params: { classroomId: this.classroom.id }
        })
      } catch (e) {
        let message = e.message
        if (e.name === 'StudentPaymentError') {
          message =
            message === 'The credit card number is not valid'
              ? 'The credit card information entered is not valid'
              : message
        }

        await this.$bvModal.msgBoxOk(message)
      }

      this.processingForm = false
    },
    onCancel () {
      this.$bvModal
        .msgBoxConfirm(
          'Are you sure? Payment will be needed to participate in this classroom',
          {
            title: 'Cancel Payment?',
            noCloseOnBackdrop: true,
            centered: true
          }
        )
        .then((res) => {
          if (res) {
            AppStorage.clear()
            this.$bvModal.hide('modal-form-payment')
            this.$router.push({ name: 'LoginPage' })
          }
        })
    }
  },
  computed: {
    getClassroomPrice () {
      return this.classroom ? this.classroom.student_payment_price : 0
    }
  }
}
</script>

<style lang="stylus" scoped>

::v-deep #modal-form-payment {
  .modal-dialog {
    max-width 600px
  }
}
</style>
