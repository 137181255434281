<template>
  <b-popover :target="target"
             show
             v-if="showPopover"
             id="custom-popover-notification"
             :custom-class="videoFailedNotifications.length ? 'video-failed-notification' : ''"
             placement="bottomright">
      <div v-click-outside="clickOutside" class="popover-content" @click="goResponseNotification">
        <img :src="require('@/assets/images/video_response_icon_blue.png')" alt="LUV" class="icon-response">
        <p v-if="videoFailedNotifications.length">You have a video that failed to convert to LUV format</p>
        <p v-else>You have a new response from <b>{{removeComma(lastResponseNotification.from)}}</b> {{responseNotifications.length > 1 ? 'and others' : ''}}</p>
      </div>
  </b-popover>
</template>

<script>
import { NotificationService } from '@/core/services'
import { Notifications } from '@/core/utils'

export default {
  name: 'NavigationPills',
  props: {
    showPopover: {
      type: Boolean,
      default: false
    },
    target: {
      type: String,
      required: true
    },
    lastResponseNotification: {
      type: Object,
      required: true
    },
    responseNotifications: {
      type: Array,
      required: true
    },
    videoFailedNotifications: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
    }
  },
  methods: {
    clickOutside () {
      this.$emit('close-popover')
    },
    removeComma (string) {
      return string?.replace(',', ' ')
    },
    goResponseNotification () {
      if (this.videoFailedNotifications.length) {
        const lastVideoFailedNotification = this.videoFailedNotifications[0]
        if (lastVideoFailedNotification.type === Notifications.type.studentFailedVideoConversion) {
          this.$router.push({
            name: 'PostDetailPage',
            params: {
              classroomId: lastVideoFailedNotification.classroom_id,
              postId: lastVideoFailedNotification.object_id
            }
          })
        } else {
          this.$router.push({
            name: 'ResponseDetailPage',
            params: {
              classroomId: lastVideoFailedNotification.classroom_id,
              responseId: lastVideoFailedNotification.object_id
            }
          })
        }
        this.$emit('close-popover')
        NotificationService.status(lastVideoFailedNotification.id, 'read')
        return
      }
      this.$router.push({
        name: 'PostDetailPage',
        params: {
          classroomId: this.lastResponseNotification.classroom_id,
          postId: this.lastResponseNotification.object_id
        }
      }).then(() => {
        this.$emit('close-popover')
        NotificationService.status(this.lastResponseNotification.id, 'read')
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"

</style>
<style lang="stylus">
color-popover-notification = #fcd58d
color-popover-video-failed = #f89484

#custom-popover-notification
  cursor pointer
  margin-top 0
  z-index 1000

  .popover-body
    background-color color-popover-notification

    .popover-content
      display flex

      .icon-response
        width 40px
        height 40px
        margin-right 10px
      p
        margin 0
        padding 0

  .arrow
    &::after
      border-bottom-color color-popover-notification
      border-top-color color-popover-notification

.video-failed-notification
  .popover-body
    background-color color-popover-video-failed !important

  .arrow
    &::after
      border-bottom-color color-popover-video-failed !important
      border-top-color color-popover-video-failed !important
</style>
