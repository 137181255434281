<template>
  <b-overlay :show="loading">
    <b-row class="create-password-container">
      <b-col cols="10" sm="8" md="6" lg="4" xl="4" class="align-self-center mx-auto">
        <div class="container-form-create-password">
          <h3 class="title">Create Password</h3>
          <p class="subtitle"><i>all fields required *</i></p>
          <div class="d-flex flex-column align-items-stretch">
            <b-form @submit.prevent="createPassword">
              <b-form-group :invalid-feedback="password.invalidFeedback" :state="password.state">
                <b-form-input v-model="password.value" type="password" placeholder="New Password *" />
              </b-form-group>
              <b-form-group :invalid-feedback="confirmPassword.invalidFeedback" :state="confirmPassword.state">
                <b-form-input v-model="confirmPassword.value" type="password" placeholder="Confirm New Password *" />
              </b-form-group>
              <div class="d-flex justify-content-around buttons">
                <b-button type="submit" class="text-uppercase" variant="primary">Create</b-button>
              </div>
            </b-form>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { UserService } from '@/core/services'
import { AppStorage } from '@/core/utils'

export default {
  name: 'CreatePasswordPage',
  data () {
    return {
      loading: false,
      user: AppStorage.getUser(),
      password: {
        value: '',
        state: true,
        invalidFeedback: ''
      },
      confirmPassword: {
        value: '',
        state: true,
        invalidFeedback: ''
      }
    }
  },
  methods: {
    validateRequired (field) {
      field.state = true
      if (field.value === '') {
        field.invalidFeedback = 'This field is required.'
        field.state = false
      }
    },
    validateConfirmPassword () {
      this.validateRequired(this.confirmPassword)
      if (this.confirmPassword.state) {
        this.confirmPassword.state = this.password.value === this.confirmPassword.value
        if (!this.confirmPassword.state) {
          this.confirmPassword.invalidFeedback = 'New password and confirm password must be the same.'
        }
      }
    },
    async createPassword () {
      this.validateRequired(this.password)
      this.validateConfirmPassword()
      if (this.password.state && this.confirmPassword.state) {
        this.loading = true
        try {
          await UserService.createPassword(this.password.value)
          await this.$bvModal.msgBoxOk('Password created successfully')
          if (this.user.force_password_reset) {
            this.$router.push({ name: 'ChangeAvatarPage' })
          } else {
            this.$router.push({ name: 'MyClassroomsPage' })
          }
        } catch (e) {
          const errorMessage = e.message ? e.message : 'Error while trying to create password.'
          console.log(e, errorMessage)
          await this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
        } finally {
          this.loading = false
        }
      }
    }
  },
  watch: {
    'password.value': function () {
      this.validateRequired(this.password)
      this.validateConfirmPassword()
    },
    'confirmPassword.value': function () {
      this.validateConfirmPassword()
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"
.create-password-container
  height 80%
  margin-top 110px

.container-form-create-password
  text-align center

.form-group-password
  margin-top 40px

.form-group-confirm-new-password
  margin-bottom 1rem

.form-control
  font-size 14px !important

.subtitle
  font-size 14px
</style>
