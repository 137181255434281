<template>
<b-row class="add-classroom-container">
  <b-col cols="10" sm="8" md="6" lg="4" xl="4" class="align-self-center mx-auto">
    <div class="d-flex flex-column align-items-center">
      <h3 class="title">Add Classroom</h3>
      <p class="subtitle"><i>all fields required *</i></p>
    </div>
    <div class="d-flex flex-column align-items-stretch">
      <b-form @submit.prevent="addClassroom">
        <b-form-group :invalid-feedback="userId.invalidFeedback" :state="userId.state">
          <b-form-input v-model="userId.value" placeholder="User ID *" />
        </b-form-group>
        <b-form-group :invalid-feedback="classroomCode.invalidFeedback" :state="classroomCode.state">
          <b-form-input v-model="classroomCode.value" placeholder="Classroom Code *" />
        </b-form-group>
        <div class="d-flex justify-content-around buttons">
          <b-button :to="{ name: 'MyClassroomsPage' }" variant="primary">CANCEL</b-button>
          <b-button type="submit" variant="primary">ADD</b-button>
        </div>
      </b-form>
    </div>
  </b-col>
</b-row>
</template>

<script>
import { ClassroomService } from '@/core/services'
import { AppStorage } from '@/core/utils'

export default {
  name: 'AddClassroom',
  data () {
    return {
      loading: false,
      userId: {
        value: '',
        state: true,
        invalidFeedback: ''
      },
      classroomCode: {
        value: '',
        state: true,
        invalidFeedback: ''
      }
    }
  },
  methods: {
    validateRequired (field) {
      field.state = true
      if (field.value === '') {
        field.invalidFeedback = 'This field is required.'
        field.state = false
      }
    },
    validateUserId (field) {
      this.validateRequired(field)
      if (field.state) {
        const user = AppStorage.getUser()
        console.log(user)
        if (field.value === user.username) {
          field.invalidFeedback = 'Cannot use same User ID that you are login.'
          field.state = false
        }
      }
    },
    async addClassroom () {
      this.validateUserId(this.userId)
      this.validateRequired(this.classroomCode)
      if (this.userId.state && this.classroomCode.state) {
        this.loading = true
        try {
          await ClassroomService.add(this.classroomCode.value, this.userId.value)
          await this.$bvModal.msgBoxOk('Classroom added successfully')
          await this.$router.push({ name: 'MyClassroomsPage' })
        } catch (e) {
          const errorMessage = e.message ? e.message : 'Error while trying to add classroom.'
          console.log(e, errorMessage)
          await this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
        } finally {
          this.loading = false
        }
      }
    }
  },
  watch: {
    'userId.value': function () {
      this.validateRequired(this.userId)
    },
    'classroomCode.value': function () {
      this.validateRequired(this.classroomCode)
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"

.add-classroom-container
  margin-top 110px

.title
  margin-bottom 0

.subtitle
  color color-black-200
  font-size 12px
  margin-bottom 54px

.buttons
  margin-top 25px
</style>
