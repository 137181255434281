<template>
  <div class="section-my-classrooms-page">
    <h2 class="title text-center mb-0">MY CLASSROOM</h2>
    <h4 class="subtitle text-center mb-0">Select a Classroom</h4>
    <div class="section-my-classrooms">
      <b-overlay :show="loading">
        <classroom-grid :classrooms="classrooms" />
      </b-overlay>
    </div>
    <CreatePasswordModal />
    <form-payment-student :classroom="getFirstClassroom"></form-payment-student>
  </div>
</template>

<script>
import CreatePasswordModal from '@/modules/panel/components/CreatePasswordModal'
import { ClassroomGrid } from '../../components/MyClassrooms'
import { ClassroomService } from '@/core/services'
import { AppStorage } from '@/core/utils'
import FormPaymentStudent from '@/modules/panel/components/FormPaymentStudent.vue'

export default {
  name: 'MyClassroomsPage',
  components: {
    FormPaymentStudent,
    ClassroomGrid,
    CreatePasswordModal
  },
  data () {
    return {
      loading: true,
      user: AppStorage.getUser(),
      classrooms: []
    }
  },
  computed: {
    getFirstClassroom () {
      return this.classrooms.filter((x) => typeof x !== 'undefined').shift()
    }
  },
  async mounted () {
    if (this.user.force_password_reset) {
      this.$bvModal.show('create-password-modal')
    } else {
      AppStorage.setUser({
        ...this.user,
        isNewUser: false
      })
    }

    this.loading = true
    try {
      this.classrooms = await ClassroomService.list()
    } catch (e) {
      const errorMessage = e.message
        ? e.message
        : 'Error while loading classroom list.'
      await this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
    } finally {
      this.loading = false
    }

    await this.$nextTick()

    if (!this.user.force_password_reset) {
      if (this.getFirstClassroom && this.getFirstClassroom.showPaymentForm()) {
        await this.$bvModal.show('modal-form-payment')
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"

.section-my-classrooms
  margin-top 24px
  background-color #F5F6FF
  border-top 1px solid color-purple-100
  padding-top 32px
  text-align center
  padding-bottom 32px

.section-my-classrooms-page
  padding-right 15px
  padding-left 15px
  padding-top 40px
  padding-bottom 110px

@media (min-width: 768px)
  .section-my-classrooms-page
    padding-right 53px
    padding-left 53px

@media (min-width: 992px)
  .section-my-classrooms-page
    padding-right 150px
    padding-left 150px
</style>
