<!-- HTML -->
<template>
  <div id="container" ref="container" :class="`${containerClasses}`">
    <div v-if="showMessageNoUploadedVideos" class="nothing-area">
      <div class="nothing-icon"></div>
      <div class="nothing-title">NO UPLOADED VIDEOS</div>
      <div class="nothing-text">Your partner group has not yet created their first video</div>
    </div>

    <!-- items rendering -->
    <template v-for="(item) in itemsToDisplay">
      <slot :item="item"/>
    </template>

    <template v-if="loading">
      <!-- Loading component -->
      <div v-if="defaultLoading" id="loading-wrapper">
        <Loading :color="defaultLoadingColor"/>
      </div>
      <div v-else id="loading-wrapper">
        <slot name="loading"></slot>
      </div>
    </template>

    <div class="all-videos-link" v-if="showLinkAllVideos" @click="$emit('click-all-videos')">Show All Videos</div>
    <!-- list footer -->
    <div v-show="((page !== items.length - 1) || !loading)" id="end-of-list" ref="end-of-list"/>

  </div>
</template>

<!-- JAVASCRIPT -->
<script>
import 'lazy-load-list/lib/index.css'
import chunkArray from 'lazy-load-list/lib/chunkArray.js'
import Loading from 'lazy-load-list/vue/Loading.vue'

export default {
  name: 'LazyList',
  components: { Loading },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    itemsPerRender: {
      type: Number,
      default: 3
    },
    containerClasses: {
      type: String,
      default: ''
    },
    defaultLoading: {
      type: Boolean,
      default: true
    },
    defaultLoadingColor: {
      type: String,
      default: '#18191A'
    },
    showLinkAllVideos: {
      type: Boolean,
      default: false
    },
    showMessageNoUploadedVideos: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.updateList()
    this.$watch('data', function () {
      this.updateList()
    }, { deep: true })
  },
  mounted () {
    this.$refs.container.addEventListener('scroll', this.loadItems)
    this.loadItems()
  },
  beforeUnmount () {
    this.$refs.container.removeEventListener('scroll', this.loadItems)
  },
  data () {
    return {
      items: [],
      page: 0, // page represents the index of last small array in the list
      loading: false,
      itemsToDisplay: [] // the list of items to be rendered
    }
  },
  methods: {
    // set the list and update it when data changes
    updateList () {
      const chunckedArray = chunkArray(this.data, this.itemsPerRender) // chunkArray(data,itemsPerRender) to get array of small arrays
      this.items = chunckedArray

      if (this.itemsToDisplay.length > 0) {
        let itemsToDisplay = []
        const numberOfItems = this.itemsToDisplay.length > this.items.length ? this.items.length : this.itemsToDisplay.length
        for (let i = 0; i < numberOfItems; i++) {
          itemsToDisplay = [...itemsToDisplay, ...this.items[i]]
        }
        this.itemsToDisplay = itemsToDisplay
        this.page = this.itemsToDisplay.length - 1
      } else {
        this.itemsToDisplay = chunckedArray[0]
      }
    },

    // load more items when scrolling to the end of the list
    loadItems () {
      if (this.page === this.items.length - 1) return

      if (this.page > this.items.length - 1) {
        this.page = this.items.length - 1
      }

      const element = this.$refs['end-of-list'] // this.endOfList;
      if (!element) return

      const position = element.getBoundingClientRect()

      // checking whether fully visible

      // increase screen height to 40px to load videos on mobile when scrolling
      const screenHeight = window.innerHeight + 40

      if ((position.top >= 0 && position.bottom <= screenHeight) && !this.loading) {
        this.loading = true
        this.page++
        setTimeout(() => {
          this.itemsToDisplay = [...this.itemsToDisplay, ...this.items[this.page]]
          this.loading = false
          this.loadItems()
        }, 500)
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"
@import "~@/core/stylus/dropdown.styl"

.all-videos-link
  text-align center
  font font-opensans-semibold
  color color-link
  cursor pointer

.nothing-area
  margin auto
  padding 80px
  text-align center
  max-width 375px
  padding-top 220px

  .nothing-icon
    background url('~@/assets/images/alert-icon.svg')
    width 100%
    height 80px
    background-repeat no-repeat
    background-position center
    background-size contain
    margin-bottom 10px

  .nothing-title
    font-size 16px
    font-weight bold
    margin-bottom 5px

  .nothing-text
    font-size 14px
</style>
