<template>
  <main-layout>
    <router-view></router-view>
  </main-layout>
</template>

<script>
import MainLayout from '@/core/components/PageBase/MainLayout'

export default {
  name: 'SecurityView',
  components: { MainLayout }
}
</script>

<style scoped>

</style>
