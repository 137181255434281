<template>
  <div>
    <div class="board-item">
      <div class="photo-area">
        <div class="photo" v-if="message.user.teacher.avatar" :style="avatarStyle"></div>
        <b-avatar v-else></b-avatar>
      </div>
      <div class="info-area">
        <div ref="detail"
             :class="{'info-area-detail': !expandedComment, 'info-area-detail__expanded': expandedComment}">
          <div class="user-name">{{ message.user.teacher.full_name }} <span class="teacher">Teacher</span></div>
          <div class="date">{{ message.created_at | dateElapseTime }}</div>
          <div class="subject">{{ message.message_title }}</div>
          <div class="content">{{ message.message_text }}</div>
          <div class="links">
            <ul>
              <li v-for="(link, index) in message.links" :key="'message-link' + index">
                <a :href="link.url" class="link" target="_blank">
                  {{ link.url }}
                </a>
              </li>
            </ul>
          </div>
          <div class="images">
            <a v-for="(media, index) in message.media"
               :key="'message-image' + index"
               class="a-image"
               target="_blank"
               :href="media.url">
               <thumbnail-attachments :data="media"/>
               <div class="label">{{truncateString(media.label, 20)}}</div>
            </a>
          </div>
        </div>
        <div class="info-area-action">
          <a v-if="triggerShowMore" href="javascript:void(0)" @click.prevent="onShowMore" class="show-more">SHOW
            MORE</a>
          <a v-if="expandedComment" href="javascript:void(0)" @click.prevent="onShowMore" class="show-more">SHOW
            LESS</a>
        </div>
      </div>
      <div class="action-area">
        <b-button class="reply-btn"
                  :disabled="classroomExchangeExpired"
                  @click="commentMessage(message.id)">Reply</b-button>
        <div class="comment-title">
          {{ message.comments.length }} {{ message.comments.length === 1 ? 'comment' : 'comments' }}
        </div>
        <div v-if="message.comments.length > 0" class="see-comments" @click="displayComments">{{ showHideComments }}
        </div>
        <div v-if="message.comments.length > 0" class="arrow" @click="displayComments">
          <div class="down-arrow" :class="showComments ? 'down' : 'up'"></div>
        </div>
      </div>
      <hr v-show="showComments">
    </div>
    <div class="comment-area" :hidden="!showComments">
      <MessageBoardComment v-bind:key="'comment-' + index" :comment="item"
                           v-for="(item, index) in message.comments"></MessageBoardComment>
    </div>
  </div>
</template>

<script>
import MessageBoardComment from './MessageBoardComment'
import ThumbnailAttachments from '@/core/components/ThumbnailAttachments'
import { ClassroomExchangeMixins } from '@/modules/mixins'

export default {
  name: 'MessageBoardItem',
  mixins: [ClassroomExchangeMixins],
  components: {
    MessageBoardComment,
    ThumbnailAttachments
  },
  data () {
    return {
      showComments: false,
      expandedComment: false,
      triggerShowMore: false
    }
  },
  props: {
    message: {
      type: [Object, Array]
    }
  },
  mounted () {
    this.$nextTick(function () {
      this.determineShowMore()
    })
  },
  updated () {
    this.$nextTick(function () {
      this.determineShowMore()
    })
  },
  methods: {
    displayComments () {
      this.showComments = !this.showComments
    },
    commentMessage (messageId) {
      this.$emit('on-selected-message-id', messageId)
      this.$bvModal.show('message-form-modal')
    },
    onShowMore () {
      this.expandedComment = !this.expandedComment
    },
    determineShowMore () {
      this.triggerShowMore = this.$refs.detail &&
          this.$refs.detail.offsetHeight < this.$refs.detail.scrollHeight
    },
    truncateString (str, num) {
      return str.length > num ? str.slice(0, num) + '...' : str
    }
  },
  computed: {
    showHideComments () {
      return !this.showComments ? 'Show comments' : 'Hide comments'
    },
    avatarStyle () {
      return {
        backgroundImage: `url("${this.message.user.teacher.avatar}")`
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"
.board-item
  border-top 1px solid #ECE8E8

  .photo-area
    display inline-block
    vertical-align top
    width 15%
    padding 16px 10px 10px 8px
    max-width 50px
    @media (min-width 515px)
      max-width 75px

    .photo
      border-radius 50%
      background-repeat no-repeat
      background-size cover
      background-position center
      height 32px
      width 32px
      @media (min-width: 515px)
        height 50px
        width 50px

    ::v-deep
      .b-avatar
        width 32px
        height 32px
        @media (min-width: 515px)
          width 50px
          height 50px

  .info-area
    display inline-block
    vertical-align top
    width 85%
    color #3D3D3D
    padding 16px 14px 12px 0px
    border-bottom 1px solid #ECE8E8

    &-detail
      --lines: 5
      white-space: pre-line
      display: -webkit-box
      max-width: 100%
      -webkit-line-clamp: var(--lines)
      -webkit-box-orient: vertical
      overflow: hidden
      text-overflow: ellipsis

      &__expanded
        display block
        white-space: pre-line

    .user-name
      font-weight bold
      text-transform capitalize
      @media (min-width: 992px)
        font-size 16px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 15px
      @media (max-width: 767px)
        font-size 14px

    .teacher
      font-weight 400
      font-style italic
      margin-left 4px
      @media (min-width: 992px)
        font-size 16px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 15px
      @media (max-width: 767px)
        font-size 14px

    .date
      @media (min-width: 992px)
        font-size 16px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 15px
      @media (max-width: 767px)
        font-size 14px

    .subject
      font-weight bold
      text-transform uppercase
      margin-bottom 5px
      margin-top 7px
      word-wrap: break-word
      @media (min-width: 992px)
        font-size 16px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 15px
      @media (max-width: 767px)
        font-size 14px

    .content
      word-wrap: break-word
      @media (min-width: 992px)
        font-size 16px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 15px
      @media (max-width: 767px)
        font-size 14px

    .links ul
      list-style none
      padding 0

      li:first-child
        margin-top 1rem

      .link
        text-transform uppercase
        margin-bottom 5px
        @media (min-width: 992px)
          font-size 14px
        @media (min-width: 768px) and (max-width: 992px)
          font-size 13px
        @media (max-width: 767px)
          font-size 12px

    .show-more
      color #262626
      text-decoration underline
      text-transform uppercase
      font-size 12px
      margin-bottom 5px
      @media (min-width: 992px)
        font-size 14px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 13px
      @media (max-width: 767px)
        font-size 12px

    .images
      display flex
      overflow-x auto

      .a-image
        display flex
        flex-direction column
        justify-content center
        align-items center
        vertical-align top
        margin-right 5px
        margin-top 5px
        margin-bottom 10px
        background-repeat no-repeat
        background-size cover
        background-position center
        border 2px solid color-blue-100
        padding 10px

        .label
          margin-top 5px

  .action-area
    text-align right
    height 45px
    line-height 45px
    padding-top 4px

    .reply-btn
      display inline-block
      margin-right 14px
      font-size 12px
      font-weight 600
      background-color color-blue-100
      border solid 2px color-blue-200
      height 29px
      vertical-align middle
      border-radius 4px
      text-align center
      color white

    .comment-title
      display inline-block
      color color-black-200
      margin-right 8px
      @media (min-width: 992px)
        font-size 16px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 15px
      @media (max-width: 767px)
        font-size 14px

    .see-comments
      display inline-block
      color color-black-200
      font-weight bold
      margin-right 5px
      @media (min-width: 992px)
        font-size 16px
        width 132px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 15px
        width 124px
      @media (max-width: 767px)
        font-size 14px
        width 116px

    .arrow
      display inline-block
      vertical-align middle
      height 15px
      width 15px
      background-color color-blue-100
      border-radius 4px

      .down-arrow
        height 7px
        width 7px
        border-bottom 2px solid white
        border-right 2px solid white
        margin-left 4px

        &.down
          transition 0.2s ease-in-out
          transform rotate(45deg)
          margin-top 2px

        &.up
          transition 0.2s ease-in-out
          transform rotate(135deg)
          margin-top 4px
          margin-left 5px

  hr
    border-top 1px solid #ECE8E8
    margin-top 16px

.comment-area
  width 85%
  margin-left 15%
</style>
