import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/core/plugins'
import '@/core/stylus/main.styl'
import '@/core/directives'
import { OfflineMixin } from '@/core/mixins/OfflineMixin'
import browserDetect from 'vue-browser-detect-plugin'

Vue.config.productionTip = false
Vue.use(require('vue-cookies'))
Vue.use(browserDetect)
Vue.mixin(OfflineMixin)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
