<template>
  <media-query-provider :queries="$options.queries">
    <router-view />
  </media-query-provider>
</template>
<script>
import { MediaQueryProvider } from 'vue-component-media-queries'
import { reloadManager } from '@/core/utils'
const queries = {
  mobile: '(max-width: 768px)'
}
export default {
  name: 'App',
  queries,
  components: {
    MediaQueryProvider
  },
  mounted () {
    reloadManager.start()
  }
}
</script>
