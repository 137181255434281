<template>
  <b-overlay :show="loading">
    <DashboardHeader :current_tab="'posts'"></DashboardHeader>
    <div class="my-posts-container">
      <div class="filter-area">
        <b-dropdown class="luv-dropdown filter-dropdown" :text="filter.label">
          <b-dropdown-item v-for="(option, index) in options" :key="index" :value="option.value"
                           @click="filter = option">
            {{ option.label }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="posts-area">
        <div class="area-title">
          My Task Video Posts
        </div>
        <PostItem v-bind:key="'index' + index" :submission="item" v-for="(item, index) in filteredSubmissions"></PostItem>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import moment from 'moment'
import DashboardHeader from '../../components/Dashboard/DashboardHeader'
import PostItem from '../../components/Dashboard/PostItem'
import { ClassroomService } from '@/core/services'

export default {
  name: 'MyPostsPage',
  components: {
    DashboardHeader,
    PostItem
  },
  props: {
    classroomId: {
      type: Number,
      required: true
    },
    taskId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      filterBy: 'newest',
      filter: {
        value: 'NEWEST',
        label: 'NEWEST'
      },
      options: [
        {
          value: 'NEWEST',
          label: 'NEWEST'
        },
        {
          value: 'OLDEST',
          label: 'OLDEST'
        }
      ],
      submissions: []
    }
  },
  methods: {
    async init () {
      this.loading = true
      this.options = [{ value: 'NEWEST', label: 'NEWEST' }, { value: 'OLDEST', label: 'OLDEST' }]
      try {
        const response = await ClassroomService.getSubmissions(this.classroomId)
        this.submissions = response.data
        this.submissions.forEach(s => {
          const option = this.options.find(o => o.value === s.class_task_id)
          if (!option) {
            this.options.push({ value: s.class_task_id, label: s.task.task_name })
          }
        })
      } catch (e) {
        const errorMessage = e.message ? e.message : 'Error while loading submissions.'
        console.log(e, errorMessage)
        await this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
      } finally {
        this.loading = false
      }
    },
    sortResponses () {
      if (['NEWEST', 'OLDEST'].includes(this.filter.value)) {
        this.submissions.sort((a, b) => {
          const dateA = moment(a.created_at)
          const dateB = moment(b.created_at)
          const dateDiff = dateB.diff(dateA)
          return this.filter.value === 'NEWEST' ? dateDiff : -1 * dateDiff
        })
      }
    }
  },
  async created () {
    await this.init()

    if (this.taskId) {
      this.options.map((opt) => {
        if (opt.value === this.taskId) {
          this.filter = opt
        }
      })
    }
  },
  computed: {
    filteredSubmissions: function () {
      return this.submissions.filter(s => {
        const taskId = this.filter.value
        if (['NEWEST', 'OLDEST'].includes(taskId)) {
          return true
        }
        return s.class_task_id === taskId
      })
    }
  },
  watch: {
    classroomId: function () {
      this.init()
    },
    'filter.value': function () {
      this.sortResponses()
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"
@import "~@/core/stylus/dropdown.styl"

.my-posts-container
  max-width 620px
  margin auto

  .filter-area
    margin 10px 15px
    margin-top 20px
    .filter-dropdown
      min-width 140px
      ::v-deep
        .dropdown-menu .dropdown-item
          text-overflow ellipsis
          overflow hidden

    .form-group
      margin 0

    ::v-deep
      .custom-dropdown
        width 140px
        @media (min-width: 992px)
          font-size 14px
        @media (min-width: 768px) and (max-width: 992px)
          font-size 12px
        @media (max-width: 767px)
          font-size 10px

  .posts-area:last-child
    border-bottom solid 1px color-gray-400

  .posts-area
    margin 10px 15px

    .area-title
      color #3D3D3D
      margin-bottom 10px
      @media (min-width: 992px)
        font-size 16px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 15px
      @media (max-width: 767px)
        font-size 14px
</style>
