<template>
  <div :class="classFormInputContent">
    <input
      :id="'form-input-file-' + _uid"
      ref="uploader"
      class="d-none"
      type="file"
      :accept="accept"
      @change="onFileChanged"
    >
    <label :for="'form-input-file-' + _uid" class="form-upload-file">
      <span v-if="placeHolder" class="form-file-text">{{ placeHolder }}</span>
      <button type="button" class="btn-common btn-input-file" @click="openUploadFile">
        <b-spinner v-if="loading" label="Spinning"></b-spinner>
        {{ labelButton }}
      </button>
    </label>
  </div>
</template>

<script>
import { ClassroomService, UploadService } from '@/core/services'
import { formatBytes } from '@/core/utils'

export default {
  name: 'UploadFile',
  props: {
    accept: {
      type: String,
      default: 'image/*'
    },
    classFormInput: {
      type: String,
      default: null
    },
    placeHolder: {
      type: String,
      default: null
    },
    labelButton: {
      type: String,
      default: 'Upload File'
    },
    autoUpload: {
      type: Boolean,
      default: false
    },
    maxSize: {
      type: Number,
      default: 262144000
    },
    classroomId: {
      type: Number,
      required: true
    },
    taskId: {
      type: Number,
      required: true
    },
    submissionId: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      currentFiles: [],
      loading: false
    }
  },
  methods: {
    openUploadFile () {
      this.$refs.uploader.click()
    },
    clearUploadFile () {
      this.$refs.uploader.value = null
    },
    onFileChanged (e) {
      const fileList = e.target.files || e.dataTransfer.files
      if (!fileList.length) {
        return
      }
      const file = fileList[0]

      if (file.size > this.maxSize) {
        this.$emit('on-file-error', {
          code: 'max_size_exceeded',
          message: `File must not be higher than ${formatBytes(this.maxSize)}`
        })
        return
      }

      this.currentFiles = [{
        url: URL.createObjectURL(file),
        objectURL: URL.createObjectURL(new Blob([file])),
        file: file,
        index_file: 1,
        type_file: 'media',
        filename: file.name,
        customData: UploadService.createFileNameData(file, this.classroomId, this.taskId, this.submissionId)
      }]

      this.$emit('on-file-select', { files: this.currentFiles })

      if (this.autoUpload) {
        this.onFileUpload()
      }
    },
    async onFileUpload () {
      this.loading = true
      this.$emit('on-file-upload-start')

      try {
        const response = await UploadService.uploadFile(
          this.currentFiles[0].file,
          this.currentFiles[0].customData,
          (progress) => {
            this.$emit('UploadProgress', progress)
          }
        )

        await ClassroomService.checkSubmissionVideo(this.currentFiles[0].customData.fileName, this.classroomId)

        this.loading = false

        this.$emit('on-file-upload-success', {
          file: response,
          error: null
        })
      } catch (e) {
        try {
          await ClassroomService.deleteSubmissionVideo(this.currentFiles[0].customData.baseName + '.mp4', this.classroomId)
        } catch (ee) {
          e.message = ee.message
        }

        this.loading = false
        this.$emit('on-file-upload-error', e)
      }
    },
    getSelectedFileData () {
      return this.currentFiles[0] ? this.currentFiles[0].customData : null
    }
  },
  computed: {
    classFormInputContent () {
      const className = {
        'form-upload': true
      }

      className[this.classFormInput] = !!this.classFormInput
      // className['form-upload-error'] = this.hasErrors

      return {
        ...className
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"

.form-upload
  display block

  &-file
    margin-bottom 0

.btn-input-file
  background-color color-blue-100
  border 3px solid color-blue-200
  border-radius 4px
  font font-opensans-bold
  font-size 14px
  letter-spacing 1.6px
  color color-white-100
  text-align center
  flexbox(flex)
  align-items(center)
  justify-content(center)
  padding 0 10px
  text-decoration none
  text-transform capitalize

  &:focus,
  &:hover
    box-shadow none

  .icon-upload
    padding-left 5px

</style>
