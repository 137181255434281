<template>
  <b-overlay :show="loading">
    <DashboardHeader :current_tab="'board'"></DashboardHeader>
    <div class="board-container">
      <MessageBoardItem :key="'message' + index" :message="item" v-for="(item, index) in messages"
                        @on-selected-message-id="onSelectedMessageId"></MessageBoardItem>
    </div>
    <MessageFormModal @on-post-message="onPostMessage"></MessageFormModal>
  </b-overlay>
</template>

<script>
import DashboardHeader from '../../components/Dashboard/DashboardHeader'
import MessageBoardItem from '../../components/Dashboard/MessageBoardItem'
import { ClassroomService } from '@/core/services'
import MessageFormModal from '@/modules/panel/components/Dashboard/MessageFormModal'

export default {
  name: 'MessageBoardPage',
  components: {
    DashboardHeader,
    MessageBoardItem,
    MessageFormModal
  },
  data () {
    return {
      loading: true,
      messages: [],
      selectedMessageId: null,
      interval: null
    }
  },
  mounted () {
    this.getMessages().then(() => {
      this.interval = setInterval(() => {
        this.getMessages(false)
      }, 15000)
    })
  },
  methods: {
    getMessages (showLoading = true) {
      if (showLoading) {
        this.loading = true
      }
      return ClassroomService.listMessages(this.$route.params.classroomId).then((response) => {
        this.messages = response.data
        this.loading = false
      })
    },
    displayComments () {
      this.showComments = !this.showComments
    },
    onSelectedMessageId (messageId) {
      this.selectedMessageId = messageId
    },
    onPostMessage (form) {
      ClassroomService.responseMessages(this.$route.params.classroomId, this.selectedMessageId, form).then((response) => {
        this.getMessages().then(() => {
          this.$bvModal.hide('message-form-modal')
        })
      })
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"
.board-container
  max-width 620px
  margin auto
  padding 15px 21px 20px 8px
</style>
