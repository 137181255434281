import { ClassroomService } from '@/core/services'
import moment from 'moment'
import { ClassroomPaymentError } from '@/core/services/classrooms'

export const ClassroomExchangeMixins = {
  data () {
    return {
      myClassroomId: this.$route.params.classroomId,
      myClassroom: {}
    }
  },
  computed: {
    classroomExchangeExpired () {
      const currentTimestamp = moment()
      return moment(currentTimestamp).isAfter(this.myClassroom.expire_at)
    },
    isMainClassroom () {
      return !!this.myClassroom.class_pairing?.id
    }
  },
  async created () {
    await ClassroomService.getClassroom(this.myClassroomId)
      .then((response) => {
        this.myClassroom = response
      })
      .catch((e) => {
        const errorMessage = e.message
          ? e.message
          : 'Error while loading classroom.'
        this.$bvModal
          .msgBoxOk(`${errorMessage} Please try again.`)
          .then((value) => {
            if (value) {
              if (e instanceof ClassroomPaymentError) {
                this.$router.push({ name: 'MyClassroomsPage' })
              }
            }
          })
      })
  }
}
