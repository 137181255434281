import { http } from './http'
import { AppStorage } from '@/core/utils'

const resource = '/api/student'
const teacherPath = resource + '/teacher'
const userValidation = () => {
  const user = AppStorage.getUser()
  return user.is_teacher ? teacherPath : resource
}

export default {
  createPassword (password) {
    return http.post(`${resource}/password`, {
      password: password
    }).then(response => {
      if (response.success === true) {
        AppStorage.setUser({
          force_password_reset: 0,
          token: response.data.token,
          isNewUser: true
        })
      }

      return response
    })
  },
  updatePassword (password, passwordOld) {
    return http.put(`${resource}/password`, {
      password: password,
      password_old: passwordOld
    }).then(response => {
      if (response.success === true) {
        AppStorage.setUserToken(response.data.token)
      }

      return response
    })
  },
  loginWithToken (token) {
    return http.post(`${resource}/token/refresh`, {
      refreshToken: token
    }).then(response => {
      AppStorage.setUserToken(response.data.token)
    })
  },
  updateAvatar (thumbnailUrl) {
    return http.put(`${resource}/avatar`, { thumbnail: thumbnailUrl }).then(response => {
      if (response.success === true) {
        AppStorage.setUserAvatar(response.data.thumbnail)
      }

      return response
    })
  },
  getProfile () {
    return http.get(`${userValidation()}/profile`).then((response) => {
      if (response.success) {
        // set new user info
        const profile = response.data || {}

        return AppStorage.setUser(profile)
      } else {
        return null
      }
    }
    )
  },
  logout () {
    // remove all data from local storage (including user) to log out
    AppStorage.clear()
  }
}
