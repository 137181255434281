<template>
  <div></div>
</template>

<script>
import { AppStorage } from '@/core/utils'
import { UserService, ClassroomService } from '@/core/services'
import router from '@/router'
export default {
  name: 'LoginTeacherByTokenPage',
  async mounted () {
    const teacherToken = this.$route.query.teacher_token
    const studentToken = this.$route.query.student_token
    const userType = this.$route.query.user_type
    const classroomId = this.$route.query?.classroom_id
    const trigger = this.$route.query?.trigger
    const submissionId = this.$route.query?.submission_id
    let taskId = this.$route.query?.task_id

    if (!teacherToken || !studentToken || !userType) {
      router.push({ name: 'LoginPage' })
    } else {
      AppStorage.clear()
      await AppStorage.setUserTeacherToken(teacherToken, studentToken, userType)
      await UserService.getProfile()

      const triggers = {
        partnerStudentCreateTrigger: 'partner_student_create_trigger',
        partnerStudentRespondTrigger: 'partner_student_respond_trigger'
      }

      let isPartnerStudentSubmission

      if (classroomId && submissionId) {
        let response
        response = await ClassroomService.getSubmission(classroomId, submissionId).catch((e) => console.log(e))
        if (!response?.success) {
          response = await ClassroomService.getClassroomPartnerStudentSubmission(classroomId, submissionId)
          isPartnerStudentSubmission = true
        }
        taskId = response?.data?.class_task_id
      }
      if ((taskId && trigger === triggers.partnerStudentCreateTrigger) || isPartnerStudentSubmission) {
        return router.push({ name: 'ClassroomDetailPage', params: { classroomId, taskId, showDropdown: true } })
      }
      if ((taskId && trigger === triggers.partnerStudentRespondTrigger) || (taskId && submissionId)) {
        return router.push({ name: 'MyPostsPage', params: { classroomId, taskId } })
      }
      if (classroomId) {
        router.push({ name: 'MyTasksPage', params: { classroomId } })
      } else {
        router.push({ name: 'MyClassroomsPage' })
      }
    }
  }
}
</script>
