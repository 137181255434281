import { render, staticRenderFns } from "./UploadOrRecordPage.vue?vue&type=template&id=c6ae3994&scoped=true&"
import script from "./UploadOrRecordPage.vue?vue&type=script&lang=js&"
export * from "./UploadOrRecordPage.vue?vue&type=script&lang=js&"
import style0 from "./UploadOrRecordPage.vue?vue&type=style&index=0&id=c6ae3994&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6ae3994",
  null
  
)

export default component.exports