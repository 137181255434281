// import LoginPage from '../pages/LoginPage'
import CreatePasswordPage from '../pages/CreatePasswordPage'
import ChangePasswordPage from '../pages/ChangePasswordPage'
import AddClassroom from '../pages/AddClassroom'
import PanelView from '../view/PanelView'
import ClassroomDetailPage from '../pages/Classroom/ClassroomDetailPage'
import MyClassroomsPage from '../pages/Classroom/MyClassroomsPage'
import NotificationsPage from '../pages/NotificationsPage'
import UploadOrRecordPage from '../pages/Classroom/UploadOrRecordPage'
import VideoRecordPage from '../pages/Classroom/VideoRecordPage'
import MyTasksPage from '../pages/Tasks/MyTasksPage'
import TaskDetailPage from '../pages/Tasks/TaskDetailPage'
import MyResponsesPage from '../pages/Responses/MyResponsesPage'
import ResponseDetailPage from '../pages/Responses/ResponseDetailPage'
import MyPostsPage from '../pages/Posts/MyPostsPage'
import PostDetailPage from '../pages/Posts/PostDetailPage'
import MessageBoardPage from '../pages/MessageBoardPage/MessageBoardPage'
import ChangeAvatarPage from '@/modules/panel/pages/ChangeAvatarPage'

const routes = [
  {
    path: '/panel',
    component: PanelView,
    redirect: { name: 'MyClassroomsPage' },
    children: [
      {
        path: 'set-avatar',
        name: 'ChangeAvatarPage',
        component: ChangeAvatarPage,
        meta: {
          isAuthenticate: true,
          isTeacher: true
        }
      },
      {
        path: 'create-password',
        name: 'CreatePasswordPage',
        component: CreatePasswordPage,
        meta: {
          isAuthenticate: true
        }
      },
      {
        path: 'change-password',
        name: 'ChangePasswordPage',
        component: ChangePasswordPage,
        meta: {
          isAuthenticate: true,
          isTeacher: true
        }
      },
      {
        path: 'add-classroom',
        name: 'AddClassroom',
        component: AddClassroom,
        meta: { isAuthenticate: true }
      },
      {
        path: 'classrooms',
        name: 'MyClassroomsPage',
        component: MyClassroomsPage,
        meta: { isAuthenticate: true }
      },
      {
        path: 'classrooms/:classroomId/:taskId?',
        name: 'ClassroomDetailPage',
        component: ClassroomDetailPage,
        props: route => ({
          classroomId: parseInt(route.params.classroomId),
          taskId: route.params.taskId ? parseInt(route.params.taskId) : null,
          showDropdown: route.params.showDropdown ? Boolean(route.params.showDropdown) : null
        }),
        meta: {
          isAuthenticate: true,
          isNavigation: true
        }
      },
      {
        path: 'notifications/:classroomId?',
        name: 'NotificationsPage',
        component: NotificationsPage,
        meta: {
          isAuthenticate: true,
          isNavigation: true,
          isHeaderNavigation: false
        }
      },
      {
        path: 'record-upload/:classroomId/:taskId/:submissionId?',
        name: 'UploadOrRecordPage',
        component: UploadOrRecordPage,
        props: route => ({
          classroomId: parseInt(route.params.classroomId),
          taskId: parseInt(route.params.taskId),
          submissionId: route.params.submissionId ? parseInt(route.params.submissionId) : null
        }),
        meta: {
          isAuthenticate: true,
          isNavigation: true,
          isHeaderNavigation: false
        }
      },
      {
        path: 'video-record/:classroomId/:taskId/:submissionId?',
        name: 'VideoRecordPage',
        component: VideoRecordPage,
        props: route => ({
          classroomId: parseInt(route.params.classroomId),
          taskId: parseInt(route.params.taskId),
          submissionId: route.params.submissionId ? parseInt(route.params.submissionId) : null
        }),
        meta: {
          isAuthenticate: true,
          isNavigation: true,
          isHeaderNavigation: false
        }
      },
      {
        path: 'my-tasks/:classroomId',
        name: 'MyTasksPage',
        component: MyTasksPage,
        props: route => ({
          classroomId: parseInt(route.params.classroomId)
        }),
        meta: {
          isAuthenticate: true,
          isNavigation: true,
          isHeaderNavigation: false
        }
      },
      {
        path: 'my-tasks/:classroomId/:taskId/:taskAction',
        name: 'TaskDetailPage',
        component: TaskDetailPage,
        props: route => ({
          classroomId: parseInt(route.params.classroomId),
          taskId: parseInt(route.params.taskId),
          taskAction: route.params.taskAction
        }),
        meta: {
          isAuthenticate: true,
          isNavigation: true,
          isHeaderNavigation: false
        }
      },
      {
        path: 'my-responses/:classroomId',
        name: 'MyResponsesPage',
        component: MyResponsesPage,
        props: route => ({
          classroomId: parseInt(route.params.classroomId)
        }),
        meta: {
          isAuthenticate: true,
          isNavigation: true,
          isHeaderNavigation: false
        }
      },
      {
        path: 'my-responses/:classroomId/:responseId',
        name: 'ResponseDetailPage',
        component: ResponseDetailPage,
        props: route => ({
          classroomId: parseInt(route.params.classroomId),
          responseId: parseInt(route.params.responseId)
        }),
        meta: {
          isAuthenticate: true,
          isNavigation: true,
          isHeaderNavigation: false
        }
      },
      {
        path: 'my-posts/:classroomId',
        name: 'MyPostsPage',
        component: MyPostsPage,
        props: route => ({
          classroomId: parseInt(route.params.classroomId),
          taskId: parseInt(route.params.taskId)
        }),
        meta: {
          isAuthenticate: true,
          isNavigation: true,
          isHeaderNavigation: false
        }
      },
      {
        path: 'my-posts/:classroomId/:postId',
        name: 'PostDetailPage',
        component: PostDetailPage,
        props: route => ({
          classroomId: parseInt(route.params.classroomId),
          postId: parseInt(route.params.postId)
        }),
        meta: {
          isAuthenticate: true,
          isNavigation: true,
          isHeaderNavigation: false
        }
      },
      {
        path: 'message-board/:classroomId',
        name: 'MessageBoardPage',
        component: MessageBoardPage,
        props: route => ({
          classroomId: parseInt(route.params.classroomId)
        }),
        meta: {
          isAuthenticate: true,
          isNavigation: true,
          isTeacher: true,
          isHeaderNavigation: false
        }
      }
    ]
  }
]

export default routes
