<template>
  <div class="alert-container">
    <b-alert v-for="alert in alerts"
             :key="alert.id"
             :show="alert.show"
             :variant="alert.color"
             dismissible
             fade
             @dismissed="onHideAlert(alert)">
      <div v-html="alert.message"/>
    </b-alert>
  </div>
</template>

<script>
import { AlertsService } from '@/core/services'

export default {
  name: 'AlertMessage',
  components: {

  },
  data () {
    return {
      color: '',
      message: '',
      alerts: [],
      interval: null,
      user: JSON.parse(localStorage.getItem('user'))
    }
  },
  async mounted () {
    this.getAlert().then(() => {
      this.interval = setInterval(() => {
        this.getAlert()
      }, 300000)
    })
  },
  methods: {
    async getAlert () {
      await AlertsService.getAlert().then(({ data }) => {
        this.alerts = data.map(alert => {
          const alertClosedByUser = localStorage['alert' + alert.id + '_' + 'user' + this.user.id]
          return {
            ...alert,
            show: !alertClosedByUser
          }
        })
      })
    },
    onHideAlert (alert) {
      localStorage['alert' + alert.id + '_' + 'user' + this.user.id] = alert.id
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
}
</script>

<style lang="stylus" scoped>
.alert-container
  position fixed
  width 100%
  margin auto
  display flex
  justify-content center
  z-index 999

  .alert
    width 95%
    max-width 800px
    max-height 800px
    position absolute
    word-wrap break-word
    white-space pre-wrap
    overflow-y auto
    padding 0.75rem 1.25rem

    ::v-deep
      .close
        z-index auto
        padding 0
        padding-right 4px
</style>
