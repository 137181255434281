<template>
  <div class="back-button-area">
    <div class="icon-caret-left back-button-icon mr-1"/>
    <div @click="gotoPage" class="back-button">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: 'BackButton',
  props: {
    text: {
      type: String,
      default: 'Back'
    },
    routeName: {
      type: String,
      required: false
    },
    routeParams: {
      type: Object
    }
  },
  methods: {
    gotoPage () {
      if (this.routeName) {
        this.$router.push({
          name: this.routeName,
          params: { ...this.routeParams }
        })
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"

.back-button
  display inline

  &-icon
    display inline

    &:before
      font-size 9px
      line-height 16px
      color color-blue-100

  &-area
    cursor pointer
    margin-top 13px
    margin-bottom 10px
    margin-left 20px
    text-align left
    font-weight bold
    font-size 12px
    line-height 16px
    letter-spacing 0
    color color-blue-100
    @media (min-width: 992px)
      font-size 17px
    @media (min-width: 768px) and (max-width: 992px)
      font-size 17px
    @media (max-width: 767px)
      font-size 12px
</style>
