<template>
  <b-modal id="create-password-modal" centered hide-footer hide-header size="xs">
    <div class="modal-title">CREATE A PASSWORD</div>
    <div class="modal-text">To start, let’s create your own personal password.</div>
    <div class="modal-btns">
      <router-link class="btn-cell cancel" :to="{ name: 'CreatePasswordPage'}">
      <div class="btn-ok">
        OK
      </div>
      </router-link>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'CreatePasswordModal'
}
</script>

<style lang="stylus">
@import "~@/core/stylus/variables.styl"

#create-password-modal
  .modal-content
    margin auto
    width 280px
    height 280px
  .modal-title
    text-align center
    font-size 16px
    font-weight bold
    margin-top 65px
  .modal-text
    text-align center
    font-size 14px
    width 79%
    margin auto
    height 75px
    margin-top 10px
  .modal-btns
    text-align center
    .btn-cancel, .btn-ok
      text-align center
      display inline-block
      vertical-align top
      width 102px
      height 31px
      background-color color-blue-100
      border none
      border-radius 4px
      color white
      font-weight 600
      margin-bottom 25px
      letter-spacing 2px
      line-height 31px
    .btn-ok
      width 82px

</style>
