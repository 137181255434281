<template>
  <b-row class="container-media" :class="{'container-media--horizontal': isHorizontal}">
    <div class="container-video">
      <video-custom :src="submission.url"
                    :video_processed="submission.video_processed"
                    :submission-id="submission.id"
                    :classroom-id="classroomId"
                    @on-video-loaded="checkVideoOrientation"
                    ref="videoCustom"/>
    </div>
    <div class="container-name">
      <div class="task-name">{{ videoName }}</div>
    </div>
  </b-row>
</template>

<script>
import VideoCustom from '@/core/components/video/VideoCustom'
import { ClassroomExchangeMixins } from '@/modules/mixins'

export default {
  name: 'TaskVideoMedia',
  mixins: [ClassroomExchangeMixins],
  components: {
    VideoCustom
  },
  props: {
    submission: {
      type: Object,
      required: true
    },
    videoName: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isHorizontal: false
    }
  },
  methods: {
    checkVideoOrientation (video) {
      const videoWidth = video.videoWidth
      const videoHeight = video.videoHeight
      this.isHorizontal = videoWidth > videoHeight
      if (this.isHorizontal) {
        this.$nextTick(() => {
          this.$refs.videoCustom.drawVideo()
        })
      }
    }
  },
  computed: {
    classroomId () {
      return this.$route.params.classroomId
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"

.container-media
  background-color color-purple-100
  padding-top 40px
  max-width 400px
  margin auto
  margin-bottom 40px

  @media (min-width: 767px)
    max-width 540px

.container-video
  background-color #f3f3f3
  margin auto
  padding 0
  @media (min-width: 768px)
    margin auto
    width 490px
    height 665px
  @media (max-width: 767px)
    width 345px
    height 565px

  .video-overlay
    border-radius: 0
    width 100%
    height 100%

.container-media--horizontal
  max-width none
  @media (min-width: 767px)
    max-width 940px

  .container-video
    width 95% !important

.container-name
  word-break break-word
  padding 0 25px
  width 100%
  @media (max-width: 768px)
    padding 0 20px

  .task-name
    font font-opensans-semibold
    text-transform capitalize
    padding 20px 0

</style>
