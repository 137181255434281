/**
 * Common const to handle http requests and responses
 * src/http.js
 */
import axios from 'axios'
import qs from 'qs'
import { AuthService } from '@/core/services'
import { AppStorage } from '@/core/utils'

import router from '@/router'

/**
 *
 * parse error response
 */
function parseError (messages) {
  // error
  if (messages) {
    if (messages instanceof Array) {
      console.log('messages: ', messages)
      return Promise.reject(new Error(messages[0]))
    }
    if (messages instanceof Object && messages.detail) {
      // JWT DRF returns an object error response
      return Promise.reject(messages.detail)
    } else if (messages instanceof Object && messages.error_code && messages.error_code === 'validation_exception') {
      let messageError = messages.message

      console.log('messages: ', messages)

      for (const [field, errors] of Object.entries(messages.errors)) {
        messageError += `\n ${field}: ${errors.join(' ')}`
      }

      messages.message = messageError

      return Promise.reject(messages)
    } else {
      return Promise.reject(messages)
    }
  } else {
    return Promise.reject(new Error('Error'))
  }
}

/**
 * parse response
 */
function parseBody (response) {
  if (response.status === 200) {
    return response.data
  } else {
    return parseError(response.data.messages)
  }
}

/**
 * axios instance
 */
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  paramsSerializer: function (params) {
    return qs.stringify(params, { indices: false })
  }
})

// request header
instance.interceptors.request.use((config) => {
  // Do something before request is sent
  // api token
  const user = JSON.parse(localStorage.getItem('user'))
  if (user && user.token) {
    config.headers.Authorization = 'Bearer ' + user.token
  }
  return config
}, error => {
  return Promise.reject(error)
})

instance.interceptors.response.use(function (response) {
  return parseBody(response)
}, function (error) {
  const originalRequest = error.config ? error.config : {}
  const user = AppStorage.getUser()
  if (error.response && (error.response.status === 403 || error.response.status === 401) && user !== {} && !originalRequest._retry) {
    AuthService.logout()
    router.push({ name: 'LoginPage' })
  }

  let responseData = error.response && error.response.data ? error.response.data : {}
  if (error.response.status === 401 && user.token) {
    responseData = {
      ...responseData,
      message: 'You have been logged out of your account, log back in.'
    }
  }
  return parseError(responseData)
})

// request header
instance.interceptors.request.use((config) => {
  // Do something before request is sent
  // api token

  const user = JSON.parse(localStorage.getItem('user'))
  if (user && user.token) {
    config.headers.Authorization = 'Bearer ' + user.token
    if (user.teacher_token) {
      config.headers.AuthorizationTeacher = 'Bearer ' + user.teacher_token
    }

    if (user.user_type && user.user_type.slug === 'student-demo') {
      config.url = config.url.replace('/student/', '/student-demo/')
    }
  }
  return config
}, error => {
  return Promise.reject(error)
})

export const http = instance
