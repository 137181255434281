import { http } from './http'
import { AppStorage } from '@/core/utils'

const resource = '/api/student'

export default {
  login (username, password) {
    return http.post(`${resource}/login`, {
      username: username,
      password: password
    }).then(response => {
      if (response.success === true) {
        AppStorage.setUser(response.data)
      }

      return response
    })
  },
  loginWithToken (token) {
    return http.post(`${resource}/token/refresh`, {
      refreshToken: token
    }).then(response => {
      AppStorage.setUserToken(response.data.token)
    })
  },
  logout () {
    // remove all data from local storage (including user) to log out
    AppStorage.clear()
  }
}
