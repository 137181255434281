<template>
  <b-modal ref="flag-inappropriate-modal"
           centered
           hide-footer
           hide-header
           size="xs"
           @hidden="hideModal">
    <h2 class="title title--modal">Flag partner Video as Inappropriate</h2>
    <p>Please tell us why you have flagged this student video. *</p>
    <checkbox-group :options="options"
                    v-model="$v.optionsSelected.$model"
                    :validator="$v.optionsSelected"
                    variant-message-required="At least one reason must be selected"
                    custom-class="custom-checkbox-primary"/>
    <p class="informatoin-label">Your report will be sent for review and may be shared with one or both teachers, as needed.</p>
    <div class="d-flex justify-content-around buttons">
      <b-button variant="primary" @click="hideModal">CANCEL</b-button>
      <b-button variant="primary" @click="handleSendInappropriateVideo">SUBMIT</b-button>
    </div>
    <b-overlay no-wrap :show="loading"/>
  </b-modal>
</template>

<script>
import CheckboxGroup from '@/modules/panel/components/Form/CheckboxGroup.vue'
import { required } from 'vuelidate/lib/validators'
import { ClassroomService } from '@/core/services'

export default {
  name: 'FlagInappropriateModal',
  components: {
    CheckboxGroup
  },
  props: {
    classroomId: {
      type: [Number, String]
    },
    submissionId: {
      type: [Number, String]
    }
  },
  data () {
    return {
      options: [
        { text: 'Inappropriate or offensive language.', value: 'language' },
        { text: 'Inappropriate or offensive behavior.', value: 'behavior' },
        { text: 'Student shares last name or social media handles.', value: 'doxing' },
        { text: 'Bullying.', value: 'bullying' },
        { text: 'Other.', value: 'other' }
      ],
      optionsSelected: [],
      loading: false
    }
  },
  validations: {
    optionsSelected: {
      required
    }
  },
  methods: {
    showModal () {
      this.$refs['flag-inappropriate-modal'].show()
    },
    hideModal () {
      this.optionsSelected = []
      this.$v.optionsSelected.$reset()
      this.$refs['flag-inappropriate-modal'].hide()
    },
    handleSendInappropriateVideo () {
      this.$v.$touch()
      if (!this.$v.optionsSelected.$anyError) {
        this.loading = true
        const data = this.optionsSelected.reduce((a, v) => ({ ...a, [v]: true }), {})
        data.student_submission_id = this.submissionId
        data.classroom_id = Number(this.classroomId)
        ClassroomService.reportVideo(data).then((res) => {
          if (res.success) {
            this.$bvModal.msgBoxOk('Thank you. We will contact your teacher for any additional information needed or follow up.', {
              size: 'sm',
              dialogClass: 'modal-message-box',
              centered: true
            }).then(() => {
              this.hideModal()
              this.$emit('close-flag-inappropriate-modal')
            })
          } else {
            const errorMessage = res.message ? res.message : 'Error reporting.'
            this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
              size: 'sm',
              dialogClass: 'modal-message-box',
              centered: true
            })
          }
        }).finally(() => (this.loading = false))
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"

.title--modal
  text-align center
  font-size 24px
  margin-bottom 30px

.informatoin-label
  text-align center
  margin 30px 0

::v-deep
  .modal-body
    padding 3rem
    @media (max-width 400px)
      padding 1rem

  .custom-checkbox-primary
    column-count 1 !important

    .custom-control-inline
      display flex

      .custom-control-label
        font-size 15px

</style>
