<template>
  <b-nav :class="classNavNavigation">
    <router-link :to="{ name: 'MyTasksPage', params: { classroomId: $route.params.classroomId }}" custom
                 v-slot="{ isActive, href }">
      <b-nav-item :active="isActive" :href="href">
        <span class="icon icon-home-1"></span>
        <span v-if="navigationIsTop" class="text">Home</span>
      </b-nav-item>
    </router-link>
    <router-link :to="{ name: 'ClassroomDetailPage', params: { classroomId: $route.params.classroomId }}" custom
                 v-slot="{ isActive, href }">
      <b-nav-item :active="isActive" :href="href">
        <span class="icon icon-tv"></span>
        <span v-if="navigationIsTop" class="text">Dashboard</span>
      </b-nav-item>
    </router-link>
    <router-link :to="{ name: 'NotificationsPage', params: { classroomId: $route.params.classroomId }}" custom
                 v-slot="{ isActive, href }">
      <b-nav-item :active="isActive" :href="href" :class="classNavigationItem" id="popover-notification">
        <span :class="classNotificationsBell"></span>
        <span v-if="navigationIsTop" class="text">Notifications</span>
      </b-nav-item>
    </router-link>
    <popover-notification :lastResponseNotification="lastResponseNotification"
                          :response-notifications="responseNotifications"
                          :video-failed-notifications="videoFailedNotifications"
                          target="popover-notification"
                          @close-popover="closePopover"
                          :showPopover="showPopover"/>
    <b-nav-item href="https://levelupvillage.com/student-resources/" target="blank" class="info-circle-container">
      <b-icon icon="info-circle"
              class="info-circle"/>
      <span v-if="navigationIsTop" class="text">Resources</span>
    </b-nav-item>
  </b-nav>
</template>

<script>
import { NotificationService } from '@/core/services'
import { Notifications } from '@/core/utils'
import PopoverNotification from '../PopoverNotification.vue'

export default {
  name: 'NavigationPills',
  components: {
    PopoverNotification
  },
  props: {
    navigationIsTop: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      notifications: [],
      interval: null,
      showPopover: false,
      lastResponseNotification: {},
      responseNotifications: [],
      videoFailedNotifications: []
    }
  },
  async mounted () {
    this.getNotifications()
    this.interval = setInterval(() => {
      this.getNotifications()
    }, 20000)
  },
  methods: {
    getNotifications () {
      if (navigator.onLine) {
        NotificationService.list().then(response => {
          this.notifications = response.data
          this.responseNotifications = response.data.filter((notification) => {
            if (notification.status === 'unread' && notification.type === Notifications.type.studentPartnerRespondedToTheirVideo) {
              return notification
            }
          })
          this.videoFailedNotifications = response.data.filter((notification) => {
            if (notification.status === 'unread' && (notification.type === Notifications.type.studentFailedVideoConversion || notification.type === Notifications.type.studentResponseFailedVideo)) {
              return notification
            }
          })
          this.lastResponseNotification = this.responseNotifications[0] || {}
          const outNotificationsPage = this.$router.history.current.name !== 'NotificationsPage'
          this.showPopover = (!!this.lastResponseNotification.id || !!this.videoFailedNotifications.length) && outNotificationsPage
        }).catch(e => {
          console.log(e.message ? e.message : 'Error while trying notification bell.')
        })
      }
    },
    closePopover () {
      this.showPopover = false
    }
  },
  computed: {
    classNavNavigation () {
      const clazz = {
        'nav-navigation': true,
        'nav-justified': true,
        'align-items-center': true,
        'h-100': true
      }

      clazz['nav-navigation--top'] = this.navigationIsTop
      clazz['justify-content-center'] = this.navigationIsTop

      return {
        ...clazz
      }
    },
    classNotificationsBell () {
      const clazz = {
        icon: true,
        'icon-notification-1': true
      }

      clazz['has-notifications'] = this.hasUnreadNotifications

      return {
        ...clazz
      }
    },
    classNavigationItem () {
      return { 'nav-item-notifications': this.hasUnreadNotifications }
    },
    hasUnreadNotifications () {
      const result = this.notifications.filter(notification => notification.status === 'unread')
      return result.length
    }
  },
  destroyed () {
    clearInterval(this.interval)
    this.interval = null
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"

.nav-navigation {
  .nav-item-notifications {
    position relative
    &:before {
      content " "
      display: block
      position: absolute
      top: 13px
      left: 33px
      z-index 1
      width: 8px
      height: 8px
      background-color: #fe472a
      border-radius: 50%
    }
  }
  ::v-deep {
    .nav-item {
      .nav-link {
        font-size 1.3rem
        padding 0.625rem 0

        &.active {
          background-color color-blue-300

          .icon {
            &:before {
              padding-bottom 4px
              border-bottom 2px solid white
            }

            &.icon-tv {
              content: url(../../../assets/images/icon-tv-dark.svg);
              filter: brightness(5)
              border-bottom 2px solid white
            }

            &.icon-home-1 {
              content: url(../../../assets/images/icon-home-dark.svg);
              filter: brightness(5)
              border-bottom 2px solid white
            }

            &.icon-notification-1 {
              content: url(../../../assets/images/icon-notification-dark.svg);
              filter: brightness(5)
              border-bottom 2px solid white
            }
          }
        }

        .icon {
          margin-top: 2px;

          &:before {
            color white
          }
        }

        .icon-tv {
          content: url(../../../assets/images/icon-tv.svg);
          filter: brightness(5)
          height: 25px;
          padding-bottom: 4px;
        }

        .icon-home-1 {
          content: url(../../../assets/images/icon-home.svg);
          filter: brightness(5)
          height: 25px;
          padding-bottom: 4px;
        }

        .icon-notification-1 {
          content: url(../../../assets/images/icon-notification.svg);
          filter: brightness(5)
          height: 25px;
          padding-bottom: 4px;

          &.has-notifications {
            content: url(../../../assets/images/icon-notification-dark.svg);
          }
        }

        .info-circle {
          color white
          font-size 22px
          position relative
          bottom 7px
        }
      }
    }
  }

  &--top {
    ::v-deep {
      .nav-item {
        flex 0 0 auto

        &:first-child {
          .nav-link {
            padding-left 0
          }
        }

        &:last-child {
          .nav-link {
            padding-right 0
          }
        }

        .nav-link {
          font-size initial
          padding-left 1.5rem
          padding-right 1.5rem

          &.active {
            background-color transparent

            .icon {
              &:before {
                padding-bottom 4px
                border-bottom 2px solid color-blue-300
              }

              &.icon-tv {
                content: url(../../../assets/images/icon-tv-dark.svg);
                filter: none
                height: 27px;
                padding-bottom: 4px;
                border-bottom 2px solid color-blue-200
              }

              &.icon-home-1 {
                content: url(../../../assets/images/icon-home-dark.svg);
                filter: none
                height: 27px;
                padding-bottom: 4px;
                border-bottom 2px solid color-blue-200
              }

              &.icon-notification-1 {
                content: url(../../../assets/images/icon-notification-dark.svg);
                filter: none
                height: 27px;
                padding-bottom: 4px;
                border-bottom 2px solid color-blue-200
              }
            }
          }

          .icon {
            font-size 20px
            vertical-align middle

            &:before {
              color color-blue-300
            }
          }

          .icon-tv {
            content: url(../../../assets/images/icon-tv.svg);
            filter: none
            height: 25px;
            padding-bottom: 4px;
          }

          .icon-home-1 {
            content: url(../../../assets/images/icon-home.svg);
            filter: none
            height: 25px;
            padding-bottom: 4px;
          }

          .icon-notification-1 {
            content: url(../../../assets/images/icon-notification.svg);
            filter: none
            height: 25px;
            padding-bottom: 4px;
          }

          .info-circle {
            color color-blue-300
            font-size 22px
            position static
          }

          .text {
            vertical-align middle
            font-size 12px
            padding-left 5px
          }
        }
      }
    }
  }
}
</style>
