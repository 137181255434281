import { http } from './http'
import { AppStorage } from '@/core/utils'

const resource = '/api/student'
const teacherPath = resource + '/teacher'
const userValidation = () => {
  const user = AppStorage.getUser()
  return user.is_teacher ? teacherPath : resource
}

export default {
  saveVideoViews (classroomId, submissionId) {
    return http.post(`${userValidation()}/classrooms/${classroomId}/videos-view/${submissionId}`).then(response => {
      return response
    }
    )
  }
}
