<template>
  <b-col lg="9" class="column">
    <div class="task-area" v-if="showClassroomA && task.due_date_type === dueDateType.createTask">
      <div class="task-info mb-2">
        <div class="area-name">{{ task.task_name }}</div>
      </div>
      <router-link class="action-icon" :to="goToTaskDetailPageParams">
        <div class="task-item">
          <div v-if="task.due_date && !submissionsCount" class="status" :class="taskSubmissionStatus">
            <div class="status-text">DUE</div>
            <div class="status-text status-text--mm"
                 :class="{'status-text--expired': taskExpired(task.due_date)}">
                 {{setDueDate(task.due_date,'MMM')}}
            </div>
            <div class="status-text status-text--dd"
                 :class="{'status-text--expired': taskExpired(task.due_date)}">
                 {{setDueDate(task.due_date,'DD')}}
            </div>
          </div>
          <div v-else class="status" :class="taskSubmissionStatus">
            <div class="status-icon"
                 :class="`icon-status-${taskSubmissionStatus === 'to-do' ? 'todo' : taskSubmissionStatus}`"></div>
            <div class="status-text">{{ taskSubmissionStatus }}</div>
          </div>
          <div class="task-name">
            <div class="name">Create a Video</div>
          </div>
        </div>
      </router-link>
    </div>
    <div class="task-area" v-if="showPartnerB && task.due_date_type === dueDateType.responseTask">
      <div class="task-info mb-2">
        <div class="area-name">{{ task.task_name }}</div>
      </div>
      <router-link class="action-icon" :to="goToResponseDetailPageParams">
        <div class="task-item long mt-1">
          <div v-if="task.response_due_date && isResponseDueDate" class="status" :class="taskResponsesStatus">
            <div class="status-text">DUE</div>
            <div class="status-text status-text--mm"
                 :class="{'status-text--expired': taskExpired(task.response_due_date)}">
                 {{setDueDate(task.response_due_date,'MMM')}}</div>
            <div class="status-text status-text--dd"
                 :class="{'status-text--expired': taskExpired(task.response_due_date)}">
                 {{setDueDate(task.response_due_date,'DD')}}</div>
          </div>
          <div v-else class="status" :class="taskResponsesStatus">
            <div class="status-icon long"
                 :class="`icon-status-${taskResponsesStatus === 'to-do' ? 'todo' : taskResponsesStatus}`"></div>
            <div class="status-text">{{ taskResponsesStatus }}</div>
          </div>
          <div class="task-response">
            <div class="response-name">Respond to Global Partners</div>
            <div class="response-progress">
              <div class="timer-bar">
                <div class="bar">
                  <div class="fill" :style="{width: `${responsesCompletion}%`}"></div>
                </div>
              </div>
            </div>
            <div class="response-amount">
              You have responded to {{ responsesCount }} of {{ task.number_responses }} {{ task.number_responses === 1 ? 'video' : 'videos' }}
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </b-col>
</template>

<script>
import { TaskUtils } from '../../utils'
import moment from 'moment'

export default {
  name: 'TaskItem',
  props: {
    task: {
      type: [Object, Array]
    },
    classroomId: {
      type: Number,
      required: true
    },
    classroomExchangeExpired: {
      type: Boolean
    },
    isMainClassroom: {
      type: Boolean
    }
  },
  data () {
    return {
      actionCreateTask: TaskUtils.ACTION_CREATE_TASK,
      actionRespondAction: TaskUtils.ACTION_RESPOND_ACTION,
      dueDateType: {
        createTask: 'create',
        responseTask: 'response'
      }
    }
  },
  computed: {
    showClassroomA () {
      return ((this.task.task_type === 'classroom_a' && this.isMainClassroom) || (this.task.task_type === 'partner_b' && !this.isMainClassroom)) || this.task.task_type === 'both_classrooms'
    },
    showPartnerB () {
      return ((this.task.task_type === 'partner_b' && this.isMainClassroom) || (this.task.task_type === 'classroom_a' && !this.isMainClassroom)) || this.task.task_type === 'both_classrooms'
    },
    taskDueDate: function () {
      return this.task.due_date ? this.$options.filters.dateMonthDayYear(this.task.due_date) : 'none'
    },
    responsesCountCompleted: function () {
      return TaskUtils.getCompletedResponseCount(this.task)
    },
    responsesCount: function () {
      return this.task.teacher_responses ? this.task.teacher_responses.length : this.task.responses.length
    },
    submissionsCount: function () {
      return this.task.teacher_submissions ? this.task.teacher_submissions.length : this.task.submissions.length
    },
    taskSubmissionStatus: function () {
      return TaskUtils.getCreateTaskStatus(this.task)
    },
    taskResponsesStatus: function () {
      return TaskUtils.getRespondActionStatus(this.task)
    },
    responsesCompletion: function () {
      return TaskUtils.getCompletedResponsePercentage(this.task)
    },
    goToTaskDetailPageParams: function () {
      if (this.classroomExchangeExpired) {
        return ''
      }
      return {
        name: 'TaskDetailPage',
        params: {
          classroomId: this.classroomId,
          taskId: this.task.id,
          taskAction: this.actionCreateTask
        }
      }
    },
    goToResponseDetailPageParams: function () {
      if (this.classroomExchangeExpired) {
        return ''
      }
      return {
        name: 'TaskDetailPage',
        params: {
          classroomId: this.classroomId,
          taskId: this.task.id,
          taskAction: this.actionRespondAction
        }
      }
    },
    isResponseDueDate () {
      let taskDueDate = null
      if (this.taskResponsesStatus === 'to-do') {
        taskDueDate = !!this.task.response_due_date
      }
      return taskDueDate
    }
  },
  methods: {
    setDueDate (date, string) {
      switch (string) {
        case 'MMM':
          return moment(date).format('MMM')
        case 'DD':
          return moment(date).format('DD')
      }
    },
    taskExpired (expirationDate) {
      const currentTimestamp = moment()
      return moment(currentTimestamp).isAfter(expirationDate)
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"
.task-area
  background-color #fff
  box-shadow 0 2px 4px #00000029
  border 1px solid #E7E7E8
  border-radius 4px
  padding 7px 8px 20px 8px
  margin-bottom 20px

  .task-info
    .area-name
      font-size 14px
      line-height 21px
      font-weight bold
      color color-black-200
      white-space nowrap
      overflow hidden
      text-overflow ellipsis

      &::first-letter
        text-transform capitalize

    .area-date
      font-size 12px
      line-height 18px
      color color-black-200

  .task-item
    background-color #fff
    box-shadow 0 2px 4px #00000029
    border solid 1px #8d91c8
    @media (min-width: 992px)
      border-radius 4px
      height 69px
    @media (max-width: 991px)
      border-radius 10px
      height 60px
      &.long
        height 70px

    .status
      display inline-block
      vertical-align middle
      height 100%
      width 17%
      border 2px solid white
      @media (min-width: 992px)
        border-radius 4px 0 0 4px
      @media (max-width: 991px)
        border-radius 10px 0 0 10px

      &.to-do
        background-color #C5C7FE

      &.blocked
        background-color #F89484

      &.submitted
        background-color #FCD68F

      &.approved
        background-color #CBE2A3

      .status-icon
        text-align center
        margin-left auto
        margin-right auto
        width 17px
        height 17px
        background-size cover

        &.long
          margin-top 16px
        @media (min-width: 992px)
          font-size 18px
          margin-top 10px
        @media (max-width: 991px)
          font-size 16px
          margin-top 7px

      .status-icon:before
        color #414D94

      .status-text
        color #414D94
        text-transform uppercase
        font-weight bolder
        font-family "Open Sans"
        text-align center
        @media (min-width: 992px)
          font-size 9px
          margin-top 6px
        @media (max-width: 991px)
          font-size 7px
          margin-top 3px

      .status-text--mm
        color white
        font-size 12px
        margin-top 0

      .status-text--dd
        color white
        font-size 16px
        margin-top 0
        line-height 16px

      .status-text--expired
        color red

    .task-name
      display inline-block
      vertical-align middle
      height 100%
      width 70%
      @media (max-width: 350px)
        width 54%

      .name
        color #3D3D3D
        font-family "Open Sans"
        margin-left 10px
        @media (min-width: 992px)
          font-size 16px
          line-height 69px
        @media (min-width: 768px) and (max-width: 992px)
          font-size 15px
          line-height 50px
        @media (max-width: 767px)
          font-size 14px
          line-height 50px

    .task-response
      display inline-block
      vertical-align middle
      height 100%
      width 70%
      margin-left 10px

      .response-name
        color color-black-200
        margin-top 9px
        @media (min-width: 992px)
          font-size 16px
          margin-top 4px
        @media (min-width: 768px) and (max-width: 992px)
          font-size 15px
          margin-top 7px
        @media (max-width: 767px)
          font-size 14px

      .response-amount
        font-size 8px
        color color-black-200
        @media (min-width: 992px)
          font-size 14px
        @media (min-width: 768px) and (max-width: 992px)
          margin-top 2px
          font-size 13px
        @media (max-width: 767px)
          font-size 12px

      .response-progress
        .bar
          border 2px solid
          height 12px
          padding 2px
          border-radius 3px

          .fill
            border-radius 3px
            border solid 2px
            height 4px
            width 80%
</style>
