<template>
  <b-overlay :show="loading">
    <DashboardHeader :current_tab="'response'"></DashboardHeader>
    <div class="my-response-container">
      <div class="filter-area">
        <b-dropdown class="luv-dropdown filter-dropdown" :text="filter.label">
          <b-dropdown-item v-for="(option, index) in options" :key="index" :value="option.value"
                           @click="filter = option">
            {{ option.label }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="responses-area">
        <div class="area-title">
          My Replies to others Task Videos
        </div>
        <ResponseItem v-bind:key="'response-' + index" :response="item" v-for="(item, index) in filteredResponses"></ResponseItem>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import moment from 'moment'
import DashboardHeader from '../../components/Dashboard/DashboardHeader'
import ResponseItem from '../../components/Dashboard/ResponseItem'
import { ClassroomService } from '@/core/services'

export default {
  name: 'MyResponsesPage',
  components: { DashboardHeader, ResponseItem },
  props: {
    classroomId: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {
      loading: true,
      filter: {
        value: 'NEWEST',
        label: 'NEWEST'
      },
      options: [
        {
          value: 'NEWEST',
          label: 'NEWEST'
        },
        {
          value: 'OLDEST',
          label: 'OLDEST'
        }
      ],
      responses: []
    }
  },
  methods: {
    async init () {
      this.loading = true
      this.options = [{ value: 'NEWEST', label: 'NEWEST' }, { value: 'OLDEST', label: 'OLDEST' }]
      try {
        const response = await ClassroomService.getResponses(this.classroomId)
        this.responses = response.data
        this.responses.forEach(r => {
          const option = this.options.find(o => o.value === r.submission.class_task_id)
          if (!option) {
            this.options.push({ value: r.submission.class_task_id, label: r.submission.task.task_name ? r.submission.task.task_name : `Task ${r.submission.class_task_id}` })
          }
        })
        this.sortResponses()
      } catch (e) {
        const errorMessage = e.message ? e.message : 'Error while loading responses.'
        console.log(e, errorMessage)
        await this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
      } finally {
        this.loading = false
      }
    },
    sortResponses () {
      if (['NEWEST', 'OLDEST'].includes(this.filter.value)) {
        this.responses.sort((a, b) => {
          const dateA = moment(a.created_at)
          const dateB = moment(b.created_at)
          const dateDiff = dateB.diff(dateA)
          return this.filter.value === 'NEWEST' ? dateDiff : -1 * dateDiff
        })
      }
    }
  },
  created () {
    this.init()
  },
  computed: {
    filteredResponses: function () {
      return this.responses.filter(r => {
        const taskId = this.filter.value
        if (['NEWEST', 'OLDEST'].includes(taskId)) {
          return true
        }
        return r.submission.class_task_id === taskId
      })
    }
  },
  watch: {
    classroomId: function () {
      this.init()
    },
    'filter.value': function () {
      this.sortResponses()
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"
@import "~@/core/stylus/dropdown.styl"

.my-response-container
  max-width 620px
  margin auto
  .filter-area
    margin 20px
    .filter-dropdown
      min-width 140px
      ::v-deep
        .dropdown-menu .dropdown-item
          text-overflow ellipsis
          overflow hidden

  .responses-area:last-child
    border-bottom  solid 1px color-gray-400
  .responses-area
    margin 20px
    .area-title
      color #3D3D3D
      margin-bottom 10px
      @media (min-width: 992px)
        font-size 16px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 15px
      @media (max-width: 767px)
        font-size 14px
</style>
