<template>
  <fragment>
    <main :class="classMainLayout">
      <EtagBanner />
      <header-layout :has-header-bottom-navigation="hasHeaderBottomNavigation"
                     :has-navigation="hasNavigation"
                     v-if="isAuthenticate"></header-layout>
      <slot></slot>
    </main>
    <footer-layout :is-authenticate="isAuthenticate" :is-navigation="hasNavigation"></footer-layout>
  </fragment>
</template>

<script>
import FooterLayout from './FooterLayout'
import HeaderLayout from './HeaderLayout'
import { AppStorage } from '@/core/utils'
import EtagBanner from '@/core/components/PageBase/EtagBanner.vue'

export default {
  name: 'MainLayout',
  components: {
    EtagBanner,
    HeaderLayout,
    FooterLayout
  },
  inject: ['mediaQueries'],
  props: {
    isAuthenticate: {
      type: Boolean,
      default: false
    },
    hasNavigation: {
      type: Boolean,
      default: false
    },
    hasHeaderBottomNavigation: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classMainLayout () {
      const classLayout = {
        'h-100': true,
        'layout-main': true
      }
      const user = AppStorage.getUser()
      classLayout['layout-main--with-banner'] = user.teacher_token && user.token
      classLayout[`layout-main--${this.mediaQueries.mobile ? '' : 'desktop-'}authenticate`] = this.isAuthenticate
      classLayout[`layout-main--${this.mediaQueries.mobile ? '' : 'desktop-'}authenticate-navigation`] = this.hasHeaderBottomNavigation

      return classLayout
    }
  }
}
</script>

<style lang="stylus" scoped>
main {
  &.layout-main {
    padding-bottom 80px

    &--authenticate {
      padding-bottom 50px
      padding-top 50px

      &-navigation {
        padding-top 86px
        padding-bottom 50px
      }
    }

    &--desktop-authenticate {
      padding-bottom 50px
      padding-top 80px

      &-navigation {
        padding-top 130px
        padding-bottom 50px
      }
    }
  }
}

main {
  &.layout-main--with-banner {
    &.layout-main--authenticate {
      padding-top 68px
    }

    &.layout-main--desktop-authenticate {
      padding-top 98px
    }
  }

  @media (max-width 400px) {
    &.layout-main--with-banner {
      &.layout-main--authenticate {
        padding-top 78px
      }

      &.layout-main--desktop-authenticate {
        padding-top 108px
      }
    }
  }
}
</style>
