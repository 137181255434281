class AppStorage {
  constructor (storage) {
    this.storage = storage || window.localStorage

    /** Is storage is supported or not */
    if (!this.isSupported()) {
      throw new Error('Storage is not supported by browser!')
    }
  }

  setItem (key, value) {
    this.storage.setItem(key, JSON.stringify(value))
  }

  getItem (key) {
    return JSON.parse(this.storage.getItem(key))
  }

  removeItem (key) {
    this.storage.removeItem(key)
  }

  clear (clearAll = false) {
    clearAll ? this.storage.clear() : localStorage.removeItem('user')
  }

  isSupported () {
    let supported = true

    if (!this.storage) {
      supported = false
    }

    return supported
  }

  getUser () {
    const user = this.storage.getItem('user') || '{}'
    return JSON.parse(user)
  }

  setUser (user) {
    // get local User Information
    const currentUser = this.getUser()

    const userProfile = Object.assign({}, currentUser, user)

    this.storage.setItem('user', JSON.stringify(userProfile))

    return userProfile
  }

  setUserToken (token) {
    const user = this.getUser()
    const updatedUser = Object.assign({}, user, {
      token: token
    })

    this.storage.setItem('user', JSON.stringify(updatedUser))
  }

  setUserAvatar (avatar) {
    const user = this.getUser()
    const updatedUser = Object.assign({}, user, {
      thumbnail: avatar
    })

    this.storage.setItem('user', JSON.stringify(updatedUser))
  }

  setUserTeacherToken (teacherToken, token, userType) {
    const user = this.getUser()
    const updatedUser = Object.assign({}, user, {
      token: token,
      teacher_token: teacherToken,
      user_type: {
        slug: userType
      },
      is_teacher: userType === 'teacher' || userType === 'teacher-demo'
    })

    this.storage.setItem('user', JSON.stringify(updatedUser))
  }
}

export default new AppStorage()
