<template>
  <b-modal id="message-form-modal" centered hide-footer hide-header size="xs" @hide="init">
    <div class="content">
      <div class="close-btn">
        <div @click="$bvModal.hide('message-form-modal')">×</div>
      </div>
      <div>
        <input v-model="form.message_title" maxlength="50" placeholder="Subject *" :disabled="this.dissable"/>
      </div>
      <div>
        <textarea v-model="form.message_text" maxlength="255" placeholder="Text *" :disabled="this.dissable"></textarea>
      </div>
      <div>
        <button class="send-btn" @click="postMessage()" :disabled="this.dissable">SUBMIT</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'MessageFormModal',
  data () {
    return {
      form: {
        message_title: '',
        message_text: ''
      },
      dissable: false
    }
  },
  methods: {
    init () {
      this.dissable = false
      this.form = {
        message_title: '',
        message_text: ''
      }
    },
    postMessage () {
      if (!this.dissable) {
        this.dissable = true
        this.$emit('on-post-message', { ...this.form })
      }
    }
  }
}
</script>

<style lang="stylus">
@import "~@/core/stylus/variables.styl"
#message-form-modal
  .modal-body
    padding 0

  .modal-content
    border-radius 4px
    border 2px solid color-gray-400
    width 80%
    left 10%

  .content
    text-align center

  .close-btn
    padding 0 15px
    text-align right
    font-size 35px
    height 35px
    font-weight bolder
    margin-top -5px

  .title
    font-weight bold
    font-size 16px
    letter-spacing 1px
    margin-bottom 10px

  .responding-to
    font-size 12px
    text-align left
    padding 0 30px
    color color-black-200

  input
    width 85%
    border 2px solid color-gray-400
    border-radius 4px
    margin-bottom 15px
    margin-top 10px
    font-family "Open Sans"
    resize none
    padding 5px
    @media (min-width: 992px)
      font-size 16px
    @media (min-width: 768px) and (max-width: 992px)
      font-size 15px
    @media (max-width: 767px)
      font-size 14px

  textarea
    width 85%
    min-height 150px
    border 2px solid color-gray-400
    border-radius 4px
    margin-bottom 15px
    margin-top 10px
    font-family "Open Sans"
    resize none
    padding 5px
    @media (min-width: 992px)
      font-size 16px
    @media (min-width: 768px) and (max-width: 992px)
      font-size 15px
    @media (max-width: 767px)
      font-size 14px

  .send-btn
    background-color color-blue-100
    border none
    border-radius 4px
    color white
    font-weight 600
    padding 5px 30px
    margin-bottom 25px
    letter-spacing 2px
</style>
