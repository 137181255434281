import LoginPage from '../pages/LoginPage'
import SecurityView from '../view/SecurityView'
import LoginTeacherByTokenPage from '../pages/LoginTeacherByTokenPage'

const routes = [
  {
    path: '/',
    component: SecurityView,
    children: [
      {
        path: '',
        name: 'LoginPage',
        component: LoginPage
      },
      {
        path: 'login-as-student',
        name: 'LoginTeacherByTokenPage',
        component: LoginTeacherByTokenPage
      }
    ]
  }
]

export default routes
