<template>
  <main-layout :is-authenticate="meta.isAuthenticate" :has-navigation="meta.isNavigation">
    <div class="main-container" :class="{'main-container--scroll': isClassroomDetailPage}">
      <router-view></router-view>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from '@/core/components/PageBase/MainLayout'

export default {
  name: 'PanelView',
  components: { MainLayout },
  data () {
    return {
      meta: {}
    }
  },
  watch: {
    '$route.meta': {
      handler: function (options) {
        this.meta = options
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    isClassroomDetailPage () {
      return this.$route.name === 'ClassroomDetailPage'
    }
  }
}
</script>

<style lang="stylus" scoped>
.main-container
  overflow-x hidden
  overflow-y auto
  position relative
  height 100%

.main-container--scroll
  overflow unset
</style>
