<template>
  <div class="offline-banner">
    Connection lost. Please check your internet connection
  </div>
</template>

<script>
export default {
  name: 'OfflineBanner',
  data () {
    return {}
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"

.masquerade-alert-banner {
}
.offline-banner
  text-align center
  background-color color-red-luv
  color #000
  font-size .8rem
  font-weight 800

  //&-text
  //  position absolute
  //  line-height 1rem
  //  top calc(calc(100vh / 2) - 0rem)
  //  width inherit
</style>
