<template>
  <match-media v-slot="{ mobile }">
    <header :class="classContentHeader">
      <offline-banner v-if="!isOnline"/>
      <div class="masquerade-alert-banner" v-if="user.teacher_token && user.token">You are logged in as student {{ userName }}</div>
      <b-row class="content-header-top align-items-center container-fluid">
        <div class="col-auto container-logo">
          <div class="logo">
            <img :src="require('@/assets/images/luv-logo-wide.png')" class="wide-logo" alt="LUV" :class="{'wide-logo--show': isClassroomPlayersTrust && hasNavigation}">
            <img :src="require('@/assets/images/PlayersTrust_H_LBG_RGB.png')" class="wide-logo wide-logo--players" alt="LUV" v-if="(isPlayersTrustTeacher && isClassroomPlayersTrust) || (isPlayersTrustTeacher && !hasNavigation)">
            <img :src="require('@/assets/images/luv-logo-small.png')" class="small-logo" alt="LUV" v-if="!isClassroomPlayersTrust || !hasNavigation">
            <img :src="require('@/assets/images/PlayersTrust_Icon_RGB.png')" class="small-logo small-logo--players" alt="LUV" v-if="(isPlayersTrustTeacher && isClassroomPlayersTrust) || (isPlayersTrustTeacher && !hasNavigation)">
          </div>
        </div>
        <div class="col-auto container-pills" v-if="!mobile && hasNavigation">
          <navigation-pills navigation-is-top/>
        </div>
        <div class="col-auto d-flex container-menu">
          <b-navbar-nav>
            <b-nav-item-dropdown menu-class="dropdown-menu-user">
              <template #button-content>
                <b-avatar :size="mobile ? '2.2rem':'3.1rem'" :src="userAvatar"></b-avatar>
              </template>
              <b-dropdown-item :to="{ name: 'ChangePasswordPage'}" :class="userValidationClass">PASSWORD</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'ChangeAvatarPage'}" :class="userValidationClass">SET AVATAR</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'MyClassroomsPage'}">CLASSROOMS</b-dropdown-item>
              <b-dropdown-item v-if="false" :to="{ name: 'AddClassroom'}">ADD ANOTHER CLASSROOM</b-dropdown-item>
              <b-dropdown-item href="https://levelupvillage.com/student-resource" target="_blank" v-if="false">STUDENT RESOURCES</b-dropdown-item>
              <b-dropdown-item @click="logout()">LOG OUT</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </div>
      </b-row>
      <b-row class="content-header-bottom align-items-center" v-if="hasHeaderBottomNavigation">
        <b-col>
          <navigation-task></navigation-task>
        </b-col>
      </b-row>
      <alert-message/>
    </header>
  </match-media>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries'
import NavigationPills from './NavigationPills'
import NavigationTask from '@/core/components/PageBase/NavigationTask'
import { AppStorage, SubjectTypes } from '@/core/utils'
import AlertMessage from '@/core/components/Alert/AlertMessage'
import OfflineBanner from '@/core/components/OfflineBanner.vue'
import { ClassroomService } from '@/core/services'
import { ClassroomMixins } from '@/modules/mixins'

export default {
  name: 'HeaderLayout',
  mixins: [ClassroomMixins],
  components: {
    OfflineBanner,
    NavigationTask,
    NavigationPills,
    MatchMedia,
    AlertMessage
  },
  inject: ['mediaQueries'],
  props: {
    hasNavigation: {
      type: Boolean,
      default: false
    },
    hasHeaderBottomNavigation: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      user: {
        teacher_token: null,
        token: null
      },
      classrooms: [],
      isClassroomPlayersTrust: false
    }
  },
  async mounted () {
    this.user = AppStorage.getUser()
    this.$root.$on('avatar-updated', (avatar) => {
      this.user.thumbnail = avatar
    })
    try {
      this.classrooms = await ClassroomService.list()
    } catch (e) {
      const errorMessage = e.message ? e.message : 'Error while loading classroom list.'
      await this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
    }
  },
  methods: {
    logout () {
      AppStorage.clear()
      this.$router.push({ name: 'LoginPage' })
    }
  },
  computed: {
    classContentHeader () {
      const classHeader = {
        header: true
      }

      classHeader[`header--${this.mediaQueries.mobile ? '' : 'desktop'}`] = !this.mediaQueries.mobile
      classHeader[`header--${this.mediaQueries.mobile ? '' : 'desktop-'}navigation`] = this.hasNavigation && !this.mediaQueries.mobile

      return {
        ...classHeader
      }
    },
    userAvatar () {
      return this.user ? this.user.thumbnail : null
    },
    userName () {
      return this.user ? this.user.first_name + ' ' + this.user.last_initial : ''
    },
    userValidationClass () {
      return {
        'is-teacher': this.user.is_teacher
      }
    },
    isPlayerTrustApp () {
      const currentUrl = window.location.href
      const containsPlayers = /players/i.test(currentUrl)
      return containsPlayers
    },
    teacherHasPlayersTrustCourse () {
      return !!this.classrooms?.find((classroom) => classroom?.course?.subject_id === SubjectTypes.playersTrust.id)
    },
    isPlayersTrustTeacher () {
      return this.isPlayerTrustApp || this.teacherHasPlayersTrustCourse
    }
  },
  watch: {
    hasNavigation: {
      handler: async function (newValue) {
        if (newValue) {
          const classroomId = this.$route.params.classroomId
          await this.getClassroom(classroomId)
          this.isClassroomPlayersTrust = this.classroom?.course?.subject_id === SubjectTypes.playersTrust.id
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"

.header {
  position fixed
  top 0
  left 0
  width 100%
  background white
  z-index 1000

  .masquerade-alert-banner {
    text-align center
    background-color color-yellow-luv
    color black
    font-size 12px
    font-weight 800
  }

  .container-fluid {
    padding-right: 0 !important
    margin-right: auto !important
    margin-left: auto !important
    justify-content space-between
    @media(min-width 768px){
      justify-content none
    }
  }

  .container-logo {
    width 15%
  }

  .container-pills {
    width 70%
  }

  .container-menu {
    width auto !important
  }

  @media(min-width 768px){
    .container-logo {
      width 10%
    }
  }

  @media(min-width 1050px){
    .container-logo {
      width 22%
    }

    .container-pills {
      width 50%
    }

    .container-menu {
      width 23% !important
    }
  }

  .content-header {
    &-top {
      height 50px
      border-bottom 2px solid color-gray-200

      .logo {
        display flex

        .wide-logo {
          display none
          margin-right 20px
        }
        .wide-logo--players {
          margin 0
          display none !important
        }
        .small-logo {
          width 60px
          margin-right 10px
        }
        .small-logo--players {
          width 35px
          margin 0
        }
      }

      ::v-deep {
        .navbar {
          padding-left 0
          padding-right 0

          .navbar-toggler {
            border 0

            span {
              font-size 1.4rem
              vertical-align middle
              font-weight normal

              &:before {
                color color-blue-100
              }
            }

            &.not-collapsed {
              span {
                font-weight 700
              }
            }
          }

          .nav-collapse-search {
            position fixed
            transition all 0.1s ease
            width 100%
            background-color white
            top: 50px
            left: 0
            height 83px
            z-index 100
          }
        }

        .navbar-nav {
          .nav-item {

            .b-avatar {
              box-sizing border-box
              border 2px solid white
              transition border 0.3s ease
            }

            &:hover {
              .b-avatar {
                border 2px solid color-blue-100
              }
            }

            .dropdown-menu-user {
              box-shadow: 0 3px 6px #00000029
              border: 2px solid color-gray-400
              border-radius: 7px
              padding 0
              margin 0
              min-width 7.5rem
              top -3px !important

              & > li {
                border-bottom: 2px solid color-gray-400

                &:last-child {
                  border-bottom 0
                }

                .dropdown-item {
                  text-align right
                  padding 11px
                  font-family 'Open Sans', sans-serif
                  font-weight 700
                  font-size 0.8rem

                  a {
                    color color-black-200
                  }
                }
              }
              .is-teacher {
                display none
              }
            }
          }
        }
      }
    }

    &-bottom {
      height 36px
      background-color color-purple-100
    }
  }

  &--desktop {
    .content-header {
      &-top {
        height 80px

        .logo {
          .wide-logo {
            @media(min-width 1200px){
              width 240px
              display block
            }
          }

          .wide-logo--show {
            display none !important
            @media(min-width 1600px){
              display block !important
            }
          }

          .wide-logo--players {
            @media(min-width 1200px){
              display block !important
            }
          }

          .small-logo {
            width 80px
            margin-right 10px
            @media(min-width 1200px){
              display none
            }
          }
          .small-logo--players {
            width 45px
            @media(min-width 1200px){
              display block !important
            }
          }

          .small-logo--players {
            margin 0
            @media(min-width 1200px){
              display none !important
            }
          }
        }

        ::v-deep {
          .navbar {
            .navbar-toggler {

              span {
                font-size 2.2rem
              }
            }

            .nav-collapse-search {
              top 80px
              height 83px
            }
          }
        }
        .container-menu {
          justify-content flex-end
        }
      }
    }

    &-navigation {
      .content-header {
        &-top {
          height 80px
        }

        &-bottom {
          height 50px
        }
      }
    }
  }
}
</style>
