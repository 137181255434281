<template>
  <div class="classroom-grid">
    <classroom-grid-item @onViewDetail="onViewDetail" :classroom="classroom" :key="'classroom-'+index"
                         v-for="(classroom, index) in classrooms" />
  </div>
</template>

<script>
import ClassroomGridItem from './ClassroomGridItem'

export default {
  name: 'ClassroomGrid',
  components: { ClassroomGridItem },
  props: {
    classrooms: {
      type: Array,
      required: true
    }
  },
  methods: {
    onViewDetail (classroom) {
      this.$router.push({
        name: 'MyTasksPage',
        params: { classroomId: classroom.id }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"
.classroom-grid
  display grid
  grid-template-columns 1fr
  justify-items center
  width 100%
</style>
