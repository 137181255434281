<template>
  <div class="form-group">
    <label v-if="label" :for="`checkbox-group-${_uid}`">{{ label }}</label>

    <b-form-checkbox-group
      v-model="inputValue"
      :id="`checkbox-group-${_uid}`"
      :options="options"
      :name="name"
      :class="customClassInput"
      style="column-count: 3;"
      :state="state"
      @input="onInput"/>

    <!-- This will only be shown if the preceding input has an invalid state -->
    <b-form-invalid-feedback :id="`checkbox-group-live-feedback-${_uid}`" v-if="showErrors">
      <ul class="error-content">
        <li class="error-text" v-for="(error, index) in errors" :key="`checkbox-group-error-${_uid}-${index}`">
          {{ error }}
        </li>
      </ul>
    </b-form-invalid-feedback>
  </div>
</template>

<script>
import { InputMixins } from './Mixins/input'

export default {
  name: 'CheckboxGroup',
  mixins: [InputMixins],
  props: {
    value: {
      type: Array,
      default: function () {
        return []
      }
    },
    name: {
      type: String
    },
    options: {
      type: Array,
      default: function () {
        return []
      }
    },
    customClass: {
      type: String
    }
  },
  methods: {
    onInput (value) {
      this.$emit('input', value)
    }
  },
  computed: {
    customClassInput () {
      const className = {
        'is-invalid': this.state === false
      }

      className[this.customClass] = !!this.customClass

      return {
        ...className
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"

::v-deep
  .custom-checkbox
    .custom-control-label
      font font-opensans-regular
      font-size 14px
      padding-top 2px
      cursor pointer

      &::before
        border-radius 0 !important

    .custom-control-label
      &::before
        color color-white-100 !important
        border-color color-blue-100  !important
        background-color color-white-100 !important

      &::after
        color color-blue-100

    .custom-control-input
      &:focus
        &~ .custom-control-label::before
          box-shadow 0 0 0 0.2rem rgba(color-blue-100, 0.25)

        &:not(:checked) ~ .custom-control-label::before
          border-color color-blue-100

      &:checked ~ .custom-control-label::after
        font-family 'icomoon' !important
        background-image none
        content "\e92a"
        font-size 1.15em

</style>
