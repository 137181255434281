import {
  FETCH_CLASSROOM_START,
  FETCH_CLASSROOM_DATA
} from '@/core/storage/classroom/mutation-types'

export const
  mutations = {
    [FETCH_CLASSROOM_START] (state) {
      state.loadingClassroom = true
    },
    [FETCH_CLASSROOM_DATA] (state, classroom) {
      state.classroom = classroom
      state.loadingClassroom = false
    },
    initClassroom (state) {
      state.classroom = Object.assign({}, {
        id: null,
        name: null,
        subject_id: null,
        age_range_id: null,
        number_students_range_id: null,
        lang_native_id: null,
        lang_instruction_id: null,
        start_date: null,
        end_date: null,
        level_id: null
      })
    }
  }
