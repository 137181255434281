import Vue from 'vue'
import moment from 'moment'

Vue.config.productionTip = false

Vue.filter('dateMonthDayYear', function (value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY')
  }
})
Vue.filter('dateMonthYear', function (value) {
  if (value) {
    return moment(String(value)).format('MM/YYYY')
  }
})
Vue.filter('dateElapseTime', function (value) {
  if (value) {
    return moment(String(value)).fromNow()
  }
})
