import { http } from '@/core/services/http'

const resource = '/api/student'

class StudentPaymentError extends Error {
  constructor (message) {
    super(message)
    this.name = 'StudentPaymentError'
  }
}

export default {
  save (request) {
    return http.post(`${resource}/payments`, request).then((response) => {
      if (!response.success) {
        throw new StudentPaymentError(response.message)
      }
      return response.data
    })
  }
}
