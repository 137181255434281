<template>
  <div class="form-group">
    <label v-if="label" :for="`${type}-${_uid}`">{{ label }}</label>

    <b-form-input
      v-model="inputValue"
      :id="`input-${type}-${_uid}`"
      :type="type"
      :class="customClassInput"
      :placeholder="placeholder"
      :disabled="disabled"
      :state="state"
      :aria-describedby="`input-live-feedback-${_uid}`"
      :maxlength="maxlength"
      :min="min"
      @change="$emit('change', $event)"
      @keyup="$emit('keyup', $event)"
      @keypress="$emit('keypress', $event)"
      @input="onInput"
      @blur="$emit('blur')"
    />

    <b-form-invalid-feedback
      :id="`input-live-feedback-${_uid}`"
      v-if="showErrors"
    >
      <ul class="error-content">
        <li
          class="error-text"
          v-for="(error, index) in errors"
          :key="`input-error-${_uid}-${index}`"
        >
          {{ error }}
        </li>
      </ul>
    </b-form-invalid-feedback>
  </div>
</template>

<script>
import { InputMixins } from './Mixins/input'

export default {
  name: 'InputGroup',
  data: function () {
    return {}
  },
  mixins: [InputMixins],
  props: {
    maxlength: {
      type: [Number, String],
      default: 150
    },
    min: {
      type: [Number, String],
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    customClass: {
      type: String
    }
  },
  methods: {
    onInput (value) {
      this.$emit('input', value)
    }
  },
  computed: {
    customClassInput () {
      const className = {
        '': true
      }

      className[this.customClass] = !!this.customClass

      return {
        ...className
      }
    }
  }
}
</script>

<style lang="stylus" scoped></style>
