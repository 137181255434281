<template>
  <b-modal id="forgot-password-modal" centered hide-footer hide-header size="xs">
    <div class="close-btn">
      <div @click="$bvModal.hide('forgot-password-modal')">×</div>
    </div>
    <div class="modal-title">FORGOT PASSWORD?</div>
    <div class="modal-text">If you forgot your password, please contact your teacher to reset your password.</div>
    <div class="modal-btns">
      <div class="btn-ok" @click="$bvModal.hide('forgot-password-modal')">OK</div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ChangePasswordModal'
}
</script>

<style lang="stylus">
@import "~@/core/stylus/variables.styl"

#forgot-password-modal
  .modal-content
    width 280px
    height 280px
    margin auto
  .close-btn
    text-align right
    font-size 35px
    height 0
    font-weight bolder
    margin-top -20px
  .modal-title
    text-align center
    font-size 16px
    font-weight bold
    margin-top 85px
  .modal-text
    text-align center
    font-size 12px
    width 75%
    margin auto
    height 85px
    margin-top 15px
  .modal-btns
    text-align center
    .btn-ok
      text-align center
      display inline-block
      vertical-align top
      width 82px
      height 31px
      background-color color-blue-100
      border none
      border-radius 4px
      color white
      font-weight 600
      margin-bottom 25px
      letter-spacing 2px
      line-height 31px
</style>
