<template>
  <b-overlay :show="loading">
    <DashboardHeader :current_tab="'tasks'"></DashboardHeader>
    <div class="task-title">My Tasks</div>
    <div class="dashboard-graph">
      <div class="cdc-container">
        <div class="cdc" style="width: 150px; height: 150px; padding-bottom: 150px;">
          <line-chart
              :chart-data="chartData"
              :options="chartOptions"
              :styles="{width: '150px', height: '150px'}"/>
        </div>
        <div class="cdc-legend" style="flex-direction: column; margin: 0px 0px 0px 1em;">
          <span title="87.5 (87.5%)" class="cdc-legend-item">
            <span class="cdc-legend-item-color" style="background-color: rgb(197, 199, 254);"></span>
            <span>To-Do</span>
          </span>
          <span title="0 (0%)" class="cdc-legend-item">
          <span class="cdc-legend-item-color" style="background-color: rgb(252, 214, 143);"></span>
            <span>Submitted</span>
          </span>
          <span title="12.5 (12.5%)" class="cdc-legend-item">
            <span class="cdc-legend-item-color" style="background-color: rgb(203, 226, 163);"></span>
            <span>Approved</span>
          </span>
          <span title="0 (0%)" class="cdc-legend-item">
          <span class="cdc-legend-item-color" style="background-color: rgb(248, 148, 132);"></span>
            <span>Blocked</span>
          </span>
        </div>
      </div>
    </div>
    <div class="dashboard-task">
      <b-row class="dashboard-row">
        <TaskItem v-bind:key="'index' + index"
                  :task="item"
                  :classroomId="classroomId"
                  :classroom-exchange-expired="classroomExchangeExpired"
                  :is-main-classroom="isMainClassroom"
                  v-for="(item, index) in tasks"/>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import DashboardHeader from '../../components/Dashboard/DashboardHeader'
import TaskItem from '../../components/Dashboard/TaskItem'
import { ClassroomExchangeMixins } from '@/modules/mixins'
import { ClassroomService } from '@/core/services'
import { TaskUtils } from '../../utils'
import LineChart from '@/modules/panel/components/DoughnutChart'

export default {
  name: 'DashboardPage',
  components: {
    DashboardHeader,
    TaskItem,
    LineChart
  },
  mixins: [ClassroomExchangeMixins],
  props: {
    classroomId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      tasks: [],
      nonReflectionTasks: [],
      interval: null,
      datasetsConfig: {
        label: 'Data One',
        backgroundColor: [
          '#C5C7FE',
          '#FCD68F',
          '#CBE2A3',
          '#F89484'
        ],
        borderWidth: 0,
        data: [0, 0, 0, 0]
      },
      chartData: {
        labels: [
          'To-Do'
        ],
        datasets: [
          {
            backgroundColor: [
              'gey'
            ],
            borderWidth: 0,
            data: [1]
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          center: {
            percentage: 'No',
            label: 'Tasks'
          }
        },
        legend: {
          display: false
        },
        cutoutPercentage: 85,
        tooltips: { enabled: false },
        hover: { mode: null }
      }
    }
  },
  methods: {
    async init (showLoading = true) {
      if (showLoading) {
        this.loading = true
      }
      try {
        const response = await ClassroomService.tasks(this.classroomId)
        this.chartData.datasets[0].data.forEach(s => {
          s = 0
        })
        if (response.data && Array.isArray(response.data)) {
          this.tasks = response.data
          this.nonReflectionTasks = response.data.filter((task) => (task.task_category !== 'activity' && task.task_category !== 'reflection'))
        }
        if (this.nonReflectionTasks.length > 0) {
          let todoCount = 0
          let submittedCount = 0
          let approvedCount = 0
          let blockedCount = 0
          this.nonReflectionTasks.forEach(task => {
            const taskCreateStatus = TaskUtils.getCreateTaskStatus(task)
            const taskRespondStatus = TaskUtils.getRespondActionStatus(task)

            function addStatusCount (status) {
              switch (status) {
                case TaskUtils.STATUS_TODO:
                  todoCount++
                  break
                case TaskUtils.STATUS_SUBMITTED:
                  submittedCount++
                  break
                case TaskUtils.STATUS_APPROVED:
                  approvedCount++
                  break
                case TaskUtils.STATUS_BLOCKED:
                  blockedCount++
                  break
              }
            }

            addStatusCount(taskCreateStatus)
            addStatusCount(taskRespondStatus)
          })
          const totalCount = todoCount + blockedCount + submittedCount + approvedCount
          this.datasetsConfig.data[0] = todoCount * 100 / totalCount
          this.datasetsConfig.data[1] = submittedCount * 100 / totalCount
          this.datasetsConfig.data[2] = approvedCount * 100 / totalCount
          this.datasetsConfig.data[3] = blockedCount * 100 / totalCount

          this.chartData.datasets[0] = this.datasetsConfig

          this.chartData = {
            labels: [
              'To-Do',
              'Submitted',
              'Approved',
              'Blocked'
            ],
            datasets: [
              this.datasetsConfig
            ]
          }

          // this.chartOptions.elements.center.percentage = this.nonReflectionTasks.length === 0 ? 'No' : `${Math.ceil((this.chartData.datasets[0].data[1] + this.chartData.datasets[0].data[2]) * 10) / 10} %`
          this.chartOptions.elements.center.percentage = this.nonReflectionTasks.length === 0 ? 'No' : `${Math.ceil((this.chartData.datasets[0].data[2]) * 10) / 10} %`
          this.chartOptions.elements.center.label = this.nonReflectionTasks.length === 0 ? 'Tasks' : 'Completed'
        }
      } catch (e) {
        const errorMessage = e.message ? e.message : 'Error while loading tasks.'
        console.log(e, errorMessage)
        showLoading && await this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
      } finally {
        this.loading = false
      }
    }
  },
  created () {
    this.init().then(() => {
      this.interval = setInterval(() => {
        this.init(false)
      }, 15000)
    })
  },
  watch: {
    classroomId: function () {
      this.init()
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"

::v-deep
  .cdc-legend-item-color
    height 2.25em
    width 2.25em
    border 1px solid #3D3D3D
    border-radius 4px

.task-title
  font-size 18px
  font-weight bold
  text-transform uppercase
  text-align center
  margin-top 20px

.dashboard-graph
  margin 20px
  font-weight bold
  font-size 12px
  @media (min-width: 992px)
    font-size 14px

  .donut-total, .donut-text
    font-size 14px
    @media (min-width: 992px)
      font-size 16px

.dashboard-task
  border-top 2px solid color-purple-100
  background-color #F5F6FF
  margin auto
  max-width 1320px
  padding 20px 0 50px

  .dashboard-row
    margin auto
    flex-direction column
    align-items center
    @media (min-width: 992px)
      max-width 936px
    @media (max-width: 991px)
      max-width 375px
</style>
