<template>
  <div @click="onClassroomDetail" class="classroom-item">
    <div class="item-name">{{ classroom.name }}</div>
    <div class="item-subject">{{ classroom.subject.name }}</div>
    <div class="item-tags">Teacher name: {{ classroom.teacher.full_name }}</div>
  </div>
</template>

<script>
export default {
  name: 'ClassroomGridItem',
  props: {
    classroom: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    onClassroomDetail () {
      const classroom = this.classroom
      this.$emit('onViewDetail', { ...classroom })
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"

.classroom-item
  display flex
  flex-direction column
  align-items flex-start
  width 300px
  padding 18px 20px
  background-color color-gray-500
  margin-bottom 10px
  box-shadow 0 2px 4px #00000029
  border: 1px solid color-purple-500
  border-radius 4px
  cursor pointer

  .item-name, .item-tags
    white-space pre-wrap
    word-wrap break-word
    text-align left
    width 100%

  .item-name
    font-size 14px
    font-weight bold
    color color-black-200
    text-transform uppercase

  .item-subject
    font-weight bold
    font-size 12px
    color color-black-200

  .item-tags
    color color-black-200
    font-size 12px
    margin-top 4px

@media (min-width: 768px)
  .classroom-grid
    .classroom-item
      width 326px

      .item-name
        font-size 16px

      .item-subject
        font-size 14px

      .item-tags
        font-size 13px
        margin-top 8px

@media (min-width: 992px)
  .classroom-grid
    .classroom-item
      width 532px

      .item-name
        font-size 18px

      .item-subject
        font-size 16px

      .item-tags
        font-size 14px
        margin-top 10px
</style>
