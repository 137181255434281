<template>
  <b-overlay :show="!isPlaying"
             :opacity="0.25"
             rounded="sm"
             class="video-overlay"
             @click="onClickOverlay"
             :style="isFullScreenStyles">
    <div class="video-player" ref="video-player-wrapper" @click="pauseVid">
      <canvas ref="canvas-video-element" :width="canvas_width"
              :height="canvas_height" class="video-canvas"></canvas>
    </div>

    <div
      v-if="showMenu"
      class="video-player-menu"
      @click.self="closePlayerMenu"
    >
      <button
        @click="closePlayerMenu"
        class="video-player-menu-close"
      >
        <span class="icon-delete"></span>
      </button>

      <b-dropdown
        :text="`Playback rate: ${playbackRate === 1 ? 'Normal':playbackRate}`"
        ref="dropdown-playback-rates"
        boundary="viewport"
        :auto-flipping="false"
        class="luv-dropdown video-player-menu-playback-rates"
        no-flip
      >
        <b-dropdown-item @click="playbackRate = 1" :active="playbackRate === 1">
          Normal
        </b-dropdown-item>
        <b-dropdown-item
          @click="playbackRate = playbackRateValue"
          v-for="playbackRateValue in playbackRates"
          :key="`playback-rate-${playbackRateValue}`"
          :active="playbackRate === playbackRateValue"
        >
          {{ playbackRateValue }}
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <div v-if="videoLoaded"
         class="video-player-controls">
      <button @click="openPlayerMenu"
              v-if="showPlayerMenu"
              class="player-control">
        <i class="fa fa-cog" aria-hidden="true"></i>
      </button>
      <b-form-input v-if="videoLoaded"
                    v-model="currentTime"
                    @input="seek"
                    @change="seek"
                    type="range"
                    step="0.01"
                    min="0"
                    :max="player.duration"/>
      <button-fullscreen-video v-if="fullscreenButton"
                               @on-fullscreen="onFullscreen"
                               @close-fullscreen="closeFullscreen"/>
    </div>
    <div class="hidden-video-container" v-if="hideVideo">
      <p>This video has been {{statusText}} by your teacher</p>
    </div>
    <template #overlay>
      <div class="text-center">
        <span v-if="videoLoaded && !isVideoProcessError" class="icon-play-video"></span>
        <b-button v-else-if="isVideoProcessError" pill variant="primary"
                  class="video-reload-button video-reload-button--faild">
          This video failed to convert to LUV format. Please correct and resubmit. See <a class="student-resources" target="_blank" href="https://levelupvillage.com/student-resources/">Student Resources</a> for video tips and recommendations
        </b-button>
        <b-button v-else-if="showTemporalIosSolutionMessage" pill variant="primary"
                  class="video-reload-button video-reload-button--large">
          This video cannot preview in this browser
          <span v-if="!isResponseVideo"> You may still submit this video, then go to **My Posts** to review it.</span>
          <span v-else> You may still submit this video, then go to **My Responses** to review it.</span>
        </b-button>
        <b-spinner v-else-if="!videoLoaded && !videoLoadError" label="Spinning"></b-spinner>
        <b-button v-else pill variant="primary" class="video-reload-button">
          <template
            v-if="!video_processed"
          >
            VIDEO IS NOT READY YET TRY AGAIN LATER
          </template>
          <template
            v-else
          >
            <b-icon icon="arrow-counterclockwise"></b-icon>
            RELOAD VIDEO
          </template>
        </b-button>

      </div>
    </template>
  </b-overlay>
</template>

<script>
import { UploadService, videosService } from '@/core/services'
import ButtonFullscreenVideo from './FullscreenVideo'

export default {
  name: 'VideoCustom',
  components: {
    ButtonFullscreenVideo
  },
  props: {
    src: {
      type: String,
      required: true
    },
    poster: {
      type: String,
      required: false
    },
    showTemporalIosSolutionMessage: {
      type: Boolean,
      required: false,
      default: false
    },
    isResponseVideo: {
      type: Boolean,
      default: false
    },
    objectURL: {
      type: Boolean,
      default: false
    },
    video_processed: {
      default: true
    },
    statusText: {
      default: 'blocked'
    },
    hideVideo: {
      type: Boolean,
      default: false
    },
    trackVideoViews: {
      type: Boolean,
      default: false
    },
    submissionId: {
      type: [String, Number]
    },
    classroomId: {
      type: [String, Number]
    },
    showPlayerMenu: {
      type: Boolean,
      default: true
    },
    fullscreenButton: {
      type: Boolean,
      default: true
    }
  },
  data () {
    const videoPlayer = document.createElement('video')
    videoPlayer.playsinline = true

    const videoThumbnail = document.createElement('img')

    return {
      player: videoPlayer,
      canvas_width: 0,
      canvas_height: 0,
      canvas: null,
      ctx: null,
      canvasUpdater: null,
      isPlaying: false,
      videoLoaded: false,
      videoLoadError: false,
      attempts: 1,
      currentTime: 0,
      currentTimeUpdater: null,
      signed_url: null,
      signed_thumbnail_url: null,
      video_thumbnail: videoThumbnail,
      showMenu: false,
      playbackRates: [
        0.75,
        0.5
      ],
      playbackRate: 1,
      trackVideoPlayed: false,
      isFullscreen: false,
      isFullScreenStyles: {}
    }
  },
  async mounted () {
    if (!this.objectURL) {
      await this.getS3Video(this.src)
      this.player.src = this.signed_url + '#t=0.2'
    } else {
      this.player.src = this.src + '#t=0.2'
    }

    this.canvas = this.$refs['canvas-video-element']
    this.ctx = this.canvas.getContext('2d')

    this.player.onplay = () => {
      this.$emit('on-video-play')
      this.isPlaying = true
      this.canvasUpdater = setInterval(this.updateCanvas, 20)
      this.currentTimeUpdater = setInterval(this.updateCurrentTime, 30)
    }

    this.player.onpause = () => {
      this.$emit('on-video-pause')
      this.isPlaying = false
      clearInterval(this.canvasUpdater)
      clearInterval(this.currentTimeUpdater)
    }

    this.player.onended = () => {
      this.$emit('on-video-ended')
      this.isPlaying = false
      clearInterval(this.canvasUpdater)
      clearInterval(this.currentTimeUpdater)
      this.currentTime = this.player.currentTime
      this.trackVideoPlayed = false
    }

    this.player.onloadeddata = () => {
      this.$emit('on-video-loaded', this.player)
      this.videoLoaded = true
      this.drawVideo()
    }

    this.player.onerror = (e) => {
      e.message = e.message ? e.message : 'Error while load unplayable video.'
      this.$emit('on-video-error', e)

      this.videoLoaded = false
      this.videoLoadError = true
    }

    if (this.trackVideoViews) {
      if (!this.submissionId) throw new Error('submissionId is required to track viewed videos')
      if (!this.classroomId) throw new Error('classroomId is required to track viewed videos')
    }
  },
  computed: {
    isVideoProcessError () {
      return this.video_processed === 2
    }
  },
  methods: {
    drawVideo () {
      // eslint-disable-next-line camelcase
      const video_wrapper = this.$refs['video-player-wrapper']

      const maxWidth = video_wrapper.clientWidth // Max video_wrapper.clientWidth for the image
      const maxHeight = video_wrapper.clientHeight // Max video_wrapper.clientHeight for the image
      let ratio = 0 // Used for aspect ratio

      let videoWidth = this.player.videoWidth // Current image width
      let videoHeight = this.player.videoHeight // Current image height

      // Check if the current width is larger than the max
      if (videoWidth > maxWidth) {
        ratio = maxWidth / videoWidth // get ratio for scaling image

        videoHeight = videoHeight * ratio // Reset this.canvas_height to match scaled image
        videoWidth = maxWidth // Reset this.canvas_width to match scaled image
      }

      // Check if current videoHeight is larger than maxHeight
      if (videoHeight > maxHeight) {
        ratio = maxHeight / videoHeight // get ratio for scaling image

        videoHeight = maxHeight // Reset this.canvas_height to match scaled image
        videoWidth = videoWidth * ratio // Reset this.canvas_width to match scaled image
      }

      this.canvas_width = videoWidth
      this.canvas_height = videoHeight

      if (!this.objectURL) {
        this.video_thumbnail.src = this.signed_thumbnail_url + '#t=0.2'
      } else {
        this.video_thumbnail.src = this.poster + '#t=0.2'
      }

      this.video_thumbnail.onload = () => {
        this.ctx.drawImage(this.video_thumbnail, 0, 0, this.canvas.width, this.canvas.height)
      }
    },
    async getS3Video (src) {
      try {
        this.videoLoaded = false
        this.videoLoadError = false

        const url = src.split('/')
        const key = url[url.length - 1]

        this.signed_url = await UploadService.getVideo(key)
        this.signed_thumbnail_url = await UploadService.getVideoThumbnail(key)
      } catch (error) {
        console.error('Signed Url video error: ' + error.message)
        this.signed_url = null
        this.signed_thumbnail_url = null

        this.videoLoaded = false
        this.videoLoadError = true
      }
    },
    playVid () {
      this.player.play()
      if (this.trackVideoViews) {
        !this.trackVideoPlayed && this.saveVideoView()
        this.trackVideoPlayed = true
      }
    },
    pauseVid () {
      this.player.pause()
    },
    async loadVid () {
      this.videoLoadError = false
      this.videoLoaded = false
      if (this.objectURL) {
        await this.getS3Video(this.src)
        this.player.src = this.signed_url + '#t=0.2'
      }
      this.player.load()
    },
    onClickOverlay () {
      if (this.showTemporalIosSolutionMessage || this.isVideoProcessError) {
        return
      }
      if (this.showMenu) {
        this.closePlayerMenu()
        return
      }
      if (this.videoLoaded) {
        this.playVid()
      } else if (this.videoLoadError) {
        this.loadVid()
      }
    },
    seek (value) {
      this.player.currentTime = value
    },
    updateCurrentTime () {
      this.currentTime = this.player.currentTime
    },
    updateCanvas () {
      this.ctx.drawImage(this.player, 0, 0, this.canvas.width, this.canvas.height)
    },
    closePlayerMenu () {
      this.showMenu = false
    },
    openPlayerMenu () {
      this.showMenu = !this.showMenu
      this.$nextTick(() => this.showMenu && this.$refs['dropdown-playback-rates'].show())
    },
    saveVideoView () {
      videosService.saveVideoViews(this.classroomId, this.submissionId).catch(e => {
        const errorMessage = e.message ? e.message : 'Tracking video error .'
        this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
      })
    },
    onFullscreen () {
      this.isFullscreen = !this.isFullscreen
      if (this.isFullscreen) {
        this.isFullScreenStyles = {
          position: 'fixed !important',
          height: '100vh',
          width: '100vw',
          top: 0,
          left: 0,
          zIndex: 9999
        }
        this.$nextTick(() => {
          this.drawVideo()
        })
      }
    },
    closeFullscreen () {
      this.isFullScreenStyles = {}
      this.isFullscreen = false
      this.$nextTick(() => {
        this.drawVideo()
      })
    }
  },
  destroyed () {
    if (this.isPlaying) {
      this.pauseVid()
    }
    this.player.remove()
    this.player.onloadeddata = () => {}
    this.player = null
  },
  watch: {
    src (newValue, onlValue) {
      if (this.player) {
        if (newValue !== onlValue) {
          if (!this.objectURL) {
            this.player.pause()
            this.getS3Video(newValue).then(() => {
              if (this.player) {
                this.player.src = this.signed_url + '#t=0.2'
                this.player.currentTime = 0
                this.loadVid()
              }
            })
          } else {
            this.player.src = this.src + '#t=0.2'
          }
        }
      }
    },
    playbackRate (newPlaybackRate) {
      this.player.playbackRate = newPlaybackRate
      this.closePlayerMenu()
    }
  }
}

</script>
<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"
@import "~@/core/stylus/mixins.styl"
@import "~@/core/stylus/dropdown.styl"

.video-overlay
  border-radius 4px
  overflow hidden
  margin 0 auto
  width 334px
  height 395px
  background-color color-black-100

  .video-player-menu
    background-color rgba(28, 28, 28, .8)
    width 250px
    height calc(165px - 22px)
    position absolute
    bottom 0
    right 0
    z-index 12
    padding 8px
    margin-bottom 40px
    overflow hidden

    &-close
      color #000
      font-weight 700
      background-color transparent
      border 0
      padding 0.5em
      position absolute
      top 0
      right 0

      .icon-delete
        color color-white-100

    &-playback-rates
      min-width 205px

      ::v-deep
        .dropdown-menu
          max-width 205px

        .dropdown-menu .dropdown-item
          text-overflow ellipsis
          overflow hidden

        .dropdown-item.active, .dropdown-item:active
          background-color #e9ecef
          font-weight 700

  .video-player
    width 100%
    height 100%

    .video
      position absolute
      top 0
      left 0

    .video-canvas
      position absolute;
      top 50%
      left 50%
      transform translate(-50%, -50%)

    &-controls
      position absolute
      display flex
      width 95%
      left calc(50% - 48%);
      bottom 10px
      z-index 11

      .player-control
        border none
        background-color transparent
        outline 0
        opacity 0.9
        display inline-block
        color rgb(222, 226, 230)
        cursor pointer

        .fa-cog, .video-icon
          font-size 22px

      @media(max-width 767px)
        .fa-cog, .video-icon
          font-size 18px !important

  .icon-play-video
    font-size 4em

    &::before
      color color-gray-100

  .spinner-border
    width 4em
    height 4em
    border-color color-gray-100
    border-right-color transparent

  .video-reload-button
    padding 10px 0
    font-size 1em
    width 180px

  .video-reload-button--large, .video-reload-button--faild
    padding 20px 16px

    span
      margin-top 10px
      display block
      font-size 10px

  .video-reload-button--faild
    background-color color-40
    border-color color-40
    cursor auto !important
    width auto

    .student-resources
      text-decoration underline
      color color-link !important

    &:not(:disabled):not(.disabled):active
      background-color color-40
      border-color color-40
video
  width 100%
  height 100%

.hidden-video-container
  width 100%
  height 100%
  z-index 15
  background black
  position absolute
  top 0
  display flex
  justify-content center
  align-items center
  text-align center

  p
    color white
    padding 40px

::v-deep
  .custom-range
    display inline-block
    flex 0 1 100%
    height 1.7rem
</style>
