const avatars = [
  {
    id: 'group1-asset-1-svg',
    src: '/assets/avatars/group1/Asset 1.svg'
  },
  {
    id: 'group1-asset-10-svg',
    src: '/assets/avatars/group1/Asset 10.svg'
  },
  {
    id: 'group1-asset-11-svg',
    src: '/assets/avatars/group1/Asset 11.svg'
  },
  {
    id: 'group1-asset-12-svg',
    src: '/assets/avatars/group1/Asset 12.svg'
  },
  {
    id: 'group1-asset-13-svg',
    src: '/assets/avatars/group1/Asset 13.svg'
  },
  {
    id: 'group1-asset-14-svg',
    src: '/assets/avatars/group1/Asset 14.svg'
  },
  {
    id: 'group1-asset-15-svg',
    src: '/assets/avatars/group1/Asset 15.svg'
  },
  {
    id: 'group1-asset-16-svg',
    src: '/assets/avatars/group1/Asset 16.svg'
  },
  {
    id: 'group1-asset-17-svg',
    src: '/assets/avatars/group1/Asset 17.svg'
  },
  {
    id: 'group1-asset-18-svg',
    src: '/assets/avatars/group1/Asset 18.svg'
  },
  {
    id: 'group1-asset-19-svg',
    src: '/assets/avatars/group1/Asset 19.svg'
  },
  {
    id: 'group1-asset-2-svg',
    src: '/assets/avatars/group1/Asset 2.svg'
  },
  {
    id: 'group1-asset-20-svg',
    src: '/assets/avatars/group1/Asset 20.svg'
  },
  {
    id: 'group1-asset-21-svg',
    src: '/assets/avatars/group1/Asset 21.svg'
  },
  {
    id: 'group1-asset-22-svg',
    src: '/assets/avatars/group1/Asset 22.svg'
  },
  {
    id: 'group1-asset-23-svg',
    src: '/assets/avatars/group1/Asset 23.svg'
  },
  {
    id: 'group1-asset-24-svg',
    src: '/assets/avatars/group1/Asset 24.svg'
  },
  {
    id: 'group1-asset-25-svg',
    src: '/assets/avatars/group1/Asset 25.svg'
  },
  {
    id: 'group1-asset-26-svg',
    src: '/assets/avatars/group1/Asset 26.svg'
  },
  {
    id: 'group1-asset-27-svg',
    src: '/assets/avatars/group1/Asset 27.svg'
  },
  {
    id: 'group1-asset-28-svg',
    src: '/assets/avatars/group1/Asset 28.svg'
  },
  {
    id: 'group1-asset-29-svg',
    src: '/assets/avatars/group1/Asset 29.svg'
  },
  {
    id: 'group1-asset-3-svg',
    src: '/assets/avatars/group1/Asset 3.svg'
  },
  {
    id: 'group1-asset-30-svg',
    src: '/assets/avatars/group1/Asset 30.svg'
  },
  {
    id: 'group1-asset-31-svg',
    src: '/assets/avatars/group1/Asset 31.svg'
  },
  {
    id: 'group1-asset-32-svg',
    src: '/assets/avatars/group1/Asset 32.svg'
  },
  {
    id: 'group1-asset-33-svg',
    src: '/assets/avatars/group1/Asset 33.svg'
  },
  {
    id: 'group1-asset-34-svg',
    src: '/assets/avatars/group1/Asset 34.svg'
  },
  {
    id: 'group1-asset-35-svg',
    src: '/assets/avatars/group1/Asset 35.svg'
  },
  {
    id: 'group1-asset-36-svg',
    src: '/assets/avatars/group1/Asset 36.svg'
  },
  {
    id: 'group1-asset-37-svg',
    src: '/assets/avatars/group1/Asset 37.svg'
  },
  {
    id: 'group1-asset-38-svg',
    src: '/assets/avatars/group1/Asset 38.svg'
  },
  {
    id: 'group1-asset-39-svg',
    src: '/assets/avatars/group1/Asset 39.svg'
  },
  {
    id: 'group1-asset-4-svg',
    src: '/assets/avatars/group1/Asset 4.svg'
  },
  {
    id: 'group1-asset-40-svg',
    src: '/assets/avatars/group1/Asset 40.svg'
  },
  {
    id: 'group1-asset-41-svg',
    src: '/assets/avatars/group1/Asset 41.svg'
  },
  {
    id: 'group1-asset-42-svg',
    src: '/assets/avatars/group1/Asset 42.svg'
  },
  {
    id: 'group1-asset-43-svg',
    src: '/assets/avatars/group1/Asset 43.svg'
  },
  {
    id: 'group1-asset-44-svg',
    src: '/assets/avatars/group1/Asset 44.svg'
  },
  {
    id: 'group1-asset-45-svg',
    src: '/assets/avatars/group1/Asset 45.svg'
  },
  {
    id: 'group1-asset-46-svg',
    src: '/assets/avatars/group1/Asset 46.svg'
  },
  {
    id: 'group1-asset-47-svg',
    src: '/assets/avatars/group1/Asset 47.svg'
  },
  {
    id: 'group1-asset-48-svg',
    src: '/assets/avatars/group1/Asset 48.svg'
  },
  {
    id: 'group1-asset-49-svg',
    src: '/assets/avatars/group1/Asset 49.svg'
  },
  {
    id: 'group1-asset-5-svg',
    src: '/assets/avatars/group1/Asset 5.svg'
  },
  {
    id: 'group1-asset-50-svg',
    src: '/assets/avatars/group1/Asset 50.svg'
  },
  {
    id: 'group1-asset-51-svg',
    src: '/assets/avatars/group1/Asset 51.svg'
  },
  {
    id: 'group1-asset-52-svg',
    src: '/assets/avatars/group1/Asset 52.svg'
  },
  {
    id: 'group1-asset-53-svg',
    src: '/assets/avatars/group1/Asset 53.svg'
  },
  {
    id: 'group1-asset-54-svg',
    src: '/assets/avatars/group1/Asset 54.svg'
  },
  {
    id: 'group1-asset-55-svg',
    src: '/assets/avatars/group1/Asset 55.svg'
  },
  {
    id: 'group1-asset-56-svg',
    src: '/assets/avatars/group1/Asset 56.svg'
  },
  {
    id: 'group1-asset-57-svg',
    src: '/assets/avatars/group1/Asset 57.svg'
  },
  {
    id: 'group1-asset-58-svg',
    src: '/assets/avatars/group1/Asset 58.svg'
  },
  {
    id: 'group1-asset-59-svg',
    src: '/assets/avatars/group1/Asset 59.svg'
  },
  {
    id: 'group1-asset-6-svg',
    src: '/assets/avatars/group1/Asset 6.svg'
  },
  {
    id: 'group1-asset-60-svg',
    src: '/assets/avatars/group1/Asset 60.svg'
  },
  {
    id: 'group1-asset-61-svg',
    src: '/assets/avatars/group1/Asset 61.svg'
  },
  {
    id: 'group1-asset-62-svg',
    src: '/assets/avatars/group1/Asset 62.svg'
  },
  {
    id: 'group1-asset-63-svg',
    src: '/assets/avatars/group1/Asset 63.svg'
  },
  {
    id: 'group1-asset-64-svg',
    src: '/assets/avatars/group1/Asset 64.svg'
  },
  {
    id: 'group1-asset-65-svg',
    src: '/assets/avatars/group1/Asset 65.svg'
  },
  {
    id: 'group1-asset-66-svg',
    src: '/assets/avatars/group1/Asset 66.svg'
  },
  {
    id: 'group1-asset-67-svg',
    src: '/assets/avatars/group1/Asset 67.svg'
  },
  {
    id: 'group1-asset-68-svg',
    src: '/assets/avatars/group1/Asset 68.svg'
  },
  {
    id: 'group1-asset-69-svg',
    src: '/assets/avatars/group1/Asset 69.svg'
  },
  {
    id: 'group1-asset-7-svg',
    src: '/assets/avatars/group1/Asset 7.svg'
  },
  {
    id: 'group1-asset-70-svg',
    src: '/assets/avatars/group1/Asset 70.svg'
  },
  {
    id: 'group1-asset-71-svg',
    src: '/assets/avatars/group1/Asset 71.svg'
  },
  {
    id: 'group1-asset-72-svg',
    src: '/assets/avatars/group1/Asset 72.svg'
  },
  {
    id: 'group1-asset-73-svg',
    src: '/assets/avatars/group1/Asset 73.svg'
  },
  {
    id: 'group1-asset-74-svg',
    src: '/assets/avatars/group1/Asset 74.svg'
  },
  {
    id: 'group1-asset-75-svg',
    src: '/assets/avatars/group1/Asset 75.svg'
  },
  {
    id: 'group1-asset-76-svg',
    src: '/assets/avatars/group1/Asset 76.svg'
  },
  {
    id: 'group1-asset-77-svg',
    src: '/assets/avatars/group1/Asset 77.svg'
  },
  {
    id: 'group1-asset-78-svg',
    src: '/assets/avatars/group1/Asset 78.svg'
  },
  {
    id: 'group1-asset-79-svg',
    src: '/assets/avatars/group1/Asset 79.svg'
  },
  {
    id: 'group1-asset-8-svg',
    src: '/assets/avatars/group1/Asset 8.svg'
  },
  {
    id: 'group1-asset-80-svg',
    src: '/assets/avatars/group1/Asset 80.svg'
  },
  {
    id: 'group1-asset-81-svg',
    src: '/assets/avatars/group1/Asset 81.svg'
  },
  {
    id: 'group1-asset-9-svg',
    src: '/assets/avatars/group1/Asset 9.svg'
  },
  {
    id: 'group2-asset-1-svg',
    src: '/assets/avatars/group2/Asset 1.svg'
  },
  {
    id: 'group2-asset-100-svg',
    src: '/assets/avatars/group2/Asset 100.svg'
  },
  {
    id: 'group2-asset-102-svg',
    src: '/assets/avatars/group2/Asset 102.svg'
  },
  {
    id: 'group2-asset-103-svg',
    src: '/assets/avatars/group2/Asset 103.svg'
  },
  {
    id: 'group2-asset-104-svg',
    src: '/assets/avatars/group2/Asset 104.svg'
  },
  {
    id: 'group2-asset-105-svg',
    src: '/assets/avatars/group2/Asset 105.svg'
  },
  {
    id: 'group2-asset-106-svg',
    src: '/assets/avatars/group2/Asset 106.svg'
  },
  {
    id: 'group2-asset-107-svg',
    src: '/assets/avatars/group2/Asset 107.svg'
  },
  {
    id: 'group2-asset-108-svg',
    src: '/assets/avatars/group2/Asset 108.svg'
  },
  {
    id: 'group2-asset-109-svg',
    src: '/assets/avatars/group2/Asset 109.svg'
  },
  {
    id: 'group2-asset-11-svg',
    src: '/assets/avatars/group2/Asset 11.svg'
  },
  {
    id: 'group2-asset-112-svg',
    src: '/assets/avatars/group2/Asset 112.svg'
  },
  {
    id: 'group2-asset-120-svg',
    src: '/assets/avatars/group2/Asset 120.svg'
  },
  {
    id: 'group2-asset-121-svg',
    src: '/assets/avatars/group2/Asset 121.svg'
  },
  {
    id: 'group2-asset-124-svg',
    src: '/assets/avatars/group2/Asset 124.svg'
  },
  {
    id: 'group2-asset-125-svg',
    src: '/assets/avatars/group2/Asset 125.svg'
  },
  {
    id: 'group2-asset-128-svg',
    src: '/assets/avatars/group2/Asset 128.svg'
  },
  {
    id: 'group2-asset-129-svg',
    src: '/assets/avatars/group2/Asset 129.svg'
  },
  {
    id: 'group2-asset-130-svg',
    src: '/assets/avatars/group2/Asset 130.svg'
  },
  {
    id: 'group2-asset-133-svg',
    src: '/assets/avatars/group2/Asset 133.svg'
  },
  {
    id: 'group2-asset-134-svg',
    src: '/assets/avatars/group2/Asset 134.svg'
  },
  {
    id: 'group2-asset-135-svg',
    src: '/assets/avatars/group2/Asset 135.svg'
  },
  {
    id: 'group2-asset-136-svg',
    src: '/assets/avatars/group2/Asset 136.svg'
  },
  {
    id: 'group2-asset-137-svg',
    src: '/assets/avatars/group2/Asset 137.svg'
  },
  {
    id: 'group2-asset-139-svg',
    src: '/assets/avatars/group2/Asset 139.svg'
  },
  {
    id: 'group2-asset-14-svg',
    src: '/assets/avatars/group2/Asset 14.svg'
  },
  {
    id: 'group2-asset-141-svg',
    src: '/assets/avatars/group2/Asset 141.svg'
  },
  {
    id: 'group2-asset-144-svg',
    src: '/assets/avatars/group2/Asset 144.svg'
  },
  {
    id: 'group2-asset-15-svg',
    src: '/assets/avatars/group2/Asset 15.svg'
  },
  {
    id: 'group2-asset-17-svg',
    src: '/assets/avatars/group2/Asset 17.svg'
  },
  {
    id: 'group2-asset-18-svg',
    src: '/assets/avatars/group2/Asset 18.svg'
  },
  {
    id: 'group2-asset-19-svg',
    src: '/assets/avatars/group2/Asset 19.svg'
  },
  {
    id: 'group2-asset-20-svg',
    src: '/assets/avatars/group2/Asset 20.svg'
  },
  {
    id: 'group2-asset-21-svg',
    src: '/assets/avatars/group2/Asset 21.svg'
  },
  {
    id: 'group2-asset-22-svg',
    src: '/assets/avatars/group2/Asset 22.svg'
  },
  {
    id: 'group2-asset-23-svg',
    src: '/assets/avatars/group2/Asset 23.svg'
  },
  {
    id: 'group2-asset-24-svg',
    src: '/assets/avatars/group2/Asset 24.svg'
  },
  {
    id: 'group2-asset-26-svg',
    src: '/assets/avatars/group2/Asset 26.svg'
  },
  {
    id: 'group2-asset-27-svg',
    src: '/assets/avatars/group2/Asset 27.svg'
  },
  {
    id: 'group2-asset-28-svg',
    src: '/assets/avatars/group2/Asset 28.svg'
  },
  {
    id: 'group2-asset-3-svg',
    src: '/assets/avatars/group2/Asset 3.svg'
  },
  {
    id: 'group2-asset-30-svg',
    src: '/assets/avatars/group2/Asset 30.svg'
  },
  {
    id: 'group2-asset-31-svg',
    src: '/assets/avatars/group2/Asset 31.svg'
  },
  {
    id: 'group2-asset-32-svg',
    src: '/assets/avatars/group2/Asset 32.svg'
  },
  {
    id: 'group2-asset-33-svg',
    src: '/assets/avatars/group2/Asset 33.svg'
  },
  {
    id: 'group2-asset-34-svg',
    src: '/assets/avatars/group2/Asset 34.svg'
  },
  {
    id: 'group2-asset-35-svg',
    src: '/assets/avatars/group2/Asset 35.svg'
  },
  {
    id: 'group2-asset-36-svg',
    src: '/assets/avatars/group2/Asset 36.svg'
  },
  {
    id: 'group2-asset-37-svg',
    src: '/assets/avatars/group2/Asset 37.svg'
  },
  {
    id: 'group2-asset-38-svg',
    src: '/assets/avatars/group2/Asset 38.svg'
  },
  {
    id: 'group2-asset-39-svg',
    src: '/assets/avatars/group2/Asset 39.svg'
  },
  {
    id: 'group2-asset-41-svg',
    src: '/assets/avatars/group2/Asset 41.svg'
  },
  {
    id: 'group2-asset-42-svg',
    src: '/assets/avatars/group2/Asset 42.svg'
  },
  {
    id: 'group2-asset-43-svg',
    src: '/assets/avatars/group2/Asset 43.svg'
  },
  {
    id: 'group2-asset-44-svg',
    src: '/assets/avatars/group2/Asset 44.svg'
  },
  {
    id: 'group2-asset-45-svg',
    src: '/assets/avatars/group2/Asset 45.svg'
  },
  {
    id: 'group2-asset-47-svg',
    src: '/assets/avatars/group2/Asset 47.svg'
  },
  {
    id: 'group2-asset-50-svg',
    src: '/assets/avatars/group2/Asset 50.svg'
  },
  {
    id: 'group2-asset-51-svg',
    src: '/assets/avatars/group2/Asset 51.svg'
  },
  {
    id: 'group2-asset-52-svg',
    src: '/assets/avatars/group2/Asset 52.svg'
  },
  {
    id: 'group2-asset-53-svg',
    src: '/assets/avatars/group2/Asset 53.svg'
  },
  {
    id: 'group2-asset-54-svg',
    src: '/assets/avatars/group2/Asset 54.svg'
  },
  {
    id: 'group2-asset-55-svg',
    src: '/assets/avatars/group2/Asset 55.svg'
  },
  {
    id: 'group2-asset-56-svg',
    src: '/assets/avatars/group2/Asset 56.svg'
  },
  {
    id: 'group2-asset-58-svg',
    src: '/assets/avatars/group2/Asset 58.svg'
  },
  {
    id: 'group2-asset-6-svg',
    src: '/assets/avatars/group2/Asset 6.svg'
  },
  {
    id: 'group2-asset-60-svg',
    src: '/assets/avatars/group2/Asset 60.svg'
  },
  {
    id: 'group2-asset-61-svg',
    src: '/assets/avatars/group2/Asset 61.svg'
  },
  {
    id: 'group2-asset-62-svg',
    src: '/assets/avatars/group2/Asset 62.svg'
  },
  {
    id: 'group2-asset-64-svg',
    src: '/assets/avatars/group2/Asset 64.svg'
  },
  {
    id: 'group2-asset-65-svg',
    src: '/assets/avatars/group2/Asset 65.svg'
  },
  {
    id: 'group2-asset-66-svg',
    src: '/assets/avatars/group2/Asset 66.svg'
  },
  {
    id: 'group2-asset-67-svg',
    src: '/assets/avatars/group2/Asset 67.svg'
  },
  {
    id: 'group2-asset-68-svg',
    src: '/assets/avatars/group2/Asset 68.svg'
  },
  {
    id: 'group2-asset-69-svg',
    src: '/assets/avatars/group2/Asset 69.svg'
  },
  {
    id: 'group2-asset-70-svg',
    src: '/assets/avatars/group2/Asset 70.svg'
  },
  {
    id: 'group2-asset-71-svg',
    src: '/assets/avatars/group2/Asset 71.svg'
  },
  {
    id: 'group2-asset-73-svg',
    src: '/assets/avatars/group2/Asset 73.svg'
  },
  {
    id: 'group2-asset-75-svg',
    src: '/assets/avatars/group2/Asset 75.svg'
  },
  {
    id: 'group2-asset-78-svg',
    src: '/assets/avatars/group2/Asset 78.svg'
  },
  {
    id: 'group2-asset-79-svg',
    src: '/assets/avatars/group2/Asset 79.svg'
  },
  {
    id: 'group2-asset-80-svg',
    src: '/assets/avatars/group2/Asset 80.svg'
  },
  {
    id: 'group2-asset-82-svg',
    src: '/assets/avatars/group2/Asset 82.svg'
  },
  {
    id: 'group2-asset-83-svg',
    src: '/assets/avatars/group2/Asset 83.svg'
  },
  {
    id: 'group2-asset-84-svg',
    src: '/assets/avatars/group2/Asset 84.svg'
  },
  {
    id: 'group2-asset-85-svg',
    src: '/assets/avatars/group2/Asset 85.svg'
  },
  {
    id: 'group2-asset-86-svg',
    src: '/assets/avatars/group2/Asset 86.svg'
  },
  {
    id: 'group2-asset-87-svg',
    src: '/assets/avatars/group2/Asset 87.svg'
  },
  {
    id: 'group2-asset-89-svg',
    src: '/assets/avatars/group2/Asset 89.svg'
  },
  {
    id: 'group2-asset-9-svg',
    src: '/assets/avatars/group2/Asset 9.svg'
  },
  {
    id: 'group2-asset-90-svg',
    src: '/assets/avatars/group2/Asset 90.svg'
  },
  {
    id: 'group2-asset-91-svg',
    src: '/assets/avatars/group2/Asset 91.svg'
  },
  {
    id: 'group2-asset-92-svg',
    src: '/assets/avatars/group2/Asset 92.svg'
  },
  {
    id: 'group2-asset-94-svg',
    src: '/assets/avatars/group2/Asset 94.svg'
  },
  {
    id: 'group2-asset-95-svg',
    src: '/assets/avatars/group2/Asset 95.svg'
  },
  {
    id: 'group2-asset-96-svg',
    src: '/assets/avatars/group2/Asset 96.svg'
  },
  {
    id: 'group2-asset-97-svg',
    src: '/assets/avatars/group2/Asset 97.svg'
  },
  {
    id: 'group2-asset-98-svg',
    src: '/assets/avatars/group2/Asset 98.svg'
  },
  {
    id: 'group2-asset-99-svg',
    src: '/assets/avatars/group2/Asset 99.svg'
  },
  {
    id: 'group3-asset-1-svg',
    src: '/assets/avatars/group3/Asset 1.svg'
  },
  {
    id: 'group3-asset-10-svg',
    src: '/assets/avatars/group3/Asset 10.svg'
  },
  {
    id: 'group3-asset-11-svg',
    src: '/assets/avatars/group3/Asset 11.svg'
  },
  {
    id: 'group3-asset-12-svg',
    src: '/assets/avatars/group3/Asset 12.svg'
  },
  {
    id: 'group3-asset-13-svg',
    src: '/assets/avatars/group3/Asset 13.svg'
  },
  {
    id: 'group3-asset-14-svg',
    src: '/assets/avatars/group3/Asset 14.svg'
  },
  {
    id: 'group3-asset-15-svg',
    src: '/assets/avatars/group3/Asset 15.svg'
  },
  {
    id: 'group3-asset-16-svg',
    src: '/assets/avatars/group3/Asset 16.svg'
  },
  {
    id: 'group3-asset-17-svg',
    src: '/assets/avatars/group3/Asset 17.svg'
  },
  {
    id: 'group3-asset-18-svg',
    src: '/assets/avatars/group3/Asset 18.svg'
  },
  {
    id: 'group3-asset-19-svg',
    src: '/assets/avatars/group3/Asset 19.svg'
  },
  {
    id: 'group3-asset-2-svg',
    src: '/assets/avatars/group3/Asset 2.svg'
  },
  {
    id: 'group3-asset-20-svg',
    src: '/assets/avatars/group3/Asset 20.svg'
  },
  {
    id: 'group3-asset-21-svg',
    src: '/assets/avatars/group3/Asset 21.svg'
  },
  {
    id: 'group3-asset-22-svg',
    src: '/assets/avatars/group3/Asset 22.svg'
  },
  {
    id: 'group3-asset-23-svg',
    src: '/assets/avatars/group3/Asset 23.svg'
  },
  {
    id: 'group3-asset-24-svg',
    src: '/assets/avatars/group3/Asset 24.svg'
  },
  {
    id: 'group3-asset-25-svg',
    src: '/assets/avatars/group3/Asset 25.svg'
  },
  {
    id: 'group3-asset-26-svg',
    src: '/assets/avatars/group3/Asset 26.svg'
  },
  {
    id: 'group3-asset-27-svg',
    src: '/assets/avatars/group3/Asset 27.svg'
  },
  {
    id: 'group3-asset-28-svg',
    src: '/assets/avatars/group3/Asset 28.svg'
  },
  {
    id: 'group3-asset-29-svg',
    src: '/assets/avatars/group3/Asset 29.svg'
  },
  {
    id: 'group3-asset-3-svg',
    src: '/assets/avatars/group3/Asset 3.svg'
  },
  {
    id: 'group3-asset-30-svg',
    src: '/assets/avatars/group3/Asset 30.svg'
  },
  {
    id: 'group3-asset-31-svg',
    src: '/assets/avatars/group3/Asset 31.svg'
  },
  {
    id: 'group3-asset-32-svg',
    src: '/assets/avatars/group3/Asset 32.svg'
  },
  {
    id: 'group3-asset-33-svg',
    src: '/assets/avatars/group3/Asset 33.svg'
  },
  {
    id: 'group3-asset-34-svg',
    src: '/assets/avatars/group3/Asset 34.svg'
  },
  {
    id: 'group3-asset-35-svg',
    src: '/assets/avatars/group3/Asset 35.svg'
  },
  {
    id: 'group3-asset-36-svg',
    src: '/assets/avatars/group3/Asset 36.svg'
  },
  {
    id: 'group3-asset-37-svg',
    src: '/assets/avatars/group3/Asset 37.svg'
  },
  {
    id: 'group3-asset-38-svg',
    src: '/assets/avatars/group3/Asset 38.svg'
  },
  {
    id: 'group3-asset-39-svg',
    src: '/assets/avatars/group3/Asset 39.svg'
  },
  {
    id: 'group3-asset-4-svg',
    src: '/assets/avatars/group3/Asset 4.svg'
  },
  {
    id: 'group3-asset-40-svg',
    src: '/assets/avatars/group3/Asset 40.svg'
  },
  {
    id: 'group3-asset-5-svg',
    src: '/assets/avatars/group3/Asset 5.svg'
  },
  {
    id: 'group3-asset-6-svg',
    src: '/assets/avatars/group3/Asset 6.svg'
  },
  {
    id: 'group3-asset-7-svg',
    src: '/assets/avatars/group3/Asset 7.svg'
  },
  {
    id: 'group3-asset-8-svg',
    src: '/assets/avatars/group3/Asset 8.svg'
  },
  {
    id: 'group3-asset-9-svg',
    src: '/assets/avatars/group3/Asset 9.svg'
  }
]

export {
  avatars
}
