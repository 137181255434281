import { Doughnut, mixins } from 'vue-chartjs'

const { reactiveProp } = mixins

export default {
  extends: Doughnut,
  mixins: [reactiveProp],
  props: ['chartdata', 'options'],
  mounted () {
    this.addPlugin({
      id: 'my-plugin',
      beforeDraw: function (chart) {
        if (chart.config.options.elements.center) {
          // Get ctx from string
          const ctx = chart.chart.ctx

          // Get options from the center object in options
          const centerConfig = chart.config.options.elements.center
          // const fontStyle = centerConfig.fontStyle || 'Arial'

          const percentage = centerConfig.percentage
          const label = centerConfig.label

          // Pick a new font size so it will not be larger than the height of label.
          // const fontSizeToUse = Math.min(newFontSize, elementHeight)
          const fontSizeToUse = 14

          // Set font settings to draw it correctly.
          ctx.textAlign = 'center'
          ctx.textBaseline = 'middle'
          ctx.font = 'bold 14px \'Open Sans\', sans-serif'
          ctx.fillStyle = '#212529'

          const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2)
          const centerY = (((chart.chartArea.top - fontSizeToUse) + chart.chartArea.bottom) / 2)
          const centerY2 = (((chart.chartArea.top + fontSizeToUse) + chart.chartArea.bottom) / 2)

          // Draw text in center
          ctx.fillText(percentage, centerX, centerY)
          ctx.fillText(label, centerX, centerY2)
        }
      }
    })
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options)
  },
  watch: {
    chartdata () {
      this.renderChart()
    }
  }
}
