<template>
  <div @click="$emit('click',notification)" class="notification-row">
    <div class="column">
      <div class="image">
        <img class="img-fluid" :src="userTriggerAvatar" alt="">
        <div v-if="notification.status === 'unread'" class="dot"></div>
      </div>
    </div>
    <div class="column">
      <div class="user-name">{{ notification.from }}</div>
      <div class="user-type">{{ notification.user_type }}</div>
    </div>
    <div class="column">{{ notificationSubject }}</div>
    <div class="column">{{ notification.created_at | dateMonthDayYear }}</div>
  </div>
</template>

<script>
export default {
  name: 'NotificationItem',
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  computed: {
    userTriggerAvatar () {
      let avatar
      if (this.notification.trigger_user) {
        if (this.notification.trigger_user.user_type.slug === 'teacher') {
          avatar = this.notification.trigger_user.teacher.avatar
        } else if (this.notification.trigger_user.user_type.slug === 'student') {
          avatar = this.notification.trigger_user.student.thumbnail
        } else if (this.notification.trigger_user.user_type.slug === 'admin') {
          avatar = null
        }
      } else {
        avatar = null
        return
      }

      return avatar ?? 'https://i.pravatar.cc/100'
    },
    notificationSubject () {
      const subjects = {
        student_partner_responded_to_their_video: 'You have received a response',
        student_teacher_changed_status_of_video: 'Video has been approved and is in process'
      }
      return this.notification.type in subjects ? subjects[this.notification.type] : this.notification.subject
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"

.notification-grid
  background-color #F5F6FF

.notification-row
  display grid
  grid-template-columns: 33px 1fr 1fr 0.5fr
  grid-gap: 0.5em
  padding-left 7px
  align-items center
  padding-bottom 7px
  padding-right 17px
  padding-top 7px
  background-color white
  margin-bottom 2.5px
  border-bottom: 1px solid #EEEAEA
  border-left 5px solid #F5F6FF
  border-right 5px solid #F5F6FF
  font-size 9px
  cursor pointer

  &__header
    background-color color-purple-100
    border-left 5px solid color-purple-100
    border-right 5px solid color-purple-100
    font-weight 600
    font-size 11px
    color #3D3D3D

    .column
      display flex
      align-items center

      .order
        display flex
        flex-direction column
        padding-right 4px

        span
          font-size 6px

  .column
    .image
      padding-right 7px
      position relative

      .dot
        position absolute
        top 0
        right 6px
        width 8px
        height 8px
        background-color #FE472A
        border-radius 50%

      img
        border-radius 50%

    .user-name
      font-size 10px
      font-weight 600

    .user-type
      font-size 8px

@media (min-width: 768px)
  .notification-row
    grid-template-columns: 59px 1fr 1fr 0.5fr
    font-size 9px

    .column
      .image
        padding-right 33px

@media (min-width: 992px)
  .notification-row
    grid-template-columns: 80px 1fr 1fr 0.5fr
    font-size 14px

    .column
      .image
        padding-right 40px

      .user-name
        font-size 16px
        font-weight bold

      .user-type
        font-size 14px

.section-notifications-page
  padding-top 36px
  padding-bottom 15px
  padding-right 15px
  padding-left 15px
  height 100%

  .table-area
    height 100%
    background-color #F5F6FF

@media (min-width: 768px)
  .section-notifications-page
    padding-right 53px
    padding-left 53px

    .title
      font-size 1.25rem

@media (min-width: 992px)
  .section-notifications-page
    padding-right 150px
    padding-left 150px

    .title
      font-size 1.5rem

</style>
