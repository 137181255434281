<script>
import InputGroup from '@/modules/panel/components/Form/InputGroup.vue'
import {
  maxLength,
  maxValue,
  minLength,
  minValue,
  numeric,
  required,
  email
} from 'vuelidate/lib/validators'

export default {
  name: 'FormPayment',
  components: { InputGroup },
  data () {
    return {
      creditCard: {
        number: null,
        holder: '',
        cvv: null,
        expirationMonth: '',
        expirationYear: ''
      },
      email: ''
    }
  },
  validations () {
    return {
      creditCard: {
        number: {
          required,
          numeric
        },
        holder: {
          required
        },
        cvv: {
          required,
          numeric,
          minLength: minLength(3),
          maxLength: maxLength(4)
        },
        expirationMonth: {
          required,
          maxValue: maxValue(12),
          minValue: minValue(1),
          minLength: minLength(2),
          numeric
        },
        expirationYear: {
          required,
          maxValue: maxValue(99),
          minValue: minValue(0),
          minLength: minLength(2),
          numeric
        }
      },
      email: {
        required,
        email
      }
    }
  },
  computed: {
    formCreditCard () {
      const { number, holder, cvv, expirationMonth, expirationYear } =
        this.creditCard

      return {
        number,
        holder,
        cvv,
        expiration: `${expirationMonth}${expirationYear}`
      }
    }
  },
  methods: {
    onProcessPayment () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.$emit('on-payment', { creditCard: { ...this.formCreditCard }, email: this.email })
    },
    onCancel () {
      this.$emit('on-cancel')
    }
  }
}
</script>

<template>
  <b-form class="form-payment">
    <input-group
      v-model="$v.creditCard.number.$model"
      :validator="$v.creditCard.number"
      label="Credit Card Number *"
      :maxlength="40"
    />
    <input-group
      v-model="$v.creditCard.holder.$model"
      :validator="$v.creditCard.holder"
      label="Credit Card Holder *"
      :maxlength="40"
    />
    <b-form-group
      label="Credit Card Expiration *"
      class="form-payment__expiration"
    >
      <div class="d-flex">
        <input-group
          v-model="$v.creditCard.expirationMonth.$model"
          :show-errors="true"
          type="text"
          :maxlength="2"
          :validator="$v.creditCard.expirationMonth"
          :numeric-errors="true"
          placeholder="MM"
        />
        <span class="form-payment__bar"> / </span>
        <input-group
          v-model="$v.creditCard.expirationYear.$model"
          :show-errors="false"
          :numeric-errors="true"
          :maxlength="2"
          type="text"
          placeholder="AA"
          :validator="$v.creditCard.expirationYear"
        />
      </div>
    </b-form-group>

    <input-group
      v-model="$v.creditCard.cvv.$model"
      id="input-cvv"
      :maxlength="4"
      :validator="$v.creditCard.cvv"
      label="CVV *"
      :numeric-errors="true"
      class="form-payment__cvv"
    />

    <input-group
      v-model="$v.email.$model"
      :validator="$v.email"
      label="Email Address *"
      :maxlength="40"
    />

    <p>Receipt will be emailed to this address</p>
    <b-form-row class="flex-row justify-content-center mt-4">
      <b-button variant="primary" class="mr-1" @click="onProcessPayment"
        >Pay Now
      </b-button>
      <b-button variant="primary" @click="onCancel">Cancel</b-button>
    </b-form-row>
  </b-form>
</template>

<style scoped lang="stylus">
.form-payment {
  &__expiration {
    .form-group {
      width 90px
      margin-bottom 0
    }

    ::v-deep .invalid-feedback {
      min-width 200px
    }
  }

  &__bar {
    font-size 25px
    margin 4px 6px 0
  }

  &__cvv {
    width 90px

    ::v-deep .invalid-feedback {
      min-width 200px
    }
  }
}
</style>
