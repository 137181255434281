<template>
  <div class="comment-item">
    <div class="photo-area">
      <div class="photo" v-if="comment.user.student.thumbnail" :style="avatarStyle"></div>
      <b-avatar v-else></b-avatar>
    </div>
    <div class="info-area">
      <div class="user-name">{{ comment.user.student.first_name }} {{ comment.user.student.last_initial }} <span class="student">Student</span></div>
      <div class="date">{{ comment.created_at | dateElapseTime }}</div>
      <div class="subject">{{ comment.message_title }}</div>
      <div class="content">{{ comment.message_text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageBoardComment',
  props: {
    comment: {
      type: [Object, Array],
      required: true
    }
  },
  computed: {
    avatarStyle () {
      return {
        backgroundImage: `url("${this.comment.user.student.thumbnail}")`
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"
.comment-item
  margin-bottom 5px

  .photo-area
    display inline-block
    vertical-align top
    width 15%
    padding 8px 0px 8px 10px
    max-width 45px
    @media (min-width 515px)
      max-width 65px

    .photo
      height 32px
      width 32px
      border-radius 50%
      background-repeat no-repeat
      background-size cover
      background-position center
      @media (min-width: 515px)
        height 48px
        width 48px

    ::v-deep
      .b-avatar
        width 32px
        height 32px
        @media (min-width: 515px)
          width 48px
          height 48px

  &:not(:last-child)
    border-bottom 1px solid #ECE8E8

  .info-area
    display inline-block
    vertical-align top
    width 85%
    color #3D3D3D
    padding 8px 8px 8px 10px

    .user-name
      font-weight bold
      text-transform capitalize
      @media (min-width: 992px)
        font-size 16px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 15px
      @media (max-width: 767px)
        font-size 14px

    .student
      font-weight 400
      font-style italic
      margin-left 4px
      @media (min-width: 992px)
        font-size 16px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 15px
      @media (max-width: 767px)
        font-size 14px

    .date
      @media (min-width: 992px)
        font-size 16px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 15px
      @media (max-width: 767px)
        font-size 14px

    .subject
      font-weight bold
      text-transform uppercase
      margin-top 7px
      margin-bottom 2px
      word-break break-word
      @media (min-width: 992px)
        font-size 16px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 15px
      @media (max-width: 767px)
        font-size 14px

    .content
      word-break break-word
      &::first-letter
        text-transform capitalize
      @media (min-width: 992px)
        font-size 16px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 15px
      @media (max-width: 767px)
        font-size 14px
</style>
