import { http } from './http'
import { AppStorage } from '@/core/utils'

const resource = '/api/student'
const teacherPath = resource + '/teacher'
const userValidation = () => {
  const user = AppStorage.getUser()
  return user.is_teacher ? teacherPath : resource
}

class Classroom {
  constructor (obj) {
    Object.assign(this, obj)
  }

  requiredPayment () {
    return !!this.student_payment_required
  }

  hasPayment () {
    return !!this.student_payments.length
  }

  showPaymentForm () {
    return (
      this.student_payment_required &&
      this.student_payment_price > 0 &&
      !this.hasPayment()
    )
  }
}

export class ClassroomError extends Error {
  constructor (message) {
    super(message)
    this.name = 'ClassroomError'
  }
}

export class ClassroomPaymentError extends ClassroomError {
  constructor (message) {
    super(message)
    this.name = 'ClassroomPaymentError'
  }
}

export default {
  list () {
    return http.get(`${userValidation()}/classrooms`).then((response) => {
      if (!response.success) throw new ClassroomError(response.message)
      return response.data.map((c) => new Classroom(c))
    })
  },
  getClassroom (classroomId) {
    return http
      .get(`${userValidation()}/classrooms/${classroomId}`)
      .then((response) => {
        if (!response.success) {
          if (response.error_code === 26) {
            throw new ClassroomPaymentError(response.message)
          }

          throw new ClassroomError(response.message)
        }
        return new Classroom(response.data)
      })
  },
  add (classroomCode, username) {
    return http
      .post(`${userValidation()}/classrooms`, {
        classroom_code: classroomCode,
        username: username
      })
      .then((response) => {
        return response
      })
  },
  tasks (classroomId) {
    return http
      .get(`${userValidation()}/classrooms/${classroomId}/tasks`)
      .then((response) => {
        return response
      })
  },
  tasksWithPartnerSubmissions (classroomId) {
    return http
      .get(
        `${userValidation()}/classrooms/${classroomId}/tasks-with-partner-submissions`
      )
      .then((response) => {
        return response
      })
  },
  task (classroomId, taskId) {
    return http
      .get(`${userValidation()}/classrooms/${classroomId}/tasks/${taskId}`)
      .then((response) => {
        return response
      })
  },
  getSubmissions (classroomId, filters) {
    return http
      .get(`${userValidation()}/classrooms/${classroomId}/submissions`, {
        params: filters
      })
      .then((response) => {
        return response
      })
  },
  getSubmission (classroomId, postId) {
    return http
      .get(
        `${userValidation()}/classrooms/${classroomId}/submissions/${postId}`
      )
      .then((response) => {
        return response
      })
  },
  getResponses (classroomId, filters) {
    return http
      .get(`${userValidation()}/classrooms/${classroomId}/responses`, {
        params: filters
      })
      .then((response) => {
        return response
      })
  },
  getResponse (classroomId, responseId) {
    return http
      .get(
        `${userValidation()}/classrooms/${classroomId}/responses/${responseId}`
      )
      .then((response) => {
        return response
      })
  },
  getClassroomPartnerSubmissions (classroomId, filters) {
    return http
      .get(`${userValidation()}/classrooms/${classroomId}/videos`, {
        params: { filters: filters }
      })
      .then((response) => {
        return response
      })
  },
  getClassroomPartnerStudentSubmission (classroomId, submissionId) {
    return http
      .get(
        `${userValidation()}/classrooms/${classroomId}/videos/${submissionId}`
      )
      .then((response) => {
        return response
      })
  },
  async saveSubmission (url, classroomId, taskId, submissionId = null) {
    const response = !submissionId
      ? await this.submitToTask(classroomId, taskId, url)
      : await this.responseToPartner(classroomId, taskId, submissionId, url)

    if (!response.success) {
      throw new Error(response.message)
    } else {
      return response
    }
  },
  async checkSubmissionVideo (fileName, classroomId) {
    const response = await http.post(
      `${userValidation()}/classrooms/${classroomId}/submissions-videos-check`,
      { fileName: fileName }
    )

    if (!response.success) {
      throw new Error(response.message)
    } else {
      if (response.submission === null || response.submission === false) {
        throw new Error('Ops, something went wrong while processing the sent video')
      } else {
        return response
      }
    }
  },
  async deleteSubmissionVideo (fileName, classroomId) {
    const response = await http.delete(
      `${userValidation()}/classrooms/${classroomId}/submissions`,
      { params: { fileName: fileName } }
    )

    if (!response.success) {
      throw new Error(response.message)
    } else {
      return response
    }
  },
  submitToTask (classroomId, taskId, url) {
    return http
      .post(
        `${userValidation()}/classrooms/${classroomId}/tasks/${taskId}/videos`,
        { url: url }
      )
      .then((response) => {
        return response
      })
  },
  responseToPartner (classroomId, taskId, submissionId, url) {
    return http
      .post(
        `${userValidation()}/classrooms/${classroomId}/tasks/${taskId}/responses/${submissionId}`,
        { url: url }
      )
      .then((response) => {
        return response
      })
  },
  responseTextToPartner (classroomId, taskId, submissionId, text) {
    return http
      .post(
        `${resource}/classrooms/${classroomId}/tasks/${taskId}/responses/${submissionId}`,
        { text: text }
      )
      .then((response) => {
        return response
      })
  },
  listMessages (classroomId) {
    return http
      .get(`${resource}/classrooms/${classroomId}/messages`)
      .then((response) => {
        return response
      })
  },
  responseMessages (classroomId, messageId, form) {
    return http
      .post(`${resource}/classrooms/${classroomId}/messages/${messageId}`, form)
      .then((response) => {
        return response
      })
  },
  reportVideo (data) {
    return http
      .post(`${userValidation()}/inappropriate-video`, data)
      .then((response) => {
        return response
      })
  }
}
