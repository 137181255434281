import Vue from 'vue'
import VueRouter from 'vue-router'
import SecurityRoutes from '@/modules/security/routes'
import PanelRoutes from '@/modules/panel/routes'
import { AppStorage } from '@/core/utils'

Vue.use(VueRouter)

let routes = []

routes = routes.concat(SecurityRoutes, PanelRoutes)

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const user = AppStorage.getUser()
  const loggedIn = !!user.token
  const isTeacher = user.is_teacher

  // redirect to login page if not logged in and trying to access a restricted page
  if (to.meta.isAuthenticate && !loggedIn) {
    return next({ name: 'LoginPage' })
  } else if (to.name === 'LoginPage' && loggedIn) {
    return next({ name: 'MyClassroomsPage' })
  }
  if (to.meta.isTeacher && isTeacher) {
    return next({ name: 'MyClassroomsPage' })
  }

  next()
})

export default router
