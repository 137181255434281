class FullscreenManager {
  constructor () {
    this.isFullscreen = false
    this.onFullscreenchange = () => {
    }

    // this becomes important when the user doesn't use the button to exit
    // fullscreen but hits ESC on desktop, pushes a physical back button on
    // mobile etc.
    document.addEventListener('fullscreenchange', () => {
      this.setFullscreenStatus()
    })
    document.addEventListener('mozfullscreenchange', () => {
      this.setFullscreenStatus()
    })
    document.addEventListener('MSFullscreenChange', () => {
      this.setFullscreenStatus()
    })
    document.addEventListener('webkitfullscreenchange', () => {
      this.setFullscreenStatus()
    })
  }

  setFullscreenStatus () {
    this.isFullscreen = !!(document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement)
    this.onFullscreenchange()
  }

  toggle () {
    if (!this.isFullscreen) {
      this.requestFullscreen()
    } else {
      this.exitFullscreen()
    }

    this.onFullscreenchange()
  }

  requestFullscreen () {
    try {
      const elem = document.documentElement

      if (elem.requestFullscreen) {
        elem.requestFullscreen({ navigationUI: 'hide' })
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen({ navigationUI: 'hide' })
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen({ navigationUI: 'hide' })
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen({ navigationUI: 'hide' })
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  exitFullscreen () {
    try {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    } catch (error) {
      throw new Error(error)
    }
  }
}

export default FullscreenManager
