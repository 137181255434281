<template>
  <div class="section-notifications-page">
    <h2 class="title text-center mb-3">NOTIFICATIONS</h2>
    <div class="table-area">
      <div class="notification-grid">
        <div class="notification-row notification-row__header">
          <div class="column"></div>
          <div class="column">
            <div class="order">
              <span class="icon-caret-top"></span>
              <span class="icon-caret-bottom"></span>
            </div>
            From
          </div>
          <div class="column">
            <div class="order">
              <span class="icon-caret-top"></span>
              <span class="icon-caret-bottom"></span>
            </div>
            Subject
          </div>
          <div class="column">
            <div class="order">
              <span class="icon-caret-top"></span>
              <span class="icon-caret-bottom"></span>
            </div>
            Date
          </div>
        </div>
        <notification-item @click="onOpenNotification" :key="'notification'+index"
                            v-for="(notification, index) in notifications" :notification="notification"/>
        <b-overlay no-wrap :show="loading"></b-overlay>
      </div>
    </div>
  </div>
</template>

<script>

import { NotificationService } from '@/core/services'
import { Notifications } from '@/core/utils'
import NotificationItem from '@/modules/panel/pages/NotificationItem'

export default {
  name: 'NotificationsPage',
  components: {
    NotificationItem
  },
  data () {
    return {
      notifications: [],
      loading: false,
      interval: null
    }
  },
  mounted () {
    this.getNotifications().then(() => {
      this.interval = setInterval(() => {
        this.getNotifications(false)
      }, 15000)
    })
  },
  methods: {
    getNotifications (showLoading = true) {
      if (showLoading) {
        this.loading = true
      }
      return NotificationService.list().then(response => {
        this.notifications = response.data
      }).catch(e => {
        const errorMessage = e.message ? e.message : 'Error while trying notification.'
        showLoading && this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
      }).finally(() => {
        this.loading = false
      })
    },
    async onOpenNotification (notification) {
      const type = notification.type
      const params = {
        classroomId: notification.classroom_id
      }
      const query = {}
      let routerName = null

      switch (type) {
        case Notifications.type.studentTeacherStatusVideo:
          routerName = 'PostDetailPage'
          params.postId = notification.object_id
          break
        case Notifications.type.studentTeacherStatusResponse:
          routerName = 'ResponseDetailPage'
          params.responseId = notification.object_id
          break
        case Notifications.type.studentTeacherStatusMessage:
          routerName = 'MessageBoardPage'
          query.commentId = notification.object_id
          break
        case Notifications.type.studentPartnerRespondedToTheirVideo:
          routerName = 'PostDetailPage'
          params.postId = notification.object_id
          break
        case Notifications.type.studentTeacherCommentOnVideo:
          routerName = 'PostDetailPage'
          params.postId = notification.object_id
          break
        case Notifications.type.studentTeacherCommentOnResponse:
          routerName = 'ResponseDetailPage'
          params.responseId = notification.object_id
          break
        case Notifications.type.studentVideoFail:
          NotificationService.status(notification.id, 'read').then(() => {
            this.getNotifications(false)
          })
          break
        case Notifications.type.studentFailedVideoConversion:
          routerName = 'PostDetailPage'
          params.postId = notification.object_id
          break
        case Notifications.type.studentResponseFailedVideo:
          routerName = 'ResponseDetailPage'
          params.responseId = notification.object_id
          break
      }

      if (routerName) {
        this.$router.push({
          name: routerName,
          params: { ...params },
          query: { ...query }
        }).then(() => {
          NotificationService.status(notification.id, 'read')
        })
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"

.notification-grid
  background-color #F5F6FF

.notification-row
  display grid
  grid-template-columns: 33px 1fr 1fr 0.5fr
  grid-gap: 0.5em
  padding-left 7px
  align-items center
  padding-bottom 7px
  padding-right 17px
  padding-top 7px
  background-color white
  margin-bottom 2.5px
  border-bottom: 1px solid #EEEAEA
  border-left 5px solid #F5F6FF
  border-right 5px solid #F5F6FF
  font-size 9px
  cursor pointer

  &__header
    background-color color-purple-100
    border-left 5px solid color-purple-100
    border-right 5px solid color-purple-100
    font-weight 600
    font-size 11px
    color #3D3D3D

    .column
      display flex
      align-items center

      .order
        display flex
        flex-direction column
        padding-right 4px

        span
          font-size 6px

  .column
    .image
      padding-right 7px
      position relative

      .dot
        position absolute
        top 0
        right 6px
        width 8px
        height 8px
        background-color #FE472A
        border-radius 50%

      img
        border-radius 50%

    .user-name
      font-size 10px
      font-weight 600

    .user-type
      font-size 8px

@media (min-width: 768px)
  .notification-row
    grid-template-columns: 59px 1fr 1fr 0.5fr
    font-size 9px

    .column
      .image
        padding-right 33px

@media (min-width: 992px)
  .notification-row
    grid-template-columns: 80px 1fr 1fr 0.5fr
    font-size 14px

    .column
      .image
        padding-right 40px

      .user-name
        font-size 16px
        font-weight bold

      .user-type
        font-size 14px

.section-notifications-page
  padding-top 36px
  padding-bottom 15px
  padding-right 15px
  padding-left 15px
  height 100%

  .table-area
    height 100%
    background-color #F5F6FF

@media (min-width: 768px)
  .section-notifications-page
    padding-right 53px
    padding-left 53px

    .title
      font-size 1.25rem

@media (min-width: 992px)
  .section-notifications-page
    padding-right 150px
    padding-left 150px

    .title
      font-size 1.5rem

</style>
