<template>
  <button
    @click="fullscreenToggle"
    :class="customClass"
    class="fullscreen-button">
    <i :class="`${isFullscreen ? 'fa fa-compress' : 'fa fa-expand'} video-icon`" aria-hidden="true"></i>
  </button>
</template>

<script>
import Fullscreen from './fullscreenManager'

export default {
  name: 'FullscreenVideo',
  props: {
    customClass: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isFullscreen: false,
      fullscreenManager: new Fullscreen()
    }
  },
  mounted () {
    this.fullscreenManager.onFullscreenchange = () => {
      this.isFullscreen = this.fullscreenManager.isFullscreen
    }
  },
  methods: {
    fullscreenToggle () {
      this.fullscreenManager.toggle()
      this.$emit('on-fullscreen')
    },
    clickFullscreen () {

    }
  },
  watch: {
    isFullscreen (newValue) {
      if (!newValue) {
        this.$emit('close-fullscreen')
      }
    }
  }
}
</script>

<style lang="stylus" scoped>

.fullscreen-button
  border none
  background-color transparent
  outline 0
  opacity 0.9
  display inline-block
  color rgb(222, 226, 230)
  cursor pointer

  .video-icon
    font-size 22px

@media(max-width 767px)
  .video-icon
    font-size 18px !important
</style>
