<template>
  <b-overlay :show="loading">
    <b-row class="change-password-container">
      <b-col cols="10" sm="8" md="6" lg="4" xl="4" class="align-self-center mx-auto">
        <div class="d-flex flex-column align-items-center">
          <h3 class="title">Change Password</h3>
          <p class="subtitle"><i>all fields required *</i></p>
        </div>
        <div class="d-flex flex-column align-items-stretch">
          <b-form @submit.prevent="changePassword">
            <b-form-group :invalid-feedback="currentPassword.invalidFeedback" :state="currentPassword.state">
              <b-form-input v-model="currentPassword.value" type="password" placeholder="Old Password *" />
            </b-form-group>
            <b-form-group :invalid-feedback="newPassword.invalidFeedback" :state="newPassword.state">
              <b-form-input v-model="newPassword.value" type="password" placeholder="New Password *" />
            </b-form-group>
            <b-form-group :invalid-feedback="confirmPassword.invalidFeedback" :state="confirmPassword.state">
              <b-form-input v-model="confirmPassword.value" type="password" placeholder="Confirm New Password *" />
            </b-form-group>
            <div class="d-flex justify-content-around buttons">
              <b-button :to="{ name: 'MyClassroomsPage' }" variant="primary">CANCEL</b-button>
              <b-button type="submit" variant="primary">APPLY</b-button>
            </div>
          </b-form>
        </div>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { UserService } from '@/core/services'

export default {
  name: 'ChangePasswordPage',
  data () {
    return {
      loading: false,
      currentPassword: {
        value: '',
        state: true,
        invalidFeedback: ''
      },
      newPassword: {
        value: '',
        state: true,
        invalidFeedback: ''
      },
      confirmPassword: {
        value: '',
        state: true,
        invalidFeedback: ''
      }
    }
  },
  methods: {
    validateRequired (field) {
      field.state = true
      if (field.value === '') {
        field.invalidFeedback = 'This field is required.'
        field.state = false
      }
    },
    validateConfirmPassword () {
      this.validateRequired(this.confirmPassword)
      if (this.confirmPassword.state) {
        this.confirmPassword.state = this.newPassword.value === this.confirmPassword.value
        if (!this.confirmPassword.state) {
          this.confirmPassword.invalidFeedback = 'New password and confirm password must be the same.'
        }
      }
    },
    async changePassword () {
      this.validateRequired(this.currentPassword)
      this.validateRequired(this.newPassword)
      this.validateConfirmPassword()
      if (this.currentPassword.state && this.newPassword.state && this.confirmPassword.state) {
        this.loading = true
        try {
          await UserService.updatePassword(this.newPassword.value, this.currentPassword.value)
          await this.$bvModal.msgBoxOk('Password changed successfully')
          await this.$router.push({ name: 'MyClassroomsPage' })
        } catch (e) {
          const errorMessage = e.message ? e.message : 'Error while trying to change password.'
          console.log(e, errorMessage)
          await this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
        } finally {
          this.loading = false
        }
      }
    }
  },
  watch: {
    'currentPassword.value': function () {
      this.validateRequired(this.currentPassword)
    },
    'newPassword.value': function () {
      this.validateRequired(this.newPassword)
      this.validateConfirmPassword()
    },
    'confirmPassword.value': function () {
      this.validateConfirmPassword()
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"

.change-password-container
  margin-top 110px

.title
  margin-bottom 0

.subtitle
  color color-black-200
  font-size 12px
  margin-bottom 54px

.buttons
  margin-top 25px
</style>
