import { mapActions, mapGetters } from 'vuex'

export const ClassroomMixins = {
  computed: {
    ...mapGetters({
      classroom: 'ClassroomModule/classroom'
    })
  },
  methods: {
    ...mapActions({
      getClassroom: 'ClassroomModule/getClassroom'
    })
  }
}
