<template>
  <div class="section-change-avatar-page">
    <h2 class="title text-center mb-0">Set Avatar</h2>
    <div class="section-change-avatar">
      <b-avatar :src="imagesPath(userAvatar)" class="user-avatar" text="Select Avatar"></b-avatar>
      <b-button class="form-upload-avatar"
                variant="primary"
                @click="$bvModal.show('avatar-list-modal')">SELECT IMAGE</b-button>
      <b-button :to="{ name: 'MyClassroomsPage' }" class="bt-cancel-avatar" variant="primary">CANCEL</b-button>
    </div>

    <b-modal id="avatar-list-modal" centered hide-footer hide-header size="lg">
      <div class="avatar-list-container">
        <div class="avatar-list">
          <div class="avatar-img"
               :class="{'avatar-img-selected': imageSelected.id === img.id}"
               v-for="img in images"
               @click="imageSelected = img"
               :key="img.id">
            <b-img-lazy v-bind="mainProps" :src="imagesPath(img.id)" :alt="img.id"/>
          </div>
        </div>
      </div>
      <p class="prompt-select-avatar" v-if="isNewUser">Select an Avatar and Click OK</p>
      <div class="avatar-list-buttons">
        <b-button class="avatar-button"
                  variant="primary"
                  @click="$bvModal.hide('avatar-list-modal')">{{isNewUser ? 'DO THIS LATER' : 'CANCEL'}}</b-button>
        <b-button class="avatar-button"
                  variant="primary"
                  :disabled="!imageSelected.id"
                  @click="uploadUserAvatar">{{isNewUser ? 'OK' : 'SUBMIT'}}</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { AppStorage, getAvatarImageById, avatars } from '@/core/utils'
import { UserService } from '@/core/services'

export default {
  name: 'ChangeAvatarPage',
  data () {
    return {
      user: AppStorage.getUser(),
      mainProps: { blank: true, width: 100, height: 100, class: 'm-2' },
      images: [],
      imageSelected: {
        id: null,
        src: null
      }
    }
  },
  computed: {
    userAvatar: {
      get: function () {
        return this.user.thumbnail
      },
      set: function (value) {
        this.user = Object.assign({}, { ...this.user }, { thumbnail: value })
      }
    },
    isNewUser () {
      return this.user.isNewUser
    }
  },
  async mounted () {
    this.user = await AppStorage.getUser()
    this.images = await avatars
    if (this.isNewUser) {
      this.$bvModal.show('avatar-list-modal')
    }
  },
  methods: {
    uploadUserAvatar () {
      UserService.updateAvatar(process.env.VUE_APP_BASE_URL + this.imageSelected.src).then(response => {
        this.userAvatar = response.data.thumbnail
        this.$root.$emit('avatar-updated', response.data.thumbnail)
        this.$bvModal.msgBoxOk('Avatar updated successfully').then(() => {
          this.$router.push({ name: 'MyClassroomsPage' })
        })
      }).catch(e => {
        const errorMessage = e.message ? e.message : 'Error while trying to update avatar.'
        console.log(e, errorMessage)
        this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
      })
    },
    showErrors (err) {
      this.$bvModal.msgBoxOk(`${err.message}, Please try again`, {
        size: 'sm',
        dialogClass: 'modal-message-box',
        noCloseOnBackdrop: true,
        centered: true
      })
    },
    imagesPath (id) {
      return getAvatarImageById(id)
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"

.section-change-avatar-page
  padding-top 110px

.section-change-avatar
  margin-top 30px
  display flex
  justify-content center
  align-items center
  flex-direction column

  .bt-cancel-avatar
    padding-top 3px
    padding-bottom 3px

  .user-avatar
    width 10rem
    height 10rem
    border 2px solid color-blue-200
    background-color #F6F6FB

    &::v-deep
      .b-avatar-text
        text-transform capitalize
        color #262626
        font-size 14px

  .form-upload-avatar
    margin-top 39px
    margin-bottom 16px

    &::v-deep
      .btn-input-file
        padding-left 30px
        padding-right 30px
        padding-top 3px
        padding-bottom 3px
        text-transform uppercase

#avatar-list-modal
  .avatar-list-container
    overflow-y scroll
    padding 5px
    padding-bottom 15px
    max-height 65vh

    &::-webkit-scrollbar
      width 8px
      height 10px
      background color-purple-200
      border-radius 4px

    &::-webkit-scrollbar-thumb
      background color-blue-400
      border-radius 4px

    .avatar-list
      display flex
      flex-wrap wrap

      .avatar-img
        overflow visible
        cursor pointer

      .avatar-img-selected
        outline solid 2px color-blue-400
        transform scale(0.9)

    @media (max-width 750px)
      .avatar-list
        min-width 815px

  .prompt-select-avatar
    text-align center
    margin 0
    margin-top 40px

  .avatar-list-buttons
    display flex
    justify-content center
    padding-top 40px

    .avatar-button
      margin-right 10px

    .avatar-button:disabled
      background color-blue-400
      border-color color-blue-400

</style>

<style lang="stylus">
#avatar-list-modal
  .modal-dialog
    max-width 750px !important
</style>
