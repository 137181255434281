import axios from 'axios'
import { AppStorage } from '@/core/utils'
import moment from 'moment'
import { AwsService } from '@/core/services'
import { http } from '@/core/services/http'

const resource = '/api/student'
const teacherPath = resource + '/teacher'
const userValidation = () => {
  const user = AppStorage.getUser()
  return user.is_teacher ? teacherPath : resource
}

function getExtension (filename) {
  return filename.substring((filename.lastIndexOf('.')) + 1).toLowerCase()
}

const getSignature = async function (key, bucket, client_method = 'put_object') {
  const result = await axios.post(process.env.VUE_APP_S3_SIGNED_URL, {
    bucket: bucket,
    key: key, //, "prefix",
    client_method: client_method
  })
  const signedUrl = result.data.signed_url
  const signature = {}

  signature.url = signedUrl
  signature.inputs = {}

  const pairs = signedUrl.substring(signedUrl.indexOf('?') + 1).split('&')
  for (let i = 0; i < pairs.length; i++) {
    if (!pairs[i]) {
      continue
    }
    const pair = pairs[i].split('=')
    signature.inputs[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
  }

  return signature
}

const getProfileMetadata = function () {
  const user = AppStorage.getUser()

  return {
    country: !user.country_name ? '' : user.country_name.replace(/[^a-zA-Z0-9]/g, ''),
    school: !user.school_name ? '' : user.school_name.replace(/[^a-zA-Z0-9]/g, ''),
    teacher: !user.full_name ? '' : user.full_name.replace(/[^a-zA-Z0-9]/g, '')
  }
}

const generateFileName = function () {
  return (moment().utc().format('YYYYMMDDHHmmssSSS') + Math.round(Math.random() * (999 - 100) + 100))
}

const createFilePrefixName = function (classroomId, taskId, submissionId = null) {
  const student = JSON.parse(localStorage.getItem('user'))

  return `${student.id}-${classroomId}-${taskId}` + (submissionId ? `-${submissionId}` : '')
}

class UploadManager {
  constructor (uploadData) {
    this.step = ''

    this.progressFile = 0
    this.uploadProgressNotification = 0
    this.bucket = process.env.VUE_APP_S3_VIDEO_INPUT_BUCKET

    this.fileName = uploadData.fileName
    this.fileUrl = uploadData.fileUrl
    this.fileUrlInput = uploadData.fileUrlInput
    this.groupId = uploadData.fileName
    this.key = uploadData.fileName
    this.keyMeta = uploadData.fileName + '.json'

    this.onProgressFn = () => {
    }

    this.awsKinesisLog({ ...uploadData, message: 'Starting video upload' })
  }

  awsKinesisLog (data) {
    // console.log('awsKinesisLog: ', data)
    if (data.message) {
      this.step = data.message
    } else {
      data.message = this.step
    }

    try {
      http.post(`${userValidation()}/kinesis`, {
        message: JSON.stringify({
          groupId: this.groupId,
          ...data
        })
      }).then(response => {
        return response
      })
    } catch (error) {
      console.error(error)
    }
  }

  async triggerTranscoder (url, size) {
    this.awsKinesisLog({ url, size, message: 'Init trigger transcoder check' })

    const rTriggerTranscoder = await axios.post(process.env.VUE_APP_S3_TRANSCODER_TRIGGER_URL, {
      FileUrl: url,
      size: size
    })

    const responseData = {
      urlConfig: rTriggerTranscoder?.config?.url,
      status: rTriggerTranscoder?.status,
      statusText: rTriggerTranscoder?.statusText,
      messageId: rTriggerTranscoder?.data?.SendMessageResponse?.SendMessageResult?.MessageId,
      requestId: rTriggerTranscoder?.data?.SendMessageResponse?.ResponseMetadata?.RequestId,
      ...rTriggerTranscoder?.headers
    }

    this.awsKinesisLog({ ...responseData, message: 'Response trigger transcoder check' })
    if (rTriggerTranscoder.status !== 200) {
      throw new Error(`Oops, something went wrong. LUV had a problem when trying to render the video: ${this.fileName}.`)
    }
  }

  async uploadVideoFile (file) {
    this.initUploadProgressInterval()

    this.awsKinesisLog({ key: this.key, bucket: this.bucket, message: 'Starting Video file upload' })

    // upload the video file
    const responseUpload = await AwsService.s3Upload(
      file,
      this.key,
      this.bucket,
      (progress) => {
        this.progressFile = Math.round((progress * 90) / 100)
        this.onProgressFn(this.progressFile)
      }
    )

    this.clearUploadProgressInterval()

    this.awsKinesisLog({ ...responseUpload, message: 'Response Video file upload' })
  }

  onProgress (onProgress) {
    this.onProgressFn = onProgress
    return this
  }

  initUploadProgressInterval () {
    this.uploadProgressNotification = setInterval(() => {
      this.awsKinesisLog({
        message: `Video file upload progress %${this.progressFile}`
      })
      // }, 60000) // 1 minute interval (60,000 milliseconds)
    }, 30000) // 30 sec interval (30,000 milliseconds)
  }

  appVisibilityEvent () {
    if (document.visibilityState === 'visible') {
      this.awsKinesisLog({
        event: 'User returns to application'
      })
    } else {
      this.awsKinesisLog({
        event: 'User change tab or application'
      })
    }
  }

  setAppVisibilityEvent () {
    // add event listeners on the document for the visibilityChange event
    document.addEventListener('visibilitychange', () => {
      this.appVisibilityEvent()
    })
  }

  removeAppVisibilityEvent () {
    // remove event listeners on the document for the visibilityChange event
    document.removeEventListener('visibilitychange', () => {
      this.appVisibilityEvent()
    })
  }

  clearUploadProgressInterval () {
    if (this.uploadProgressNotification) {
      clearInterval(this.uploadProgressNotification)
    }
  }

  async uploadMetaFile () {
    const metaContent = { key: this.key, metadata: getProfileMetadata() }
    const metaFile = new File([JSON.stringify(metaContent)], this.keyMeta, {
      type: 'application/json'
    })

    this.awsKinesisLog({
      keyMeta: this.keyMeta,
      bucket: this.bucket,
      message: 'Starting meta file upload'
    })

    const responseJson = await AwsService.s3Upload(metaFile, this.keyMeta, this.bucket, (progress) => {
      this.onProgressFn(this.progressFile + Math.round((progress * 10) / 100))
    })

    this.awsKinesisLog({ ...responseJson, message: 'Response meta file upload' })
  }

  notifyError (error) {
    this.awsKinesisLog({ error: error, step: this.step, message: 'Error upload' })
    throw error
  }
}

export default {
  uploadFile: async function (file, customData, onUploadProgress) {
    const uManager = new UploadManager(customData)
      .onProgress((percentage) => {
        onUploadProgress(percentage)
      })

    const fileUrlInput = customData.fileUrlInput

    try {
      // watch if user change tab or application
      uManager.setAppVisibilityEvent()

      // call the transcoder check
      await uManager.triggerTranscoder(fileUrlInput, file.size)

      // upload the video file
      await uManager.uploadVideoFile(file)

      // upload the meta file
      await uManager.uploadMetaFile()

      uManager.removeAppVisibilityEvent()

      return {
        name: uManager.fileUrl
      }
    } catch (error) {
      uManager.clearUploadProgressInterval()
      uManager.removeAppVisibilityEvent()
      uManager.notifyError(error)
    }
  },
  getVideo: async function (key) {
    const bucket = process.env.VUE_APP_S3_VIDEO_OUTPUT_BUCKET
    const signature = await getSignature('resources/' + key, bucket, 'get_object')

    return signature.url
  },
  getVideoThumbnail: async function (key) {
    const bucket = process.env.VUE_APP_S3_VIDEO_OUTPUT_BUCKET
    const signatureThumbnail = await getSignature('resources/MedResVideo/thumbnails/' + key + '-00001.png', bucket, 'get_object')

    return signatureThumbnail.url
  },
  createFileNameData: function (file, classroomId, taskId, submissionId = null) {
    const baseNamePrefix = createFilePrefixName(classroomId, taskId, submissionId)
    const baseName = `${baseNamePrefix}-${generateFileName(file.name)}`
    const extension = getExtension(file.name)
    const fileName = baseName + '.' + extension

    const fileUrl = process.env.VUE_APP_S3_STORAGE + '/' + baseName + '.mp4'
    const fileUrlInput = process.env.VUE_APP_S3_IMPUT_STORAGE + '/' + fileName

    return {
      baseNamePrefix,
      baseName,
      extension,
      fileName,
      fileUrl,
      fileUrlInput
    }
  }
}
