<template>
  <b-modal id="text-respond-modal" centered hide-footer hide-header size="xs">
    <div class="content" v-if="submissionItem">
      <div class="close-btn">
        <div @click="$bvModal.hide('text-respond-modal')">×</div>
      </div>
      <div class="title">
        RESPOND
      </div>
      <div class="responding-to">
        Text response to <span>{{submissionItem.student.full_name}}</span>
      </div>
      <div class="responding-to">
        {{submissionItem.task.task_name}}
      </div>
      <div>
        <textarea placeholder="Enter Text" maxlength="250" v-model="responseText"></textarea>
      </div>
      <div>
        <button class="send-btn" @click="onSubmit">SUBMIT</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { AppStorage } from '@/core/utils'
import { ClassroomService } from '@/core/services'

export default {
  name: 'TextRespondModal',
  props: {
    value: {
      required: true
    }
  },
  data () {
    return {
      user: null,
      responseText: null
    }
  },
  methods: {
    async onSubmit () {
      const classroomId = this.$route.params.classroomId
      const textToSend = this.responseText
      await ClassroomService.responseTextToPartner(classroomId, this.value.class_task_id, this.value.id, textToSend)
      this.$bvModal.hide('text-respond-modal')
      this.$emit('load-classroom', true)
    }
  },
  computed: {
    submissionItem () {
      return this.value ? this.value : null
    }
  },
  mounted () {
    this.user = AppStorage.getUser()
  }
}
</script>

<style lang="stylus">
@import "~@/core/stylus/variables.styl"
#text-respond-modal
  .modal-body
    padding 0
  .modal-content
    border-radius 4px
    border 2px solid color-gray-400
    width 80%
    left 10%
  .content
    text-align center
  .close-btn
    padding 0 15px
    text-align right
    font-size 35px
    height 35px
    font-weight bolder
    margin-top -5px
  .title
    font-weight bold
    font-size 16px
    letter-spacing 1px
    margin-bottom 10px
  .responding-to
    font-size 12px
    text-align left
    padding 0 30px
    color color-black-200
    &:first-letter
      text-transform capitalize
    span
      text-transform capitalize
  textarea
    width 85%
    min-height 150px
    border 2px solid color-gray-400
    border-radius 4px
    margin-bottom 15px
    margin-top 10px
    font-family "Open Sans"
    resize none
    padding 5px
    @media (min-width: 992px)
      font-size 16px
    @media (min-width: 768px) and (max-width: 992px)
      font-size 15px
    @media (max-width: 767px)
      font-size 14px
  .send-btn
    background-color color-blue-100
    border none
    border-radius 4px
    color white
    font-weight 600
    padding 5px 30px
    margin-bottom 25px
    letter-spacing 2px
</style>
