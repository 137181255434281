export const state = {
  classroom: {
    id: null,
    name: null,
    subject_id: null,
    age_range_id: null,
    number_students_range_id: null,
    lang_native_id: null,
    lang_instruction_id: null,
    start_date: null,
    end_date: null,
    level_id: null
  },
  tasksList: [],
  chat_messages: [],
  creatingClassroom: false,
  loading: true,
  loadingTask: true,
  loadingClassroom: true,
  addingClassroomContent: false
}
