import { ClassroomService } from '@/core/services'
import {
  FETCH_CLASSROOM_START,
  FETCH_CLASSROOM_DATA
} from '@/core/storage/classroom/mutation-types'

export const actions = {
  getClassroom ({ commit }, id = Number) {
    commit(FETCH_CLASSROOM_START)
    return ClassroomService.getClassroom(id).then(response => {
      commit(FETCH_CLASSROOM_DATA, response)

      return response
    })
  }
}
