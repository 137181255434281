<template>
  <b-row class="login-container">
    <b-col cols="10" sm="8" md="6" lg="4" xl="4" class="mx-auto">
      <div class="container-form-create-password">
        <div v-if="isPlayerTrust" class="logo-player-trust"/>
        <div v-else class="logo"/>
        <h3 class="welcome">Welcome</h3>
        <div class="lines">
          <div class="green-line"></div>
          <div class="purple-line"></div>
        </div>
        <b-form @submit.prevent="login">
          <p v-if="error" class="text-danger">{{ error }}</p>
          <div class="form-group">
            <input v-model="form.username" name="username" type="text" class="form-control" placeholder="Username">
          </div>
          <div class="form-group form-group-confirm-new-password">
            <input v-model="form.password" name="password" type="password" class="form-control"
                   placeholder="Password or Class Code">
          </div>
          <div @click="$bvModal.show('forgot-password-modal')" class="forgot-password">
            Forgot password?
          </div>
          <div class="form-group form-group-actions buttons">
            <b-button type="submit" class="text-uppercase" variant="primary">Log in</b-button>
          </div>
        </b-form>
      </div>
    </b-col>
    <b-col cols="12" class="align-self-end mx-auto teacher">
      <a href="https://cert.privo.com/#/companies/languageTestingInternational"
         target="_blank"
         class="logo-coppa">
        <img src="~@/assets/logo-coppa.png" alt="">
      </a>
      <div>
        ARE YOU A TEACHER?
      </div>
      <div>
        <a class="teacher-url" :href="$options.urlTeacherSite">CLICK HERE TO ACCESS YOUR ACCOUNT.</a>
      </div>
    </b-col>
    <ForgotPasswordModal></ForgotPasswordModal>
  </b-row>
</template>

<script>
import { ForgotPasswordModal } from '../components'
import { AuthService } from '@/core/services'
import { AppStorage } from '@/core/utils'

export default {
  urlTeacherSite: process.env.VUE_APP_BASE_URL_TEACHER_PORTAL,
  name: 'LoginPage',
  components: {
    ForgotPasswordModal
  },
  data () {
    return {
      error: null,
      form: {
        username: '',
        password: ''
      }
    }
  },
  mounted () {
    this.checkBrowserCompatibility()
  },
  computed: {
    isPlayerTrust () {
      const currentUrl = window.location.href
      const containsPlayers = /players/i.test(currentUrl)
      return containsPlayers
    }
  },
  methods: {
    login () {
      this.error = null

      AuthService.login(this.form.username, this.form.password).then((response) => {
        AppStorage.setUser(response.data)
        this.$router.push({ name: 'MyClassroomsPage' })
      }).catch(error => {
        this.error = error.message
      })
    },
    checkBrowserCompatibility () {
      const supportedBrowsers = {
        windows: {
          id: 'win',
          browser: 'Chrome'
        },
        macOs: {
          id: 'mac',
          browser: 'Chrome or Safari'
        },
        android: {
          id: 'android',
          browser: 'Chrome'
        },
        ios: {
          id: [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
          ],
          browser: 'Safari'
        }
      }

      // Get the user's operating system
      const operatingSystem = navigator.platform.toLowerCase()

      const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
      const isEdge = isChrome && (navigator.userAgent.indexOf('Edg') !== -1)
      const isBrave = navigator?.brave?.isBrave?.name === 'isBrave'
      const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
                       navigator.userAgent &&
                       navigator.userAgent.indexOf('CriOS') === -1 &&
                       navigator.userAgent.indexOf('FxiOS') === -1

      if (operatingSystem.includes(supportedBrowsers.windows.id) && (!isChrome || isEdge || isBrave)) {
        this.showPopupBrowserCompatibility(supportedBrowsers.windows.browser)
        return
      }
      if (operatingSystem.includes(supportedBrowsers.macOs.id) && !(isChrome || isSafari || isEdge)) {
        this.showPopupBrowserCompatibility(supportedBrowsers.macOs.browser)
        return
      }
      if (operatingSystem.includes(supportedBrowsers.android.id) && (!isChrome || isEdge)) {
        this.showPopupBrowserCompatibility(supportedBrowsers.android.browser)
        return
      }
      if (supportedBrowsers.ios.id.includes(navigator.platform) && !isSafari) {
        this.showPopupBrowserCompatibility(supportedBrowsers.ios.browser)
      }
    },
    showPopupBrowserCompatibility (browser) {
      this.$bvModal.msgBoxOk(`In order to have the best experience on Level Up Village, please switch to ${browser}.`)
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"

.login-container
  padding-bottom 80px

  @media (max-width 700px)
    overflow-y scroll

.logo, .logo-player-trust
  background-image url('~@/assets/images/luv-logo-standard.png')
  height 200px
  width 100%
  margin auto
  background-size cover
  background-position center
  background-repeat no-repeat
  margin-bottom 10px
  margin-top 100px
  @media (max-height: 670px)
    margin-top 50px
  @media (max-height: 550px)
    margin-top 10px

.logo-player-trust
  background-image url('~@/assets/images/PlayersTrust_V_LBG_RGB.png')
  background-size contain

.logo-coppa
  margin-bottom 1rem
  display block

.welcome
  color color-blue-100
  font-size 20px
  font-weight bold
  margin-bottom 10px

.lines
  margin-top 20px

  .green-line
    display inline-block
    vertical-align top
    width 30%
    background-color color-green-100
    height 5px

  .purple-line
    display inline-block
    vertical-align top
    width 70%
    background-color color-purple-400
    height 5px

.container-form-create-password
  text-align center
  margin-right auto
  margin-left auto
  max-width 410px

.form-group-password
  margin-top 40px

.form-group-confirm-new-password
  margin-bottom 1rem

.forgot-password
  text-align right
  color color-black-200
  font-size 12px
  font-weight bold
  cursor pointer

.buttons
  margin-top 48px
  padding-bottom 20px
  @media (max-height: 670px)
    margin-top 24px
  @media (max-height: 550px)
    margin-top 10px

.teacher
  text-align center
  letter-spacing 1px
  text-transform uppercase
  margin-bottom 20px
  @media (min-width: 992px)
    font-size 12px
  @media (min-width: 768px) and (max-width: 992px)
    font-size 11px
  @media (max-width: 767px)
    font-size 10px

  &-url
    color #262626
</style>
