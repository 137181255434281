<template>
  <b-overlay :show="loading">
    <div class="post-container">
      <back-button text="Back"/>
      <div class="post-area">
        <div class="post-title">
          My Post
        </div>
        <div class="post-information">
          <div class="post-student-area">
            <b-avatar class="post-student-avatar" :src="submission.student.thumbnail"/>
          </div>
          <div class="post-detail" v-if="!loading">
            <div class="post-task-name">{{ $options.setUserName(submission.student.full_name) }}</div>
            <div class="post-task-name">{{ submission.task.task_name }}</div>
            <div class="post-task-date">Posted {{ submission.created_at | dateElapseTime }}</div>
          </div>
        </div>
        <div class="post-video">
          <video-custom
            :src="submission.url || ''"
            :video_processed="submission.video_processed"
            :hide-video="!!validStatus"
            :status-text="validStatus"
          ></video-custom>
        </div>
        <div class="post-status" v-if="!loading">
          STATUS: {{ submission.status }}
        </div>
        <div class="teacher-comment" v-if="submission.teacher_comment">
          <span class="teacher-comment__label">Teacher comment:</span> {{ submission.teacher_comment }}
        </div>
      </div>
    </div>
    <div class="responses-container" v-if="submission.responses.length">
      <div class="responses-title">
        Partner's responses
      </div>
      <div class="responses-area" :key="response.id" v-for="response in submission.responses">
        <div class="response-information">
          <div class="response-image">
            <b-avatar class="photo" :src="response.student.thumbnail"/>
          </div>
          <div class="info">
            <div class="user-name">{{ $options.setUserName(response.student.full_name) }}</div>
            <!--            <div class="task-name">Text Response</div>-->
            <div class="date">posted {{ response.created_at | dateElapseTime }}</div>
          </div>
        </div>
        <div v-if="response.response_type === 'video'" class="response-video">
          <video-custom
            :src="response.url"
            :video_processed="response.video_processed"
          ></video-custom>
        </div>
        <div v-else class="response-text">{{ response.text }}</div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import BackButton from '@/core/components/BackButton'
import { ClassroomService, NotificationService } from '@/core/services'
import VideoCustom from '@/core/components/video/VideoCustom'
import { setUserName, Notifications } from '@/core/utils'

export default {
  name: 'MyPostDetailPage',
  setUserName: setUserName,
  components: {
    BackButton,
    VideoCustom
  },
  props: {
    classroomId: {
      type: Number,
      required: true
    },
    postId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      submission: {
        student: {
          thumbnail: '',
          full_name: ''
        },
        task: { task_name: '' },
        created_at: '',
        url: '',
        responses: []
      },
      interval: null
    }
  },
  methods: {
    async init (showLoading = true) {
      if (showLoading) {
        this.loading = true
      }
      try {
        const response = await ClassroomService.getSubmission(this.classroomId, this.postId)
        this.submission = response.data
        const notifications = await NotificationService.list()
        const failedVideoNotification = notifications?.data?.find((notification) => notification.status === 'unread' && notification.type === Notifications.type.studentFailedVideoConversion && notification.object_id === this.postId)
        failedVideoNotification && NotificationService.status(failedVideoNotification.id, 'read')
      } catch (e) {
        const errorMessage = e.message ? e.message : 'Error while loading post.'
        console.log(e, errorMessage)
        await this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
      } finally {
        this.loading = false
      }
    }
  },
  computed: {
    validStatus () {
      return (this.submission.status === 'blocked' || this.submission.status === 'deleted') && this.submission.status
    }
  },
  created () {
    this.init()
  },
  watch: {
    classroomId: function () {
      this.init()
    },
    postId: function () {
      this.init().then(() => {
        this.interval = setInterval(() => {
          this.init(false)
        }, 15000)
      })
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"

.post-container
  max-width 625px
  margin auto

  .post-area
    margin 0 20px
    padding 10px 0

    .post-title
      font-weight bold
      color color-black-200

      @media (min-width: 992px)
        font-size 20px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 18px
      @media (max-width: 767px)
        font-size 16px

    .post-information
      margin-top 12px
      padding-bottom 7px

      .post-student-area
        display inline-block
        vertical-align top
        width 90px

        .post-student-avatar
          width 73px
          height 73px
          border-radius 50px
          background-repeat no-repeat
          background-size cover
          background-position center

      .post-detail
        display inline-block
        vertical-align top
        color color-black-200
        padding-top 4px

        .post-student-name
        .post-task-name
          font-weight bold
          word-break break-word

        .post-student-name
        .post-task-name
        .post-task-date
          @media (min-width: 992px)
            font-size 16px
          @media (min-width: 768px) and (max-width: 992px)
            font-size 15px
          @media (max-width: 767px)
            font-size 14px

    .post-video
      padding 10px
      border-top 1px solid #DDDDDD

      .video-overlay
        width 100%
        height 395px
        border-radius 4px

    .post-status
      font-size 14px
      font-weight bold
      text-transform uppercase
      color color-black-200
      margin-top 5px
      padding 0 10px

    .teacher-comment
      margin-top 10px
      margin-bottom 30px
      padding 10px
      border 1.5px solid black
      background-color color-comment-background
      word-wrap break-word
      white-space pre-wrap

      &__label
        font font-opensans-semibold

.responses-container
  max-width 625px
  margin auto

  .responses-title
    font-weight bold
    color color-black-200
    margin 0 20px
    padding 10px 0
    padding-top 20px
    border-top 1px solid #DDDDDD

    @media (min-width: 992px)
      font-size 20px
    @media (min-width: 768px) and (max-width: 992px)
      font-size 18px
    @media (max-width: 767px)
      font-size 16px

  .responses-area
    border-bottom 1px solid #DDDDDD
    margin 0 20px
    padding 10px 0

    &:first-child
      border-top none
      border-bottom none

    .response-information
      margin-top 12px
      padding-bottom 7px
      border-bottom 1px solid #DDDDDD

      .response-image
        display inline-block
        vertical-align top
        width 90px

        .photo
          width 73px
          height 73px
          border-radius 50px
          background-repeat no-repeat
          background-size cover
          background-position center

      .info
        display inline-block
        vertical-align top
        color color-black-200
        padding-top 4px

        .user-name
          font-weight bold
          @media (min-width: 992px)
            font-size 16px
          @media (min-width: 768px) and (max-width: 992px)
            font-size 15px
          @media (max-width: 767px)
            font-size 14px

        .task-name
          font-weight bold
          @media (min-width: 992px)
            font-size 16px
          @media (min-width: 768px) and (max-width: 992px)
            font-size 15px
          @media (max-width: 767px)
            font-size 14px

        .date
          @media (min-width: 992px)
            font-size 16px
          @media (min-width: 768px) and (max-width: 992px)
            font-size 15px
          @media (max-width: 767px)
            font-size 14px

    .response-video
      padding 10px
      border-top 1px solid #DDDDDD

      .video-overlay
        width 100%
        height 395px
        border-radius 4px

    .response-text
      font-size 14px
      color color-black-200
      padding 20px 20px 0
</style>
