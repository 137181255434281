<template>
  <div class="dashboard-header">
    <div class="header-container">
      <router-link :to="{ name: 'MyTasksPage', params: {classroomId: this.$route.params.classroomId}}">
        <div class="header-opt tasks" :class="current_tab === 'tasks' ? 'active' : ''">My Tasks</div>
      </router-link>
      <router-link :to="{ name: 'MyPostsPage', params: {classroomId: this.$route.params.classroomId}}">
        <div class="header-opt posts" :class="current_tab === 'posts' ? 'active' : ''">My Posts</div>
      </router-link>
      <router-link :to="{ name: 'MyResponsesPage', params: {classroomId: this.$route.params.classroomId}}">
        <div class="header-opt response" :class="current_tab === 'response' ? 'active' : ''">My Responses</div>
      </router-link>
      <router-link v-if="false" :to="{ name: 'MessageBoardPage', params: {classroomId: this.$route.params.classroomId}}" :class="userValidationClass">
        <div class="header-opt board" :class="current_tab === 'board' ? 'active' : ''">Message Board</div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { AppStorage } from '@/core/utils'

export default {
  name: 'DashboardHeader',
  props: {
    current_tab: String
  },
  computed: {
    userValidationClass () {
      const user = AppStorage.getUser()
      return {
        'is-teacher': user.is_teacher
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"
.dashboard-header
  width 100%
  background-color color-purple-100
  color color-blue-100
  font-family "Open Sans"
  display flex
  justify-content center

  @media (min-width: 992px)
    height 50px
    font-size 18px
  @media (min-width: 768px) and (max-width: 992px)
    height 40px
    font-size 15px
  @media (max-width: 767px)
    height 36px
    font-size 12px
    justify-content space-between
  @media (max-width: 370px)
    font-size 10px
  .header-container
    margin auto
    max-width 710px
    min-width 320px
    overflow hidden
    display flex
    justify-content center

    .header-opt
      display inline-block
      text-align center
      margin 0 35px
      @media (min-width: 992px)
        line-height  50px
      @media (min-width: 768px) and (max-width: 992px)
        line-height 40px
      @media (max-width: 767px)
        line-height 36px
        margin 0 10px

      &.active
        font-weight bold
        text-transform uppercase

  .is-teacher
    display none

</style>
