<template>
  <match-media v-slot="{ mobile }">
    <footer :class="classContentFooter">
      <navigation-pills v-if="mobile && isNavigation"></navigation-pills>
      <social :is-authenticate="isAuthenticate" v-else></social>
    </footer>
  </match-media>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries'
import Social from './Social'
import NavigationPills from './NavigationPills'

export default {
  name: 'FooterLayout',
  components: {
    NavigationPills,
    Social,
    MatchMedia
  },
  inject: ['mediaQueries'],
  props: {
    isAuthenticate: {
      type: Boolean,
      default: false
    },
    isNavigation: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classContentFooter () {
      const classLayout = {
        footer: true
      }
      classLayout['footer--authenticate'] = this.isAuthenticate
      classLayout['container-fluid'] = !(this.isNavigation && this.mediaQueries.mobile)

      return classLayout
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"

.footer {
  position fixed
  height 80px
  bottom 0
  left 0
  width 100%
  background-color color-blue-100
  z-index 100

  &--authenticate {
    height 50px
  }
}
</style>
