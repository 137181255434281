const type = Object.freeze({
  studentTeacherStatusVideo: 'student_teacher_changed_status_of_video',
  studentTeacherStatusResponse: 'student_teacher_changed_status_of_response',
  studentTeacherStatusMessage: 'student_teacher_changed_status_of_message',
  studentPartnerRespondedToTheirVideo: 'student_partner_responded_to_their_video',
  studentTeacherCommentOnVideo: 'student_teacher_comment_on_video',
  studentTeacherCommentOnResponse: 'student_teacher_comment_on_response',
  studentVideoFail: 'student_video_fail',
  studentFailedVideoConversion: 'student_create_video_process_failure',
  studentResponseFailedVideo: 'student_response_video_process_failure'
})

export default { type }
