const PENDING_STATUS = 'pending' // legacy, same as to-do status
const STATUS_TODO = 'to-do'
const STATUS_SUBMITTED = 'submitted'
const STATUS_APPROVED = 'approved'
const STATUS_BLOCKED = 'blocked'
const STATUS_UNAPPROVED = 'unapproved'

const ACTION_CREATE_TASK = 'createTask'
const ACTION_RESPOND_ACTION = 'respondAction'

function getCreateTaskStatus (task) {
  const submissions = task.teacher_submissions ? task.teacher_submissions : task.submissions
  if (!submissions || !submissions.length) {
    return STATUS_TODO
  }
  if (!submissions[0].status || submissions[0].status === PENDING_STATUS) {
    return STATUS_SUBMITTED
  }

  if (!submissions[0].status || submissions[0].status === STATUS_UNAPPROVED) {
    return STATUS_BLOCKED
  }
  return submissions[0].status
}

function getResponseCount (taskResponses) {
  return taskResponses.length ?? 0
}

function getCompletedResponseCount (task) {
  const taskResponses = task.teacher_responses ? task.teacher_responses : task.responses
  const responsesCount = getResponseCount(taskResponses)
  if (responsesCount === 0) {
    return 0
  }
  let completeCount = 0
  for (let i = 0; i < responsesCount; i++) {
    const responseStatus = taskResponses[i].status
    if ([STATUS_SUBMITTED, STATUS_APPROVED].includes(responseStatus)) {
      completeCount++
      if (completeCount === task.number_responses) {
        // if we reached the maximum number of responses just return the maximum
        break
      }
    }
  }
  return completeCount
}

function getRespondActionStatus (task) {
  const taskResponses = task.teacher_responses ? task.teacher_responses : task.responses
  // get the lesser status and return that as the response action status
  // STATUS_BLOCKED > STATUS_APPROVED > STATUS_SUBMITTED > STATUS_TODO
  const responsesCount = getResponseCount(taskResponses)
  if (!responsesCount || responsesCount < task.number_responses) {
    return STATUS_TODO
  }
  // responses are at least the number requested
  let submittedCount = 0
  let approvedCount = 0
  let blockedCount = 0
  for (let i = 0; i < responsesCount; i++) {
    const responseStatus = taskResponses[i].status
    if ([STATUS_TODO, STATUS_APPROVED, PENDING_STATUS, STATUS_SUBMITTED].includes(responseStatus)) {
      submittedCount++
    }
    if (responseStatus === STATUS_APPROVED) {
      approvedCount++
    }
    if (responseStatus === STATUS_BLOCKED) {
      blockedCount++
    }
  }

  if (approvedCount >= task.number_responses) {
    return STATUS_APPROVED
  }
  if (submittedCount >= task.number_responses) {
    return STATUS_SUBMITTED
  }
  if (blockedCount >= task.number_responses) {
    return STATUS_BLOCKED
  }

  return STATUS_TODO
}

function getCompletedResponsePercentage (task) {
  if (task.number_responses === 0) {
    return 0
  }
  const completeCount = getCompletedResponseCount(task)
  return completeCount * 100 / task.number_responses
}

export default {
  STATUS_TODO,
  STATUS_SUBMITTED,
  STATUS_APPROVED,
  STATUS_BLOCKED,
  ACTION_CREATE_TASK,
  ACTION_RESPOND_ACTION,
  getCreateTaskStatus,
  getRespondActionStatus,
  getCompletedResponseCount,
  getCompletedResponsePercentage
}
