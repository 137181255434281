import { http } from './http'
import { AppStorage } from '@/core/utils'

const resource = '/api/student/notifications'
const teacherPath = '/api/student/teacher/notifications'
const userValidation = () => {
  const user = AppStorage.getUser()
  return user.is_teacher ? teacherPath : resource
}

export default {
  list () {
    return http.get(`${userValidation()}`).then(response => {
      return response
    })
  },
  status (notificationId, status) {
    return http.put(`${userValidation()}/${notificationId}`, { status }).then(response => {
      return response
    })
  },
  privacyPolicys () {
    return http.get('api/teacher/privacy-policy-and-terms-of-use').then(response => {
      return response
    })
  }
}
