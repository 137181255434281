import { http } from '../http'
import { AppStorage, Uploader } from '@/core/utils'

const UPLOAD_CHUNK_SIZE_MB = process.env.VUE_APP_S3_UPLOAD_CHUNK_SIZE_MB
const UPLOAD_THREADS = process.env.VUE_APP_S3_UPLOAD_THREADS

const apiPath = '/api/student'
const apiPathTeacher = '/api/student/teacher'

const userValidation = () => {
  const user = AppStorage.getUser()
  return user.is_teacher ? apiPathTeacher : apiPath
}

const getCognitoToken = async function () {
  const response = await http.get(`${userValidation()}/cognito-token`)

  if (response.success) {
    return response.data.token
  }

  throw Error(response.message)
}

const VUE_APP_S3_TIMEOUT = 1200 * 1000

const uploadFile = (file, key, bucket, onUploadProgress) => {
  return new Promise((resolve, reject) => {
    const uploaderOptions = {
      file: file,
      fileKey: key,
      bucket: bucket,
      chunkSize: UPLOAD_CHUNK_SIZE_MB,
      threadsQuantity: UPLOAD_THREADS,
      timeout: VUE_APP_S3_TIMEOUT,
      useTransferAcceleration: false
    }

    const uploader = new Uploader(uploaderOptions)
    uploader
      .onProgress(({ percentage }) => {
        onUploadProgress(percentage)
      })
      .onError((error) => {
        reject(error)
      })
      .onSuccess(() => {
        resolve({ success: true })
      })

    uploader.start()
  })
}

export default {
  s3Upload: uploadFile,
  getCognitoToken: getCognitoToken
}
