<template>
  <div>
    <router-link class="action-icon" :to="goToResponseDetailPage()">
      <div class="response">
        <div class="photo-area">
          <div class="photo" v-if="response.response_type === 'text'">
            <span class="icon-text"></span>
          </div>
          <div v-else class="photo" :style="thumbnail"></div>
        </div>
        <div class="info-area">
          <div class="responding-to">Responding To {{ $options.setUserName(response.submission.student.full_name) }}</div>
          <div class="response-date">{{ response.created_at | dateElapseTime }}</div>
          <div class="response-type">{{ response.status }}</div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { UploadService } from '@/core/services'
import { setUserName } from '@/core/utils'

export default {
  name: 'ResponseItem',
  setUserName: setUserName,
  props: {
    response: Object
  },
  data () {
    return {
      signed_url: null
    }
  },
  async mounted () {
    if (!this.isTextResponse && this.response.url) {
      const url = this.response.url.split('/')
      const key = url[url.length - 1]

      this.signed_url = await UploadService.getVideoThumbnail(key)
    }
  },
  methods: {
    goToResponseDetailPage () {
      return {
        name: 'ResponseDetailPage',
        params: {
          classroomId: this.$route.params.classroomId,
          responseId: this.response.id
        }
      }
    }
  },
  computed: {
    thumbnail () {
      return {
        backgroundImage: `url('${this.signed_url}')`
      }
    },
    isTextResponse () {
      return this.response.response_type === 'text'
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"

::v-deep
  .icon-text:before
    color #4E4F4E

.response
  display flex
  border-top solid 1px color-gray-400
  padding-top 6px
  margin-bottom 5px
  @media (min-width: 992px)
    min-height 100px
  @media (min-width: 768px) and (max-width: 992px)
    min-height 90px
  @media (max-width: 767px)
    min-height 80px

  .photo-area
    display inline-block
    @media (min-width: 992px)
      min-width 100px
    @media (min-width: 768px) and (max-width: 992px)
      min-width 85px
    @media (max-width: 767px)
      min-width 70px

    .photo
      background-color #DDDDDD
      background-repeat no-repeat
      background-size cover
      background-position center
      @media (min-width: 992px)
        width 90px
        height 90px
      @media (min-width: 768px) and (max-width: 992px)
        width 80px
        height 80px
      @media (max-width: 767px)
        width 72px
        height 72px

      span
        font-size 2.4rem
        margin 17px 22px
        position absolute
        @media(min-width 768px)
          font-size 2.6rem
          margin 21px 25px
        @media(min-width 992px)
          font-size 2.7rem
          margin 25px 29px
  .info-area
    display inline-block
    vertical-align top
    padding-top 7px
    color #3D3D3D
    margin-left 12px

    .responding-to
      font-weight bold
      text-transform capitalize
      @media (min-width: 992px)
        font-size 16px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 15px
      @media (max-width: 767px)
        font-size 14px
      @media (max-width: 370px)
        font-size 12px

    .response-type
      text-transform capitalize
      @media (min-width: 992px)
        font-size 14px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 13px
      @media (max-width: 767px)
        font-size 12px
      @media (max-width: 370px)
        font-size 10px

    .response-date
      @media (min-width: 992px)
        font-size 14px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 13px
      @media (max-width: 767px)
        font-size 12px
      @media (max-width: 370px)
        font-size 10px
</style>
