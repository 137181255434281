<template>
  <div>
    <router-link class="action-icon"
                 :to="{ name: 'PostDetailPage', params: {postId: submission.id, classroomId: $route.params.classroomId}}">
      <div class="post">
        <div class="photo-area">
          <div class="photo" :style="thumbnail"></div>
        </div>
        <div class="info-area">
          <div class="task-name">{{ submission.task.task_name }} <span class="topic"> - {{
              submission.task.topic
            }}</span></div>
          <div class="post-date">posted on {{ submission.created_at  | dateElapseTime }}</div>
        </div>
      </div>
    </router-link>
  </div>
</template>
<script>
import { UploadService } from '@/core/services'

export default {
  name: 'MyPostsItem',
  data () {
    return {
      signed_url: null
    }
  },
  props: {
    submission: {
      type: Object
    }
  },
  async mounted () {
    const url = this.submission.url ? this.submission.url.split('/') : ''
    const key = url[url.length - 1]

    this.signed_url = await UploadService.getVideoThumbnail(key)
  },
  computed: {
    thumbnail () {
      return {
        backgroundImage: `url('${this.signed_url}')`
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"
.post
  display flex
  border-top solid 1px color-gray-400
  padding-top 6px
  @media (min-width: 992px)
    height 100px
  @media (min-width: 768px) and (max-width: 992px)
    height 90
  @media (max-width: 767px)
    height 85px

  .photo-area
    display inline-block
    @media (min-width: 992px)
      min-width 120px
    @media (min-width: 768px) and (max-width: 992px)
      min-width 110px
    @media (max-width: 767px)
      min-width 90px

    .photo
      background-color lightgray
      background-repeat no-repeat
      background-size cover
      background-position center
      @media (min-width: 992px)
        width 95px
        height 88px
      @media (min-width: 768px) and (max-width: 992px)
        width 85px
        height 80px
      @media (max-width: 767px)
        width 75px
        height 72px

  .info-area
    display inline-block
    vertical-align top
    padding-top 7px
    color #3D3D3D

    .task-name
      font-weight bold
      white-space nowrap
      overflow hidden
      text-overflow ellipsis
      max-width: 250px

      &::first-letter
        text-transform capitalize
      @media (min-width: 992px)
        font-size 16px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 15px
      @media (max-width: 767px)
        font-size 14px
      @media (min-width 620px)
        max-width 460px
      @media (min-width 475px) and (max-width: 619px)
        max-width 350px

    .topic
      font-weight 400

      &::first-letter
        text-transform capitalize

    .post-date
      @media (min-width: 992px)
        font-size 14px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 13px
      @media (max-width: 767px)
        font-size 12px
</style>
