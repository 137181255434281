<template>
  <b-modal id="download-recorded-video-modal" centered hide-footer hide-header size="xs">
    <div class="modal-title">Downloading Video</div>
    <div class="modal-text">A copy of your video is being downloaded to your device. Click OK to continue.</div>
    <div class="modal-text">Filename: <b>{{ fileName }}</b></div>
    <div class="modal-btns">
      <div role="button" class="btn-ok" @click="$bvModal.hide('download-recorded-video-modal')">
        OK
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'DownloadRecordedVideoModal',
  props: {
    fileName: {
      type: String
    }
  }
}
</script>

<style lang="stylus">
@import "~@/core/stylus/variables.styl"

#download-recorded-video-modal
  .modal-content
    margin auto
    width 500px
    height auto

  .modal-title
    text-align center
    font-size 16px
    font-weight bold
    margin-top 65px

  .modal-text
    text-align center
    font-size 14px
    width 79%
    margin auto
    height 75px
    margin-top 10px
    text-overflow ellipsis
    overflow hidden

  .modal-btns
    text-align center

    .btn-cancel, .btn-ok
      text-align center
      display inline-block
      vertical-align top
      width 102px
      height 31px
      background-color color-blue-100
      border none
      border-radius 4px
      color white
      font-weight 600
      margin-bottom 25px
      letter-spacing 2px
      line-height 31px

    .btn-ok
      width 82px

</style>
