<template>
  <div :class="classContentSocial">
    <div class="copyright">Copyright &copy; {{ getCurrentYear() }} Level Up Village&reg; <span @click="$bvModal.show('privacy-policy-modal')">Privacy Policy</span></div>
    <b-modal id="privacy-policy-modal" centered hide-footer hide-header size="lg">
      <iframe :src="privacyPolicyUrl + '#toolbar=0'" width="100%" height="100%"/>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'

export default {
  name: 'Social',
  props: {
    isAuthenticate: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      privacyPolicy: process.env.VUE_APP_LUV_PRIVACY_POLICY,
      privacyPolicyUrl: ''
    }
  },
  computed: {
    classContentSocial () {
      const clazz = {
        'footer-social': true
      }

      clazz['footer-social--authenticate'] = this.isAuthenticate

      return {
        ...clazz
      }
    }
  },
  methods: {
    getCurrentYear () {
      return moment().year()
    }
  },
  async mounted () {
    const response = await axios.get(this.privacyPolicy, {
      responseType: 'blob'
    })

    this.privacyPolicyUrl = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"

.footer-social {
  display flex
  height 100%
  align-items center
  justify-content center
  flex-direction column-reverse

  .copyright {
    font-family "Open Sans", sans-serif
    color white
    margin-top 10px

    span {
      color #fff
      margin-left 20px
      text-decoration underline
      cursor pointer
    }
  }

  &--authenticate {
    justify-content space-between
    flex-direction row

    .copyright {
      margin-top 0
    }
  }
}
</style>
<style lang="stylus">
#privacy-policy-modal {
  height: 96%
  .modal-dialog {
    height: 90%;

    .modal-content {
      height: 100%

      .modal-body {
        padding: 0
      }
    }
  }
}
</style>
